import { css } from "@emotion/react";
import React, { useState } from "react";

import { COLORS } from "../../../stylesheets/emotion/vars";
import { getTotalHeightInVh } from "../../../utils/DynamicPricelistUtils";

export type RoundAboutPriceModel = {
    active: boolean;
    label: string;
    price: number;
    product_link: string;
    quantity: number;
};

//Logos urls
const BANNER_LOGO = "/images/pricelist/roundabout69_logo_black.png";
const BACKGROUND_VIDEO = "/images/pricelist/smokeBackground.mp4";
const BACKGROUND_IMAGE = "/images/pricelist/roundabout69_cannabis_blue.png";

const MAX_ROWS_PER_COLUMN = 20; // Maximum rows per each column, this value affects the number of columns in the table

// Table Logo Header settings: VH = Viewport Height (1vh = 1% of the screen height)
const LOGO_HEADER_HEIGHT_VH = 0;
const LOGO_HEADER_PADDING_VH = 0;
const ITEM_NAME_FONT_SIZE_VH = 1.8;

// Padding for each table column
const TABLE_COLUMN_PADDING_VH = 0.6;
const COLOR_GLOW = COLORS.roundaboutGlow;
const COLOR_GLOW2 = COLORS.roundaboutGlow2;

// Styling
const STYLES = css`
    .pricelistBody {
        background-color: ${COLORS.lightestPrimary};
        opacity: 1;
    }

    .logoBackground {
        background-image: url(${BANNER_LOGO});
        background-size: contain;
        background-position: center;
        z-index: 1;
        position: fixed;
        background-repeat: no-repeat;
        width: 40%;
        height: 40%;
        filter: drop-shadow(0px 0px 100px #fff);
        opacity: 0.6;

        /* Center the logo both vertically and horizontally */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .pricelistLogoHeader {
        background: ${COLORS.roundaboutColor};
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${LOGO_HEADER_HEIGHT_VH};
        padding-block: ${LOGO_HEADER_PADDING_VH}vh;
        z-index: 3;
    }

    .bannerBack {
        height: ${LOGO_HEADER_HEIGHT_VH - 1.5}vh;
    }

    .bannerFront {
        height: ${LOGO_HEADER_HEIGHT_VH - 1.5}vh;
    }

    .bannerLogo {
        height: ${LOGO_HEADER_HEIGHT_VH}vh; // The logo height should be the same as the header height
    }

    // Pricelist table styles

    .tableColumn {
        background: ${COLORS.pricelistBackground};
        color: white;
        width: 100%;
        padding: ${TABLE_COLUMN_PADDING_VH}vh;
    }

    .rowDivider {
        border: 0.01vh solid;
        border-color: ${COLORS.roundaboutSecondaryColor};
        z-index: 3;
    }

    .tableContainer {
        position: relative;
        display: flex;
        opacity: 1;
    }

    .tableRow {
        padding-block: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 2;
    }

    .titleRow {
        color: ${COLORS.roundaboutColor};
        font-size: 1.5vw; // If the category title font size is too big, the text will be cut off
        font-weight: bold;
        animation: pulsate 1.5s ease-in-out infinite alternate;
    }

    @keyframes pulsate {
        100% {
            /* A slightly smaller blur radius */
            text-shadow: 0 0 0.04vw #000000, 0 0 0.05vw ${COLOR_GLOW}, 0 0 0.06vw ${COLOR_GLOW},
                0 0 0.07vw ${COLOR_GLOW2}, 0 0 0.08vw ${COLOR_GLOW}, 0 0 0.09vw ${COLOR_GLOW2};
        }
        0% {
            /* Larger blur radius */
            text-shadow: 0 0 0.04vw #000000, 0 0 0.18vw ${COLOR_GLOW}, 0 0 0.23vw ${COLOR_GLOW},
                0 0 0.3vw ${COLOR_GLOW2}, 0 0 0.44vw ${COLOR_GLOW}, 0 0 0.6vw ${COLOR_GLOW2};
        }
    }

    .namePriceRow {
        padding-inline: 1vh;
        font-size: ${ITEM_NAME_FONT_SIZE_VH}vh;
    }

    .name {
        font-weight: bold;
    }

    .videoContainer {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.85;
        object-fit: fill;
    }

    .imageContainer {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.25;
        object-fit: fill;
    }

    .pricePart {
        display: flex;
        padding-block: 0.2vh;
    }

    .priceQuantityContainer {
        margin-right: ${TABLE_COLUMN_PADDING_VH}vh;
        display: flex;
        flex-direction: row-reverse;
        gap: 0.8vh;
    }

    .priceQuantityItem {
        flex: 0;
        text-align: right;
        width: 2.8vw;
    }

    .quantity {
        font-size: 1.4vh;
        margin-bottom: 0.3vh;
    }

    .price {
        font-size: 1.45vh;
        font-weight: bold;
        text-shadow: 0.1vw 0.1vw 0.15vw black, 0 0 0.75vw rgba(245, 243, 151, 0.25), 0 0 0.5vw #203a20;
    }

    .usaIcon {
        font-size: 2vh;
        text-shadow: 0.1vw 0.1vw 0.15vw black, 0 0 0.75vw rgba(255, 255, 255, 0.25), 0 0 0.5vw #203a20;
    }

    .starIcon {
        font-size: 1.9vh;
        text-shadow: 0.1vw 0.1vw 0.15vw black, 0 0 0.75vw rgba(255, 255, 255, 0.25), 0 0 0.5vw #203a20;
    }

    .legendCannabis {
        padding: 1vh;
        flex-direction: row-reverse;
        border-top: ${COLORS.roundaboutColor} 0.2vh solid;
        width: 100%;
        display: flex;
        font-size: 2vh;
        color: white;
        z-index: 3;
        gap: 3vh;
    }

    .legendItem {
        display: flex;
        gap: 0.6vh;
    }
`;

const totalHeightInVh = getTotalHeightInVh(LOGO_HEADER_HEIGHT_VH, LOGO_HEADER_PADDING_VH, TABLE_COLUMN_PADDING_VH);
const rowHeightInVh = totalHeightInVh / MAX_ROWS_PER_COLUMN;

const RenderBackground = () => {
    const backgrounds = [
        {
            type: "video",
            src: BACKGROUND_VIDEO,
        },
        {
            type: "image",
            src: BACKGROUND_IMAGE,
        },
    ];

    const [selectedBackground, setSelectedBackground] = useState(0);

    function updateBackground() {
        if (selectedBackground >= backgrounds!.length - 1) {
            setSelectedBackground(0);
        } else {
            setSelectedBackground(selectedBackground + 1);
        }
    }

    return (
        <div onClick={updateBackground}>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 10 /* Ensure it is above other components */,
                    backgroundColor: "transparent",
                    cursor: "pointer",
                }}
                onClick={updateBackground}
            />
            {backgrounds![selectedBackground].type === "video" ? (
                <video className="videoContainer" autoPlay loop muted src={backgrounds[selectedBackground].src} />
            ) : (
                <img className="imageContainer" src={backgrounds![selectedBackground].src} alt="background_image" />
            )}
            <div className="logoBackground" />
        </div>
    );
};

export const RoundAbout69CannabisSettings = {
    styles: STYLES,
    bannersURLs: {
        bannerLogo: BANNER_LOGO,
        bannerFront: undefined,
        bannerBack: undefined,
    },
    maxRowsPerColumn: MAX_ROWS_PER_COLUMN,
    rowHeight: rowHeightInVh,
    badgesStyles: undefined,
    excludedCategories: [],
    renderBackground: RenderBackground,
    renderLegend: undefined,
};
