import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiLink,
    EuiLoadingSpinner,
    EuiPage,
    EuiPageBody,
    EuiPanel,
    EuiSpacer,
    EuiTab,
    EuiTabs,
    EuiTitle,
} from "@elastic/eui";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import client from "../api/Client";
import { UserContext } from "../App";
import { ProductResults } from "../components/ProductResults";
import API_URL from "../Constants";
import { ShopContext } from "../utils/Shop";
import { capitalizeFirstLetter } from "../utils/Utils";

const tabs = [
    {
        id: "cannabis",
        name: "Cannabis",
        href: "/products/cannabis",
        disabled: false,
    },
    {
        id: "horeca",
        name: "Horeca",
        href: "/products/horeca",
        disabled: false,
    },
];

export function Products(props) {
    // Get shop context
    const shopContext = useContext(ShopContext);
    const [filteredData, setFilteredData] = useState([]);
    const [cannabisData, setCannabisData] = useState([]);
    const [horecaData, setHorecaData] = useState([]);
    const [selectedTabId, setSelectedTabId] = useState(props.showCannabis ? "cannabis" : "horeca");
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const user = useContext(UserContext);

    function filterData(prices, selectedTab) {
        // Prepare the data for horeca and cannabis product results
        if (selectedTab === "cannabis") {
            return prices
                .filter((price) => price.kind_name)
                .map((price) => {
                    price.name = price.kind_name;
                    if (price.json_prices) {
                        price.prices = {};
                        for (let i = 0; i < price.json_prices.length; i++) {
                            price.prices[price.json_prices[i].label.replace(" ", "").replace("gram", "g")] =
                                price.json_prices[i].price;
                        }
                    } else {
                        price.prices = {
                            half: price.half,
                            one: price.one,
                            two_five: price.two_five,
                            five: price.five,
                            joint: price.joint,
                            piece: price.piece,
                        };
                    }
                    if (price.strains.length > 0) {
                        price.description = price.strains.map((strain) => strain.name).join(" - ");
                    } else {
                        price.description = price.kind_short_description_nl;
                    }
                    return price;
                });
        } else if (selectedTab === "horeca") {
            return prices
                .filter((price) => price.product_name)
                .map((price) => {
                    price.name = price.product_name;
                    price.prices = {
                        half: price.half,
                        one: price.one,
                        two_five: price.two_five,
                        five: price.five,
                        joint: price.joint,
                        piece: price.piece,
                    };
                    price.description = price.product_short_description_nl;
                    return price;
                });
        }
    }

    const refreshData = (tabName) => {
        // Make data reload easy
        let isHoreca = tabName === "horeca";

        client.get(`${API_URL}/v1/shops/${shopContext.shopId}?is_horeca=${isHoreca}`).then((result) => {
            if (selectedTabId === tabName) {
                const filteredData = filterData(result.data.prices, selectedTabId);
                setFilteredData(filteredData);
            }

            if (isHoreca) {
                setHorecaData(result.data.prices);
            } else {
                setCannabisData(result.data.prices);
            }
        });
    };

    useEffect(() => {
        refreshData("horeca");
        refreshData("cannabis");
        // Todo: fix this for real ->
        // eslint-disable-next-line
    }, []);

    const setSelectedTab = (id) => {
        let filteredData = id === "cannabis" ? filterData(cannabisData, id) : filterData(horecaData, id);

        setSelectedTabId(id);
        setFilteredData(filteredData);
    };

    const renderTabs = () => {
        return tabs.map((tab, index) => (
            <Link to={tab.href} key={index}>
                <EuiTab onClick={() => setSelectedTab(tab.id)} isSelected={tab.id === selectedTabId} key={index}>
                    {tab.name}
                </EuiTab>
            </Link>
        ));
    };
    const showAdminLinks = !user.profile.email.endsWith("@prijslijst.info");

    return (
        <EuiPage>
            <EuiPageBody component="div">
                <EuiPanel color="transparent" hasBorder={false} hasShadow={false}>
                    <EuiTabs>{renderTabs()}</EuiTabs>
                    <EuiSpacer />
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiTitle size="l">
                                <h1>{capitalizeFirstLetter(selectedTabId)} Producten</h1>
                            </EuiTitle>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiLink href={`new-product/${selectedTabId}`}>
                                <EuiButton size="s" iconType="plus">
                                    Prijs
                                </EuiButton>
                            </EuiLink>
                        </EuiFlexItem>
                        {selectedTabId === "cannabis" && (
                            <>
                                {shopContext.shopId !== "f155b35a-14a1-4866-867f-93fe21e9769b" && (
                                    <EuiFlexItem grow={false}>
                                        <EuiLink href={"new-product/kind"}>
                                            <EuiButton size="s" iconType="plus">
                                                Wiet
                                            </EuiButton>
                                        </EuiLink>
                                    </EuiFlexItem>
                                )}

                                {showAdminLinks && (
                                    <>
                                        <EuiFlexItem grow={false}>
                                            <EuiLink href={"manage/strains-tags"}>
                                                <EuiButton size="s" iconType="pencil">
                                                    Kruisingen & Tags
                                                </EuiButton>
                                            </EuiLink>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiLink href={"manage/categories"}>
                                                <EuiButton size="s" iconType="pencil">
                                                    Categories
                                                </EuiButton>
                                            </EuiLink>
                                        </EuiFlexItem>
                                    </>
                                )}
                            </>
                        )}
                        <EuiFlexItem grow={false}>
                            <EuiLink onClick={() => setSearchIsOpen(!searchIsOpen)}>
                                <EuiIcon type="search" size="l" />
                            </EuiLink>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size="s" />
                    <div>
                        {filteredData.length > 0 && (
                            <ProductResults
                                products={filteredData}
                                searchIsOpen={searchIsOpen}
                                mode={selectedTabId}
                                key={selectedTabId} // Needed so tab re-renders
                                refresh={refreshData}
                            />
                        )}
                        {!(filteredData.length > 0) && <EuiLoadingSpinner size="xl" />}
                    </div>
                </EuiPanel>
            </EuiPageBody>
        </EuiPage>
    );
}

export default Products;
