import { EuiButton, EuiText } from "@elastic/eui";
import AWS from "aws-sdk";
import React, { useState } from "react";

import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, REGION, S3_BUCKET } from "../Constants";

AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

const UploadImageToS3WithNativeSdk = () => {
    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const uploadFile = (file) => {
        const params = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name,
        };

        myBucket
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
            })
            .send((err) => {
                if (err) console.log(err);
            });
    };

    return (
        <div>
            <input type="file" onChange={handleFileInput} />
            <EuiButton size="s" onClick={() => uploadFile(selectedFile)}>
                {" "}
                Upload to S3
            </EuiButton>
            <EuiText style={{ marginTop: "20px", marginBottom: "10px" }}>Upload voortgang {progress}%</EuiText>
        </div>
    );
};

export default UploadImageToS3WithNativeSdk;
