import { EuiFlexItem } from "@elastic/eui";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Badge } from "./Badge";
import { deSteegLegend, easyGoingLegend, maryJaneLegend, mississippiLegend } from "./LegendStyling";
import { easyGoingBadges, maryJaneBadges, mississippiBadges } from "./PriceRowStyling";

export const PriceLegend = (props) => {
    const { shopId } = props;
    const stylesRow = (() => {
        switch (shopId) {
            case "bbc0cfa7-e230-4338-817a-55190debdac0":
                return maryJaneBadges;
            case "ddd70853-5e9d-45ff-82b3-24c9d81d4e32":
                return easyGoingBadges;
            default:
                return mississippiBadges;
        }
    })();

    const styles = (() => {
        switch (shopId) {
            case "bbc0cfa7-e230-4338-817a-55190debdac0":
                return maryJaneLegend;
            case "ddd70853-5e9d-45ff-82b3-24c9d81d4e32":
                return easyGoingLegend;
            case "470f3f5a-e7b9-43a5-bbfd-2ffce8c161e4":
                return deSteegLegend;
            default:
                return mississippiLegend;
        }
    })();

    return (
        <EuiFlexItem css={styles}>
            <div className="legend">
                <div className="container">
                    <div className="content">
                        <div className="legendRow">
                            <div className="newIcon">
                                <FontAwesomeIcon
                                    style={{
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        marginLeft: "0.5vw",
                                        fontSize: "1.75vw",
                                    }}
                                    icon={faStar}
                                    color={"#d1cb28"}
                                />
                            </div>
                            <div className="label">New</div>
                        </div>
                        <div className="legendRow legendBadge">
                            <Badge letter={"c"} styles={stylesRow} />
                            <div className="label">CBD</div>
                        </div>
                        <div className="legendRow legendBadge">
                            <Badge letter={"i"} styles={stylesRow} />
                            <div className="label">Indica</div>
                        </div>

                        <div className="legendRow legendBadge">
                            <Badge letter={"s"} styles={stylesRow} />
                            <div className="label">Sativa</div>
                        </div>
                        <div className="legendRow legendBadge">
                            <Badge letter={"h"} styles={stylesRow} />
                            <div className="label">Hybrid</div>
                        </div>
                    </div>
                </div>
            </div>
        </EuiFlexItem>
    );
};
