import { css } from "@emotion/react";

import { COLORS } from "../../stylesheets/emotion/vars";

export const roundabout69Style = css`
    body {
        overflow-y: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        height: 100vh;
        width: 100vw;
    }

    .bannerAll {
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .bannerBack {
        width: 17.5%;
        margin-top: 0.55vw;
        position: absolute;
        margin-left: 53.6%;
    }

    .bannerFront {
        width: 19%;
        margin-left: 27.3%;
        margin-top: 0.68vw;
        position: absolute;
    }

    .bannerLogo {
        width: 4.2vw;
        position: relative;
        margin-top: 0.1vw;
        margin-left: 132.7%;
        z-index: 1000;
        filter: drop-shadow(0px 0px 5px #fff);
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg) scale(0.5);
            opacity: 0.4;
        }
        5% {
            transform: rotate(180deg) scale(0.5);
            opacity: 0.6;
        }
        10% {
            transform: rotate(360deg) scale(0.5);
            opacity: 0.4;
        }
        100% {
            transform: rotate(360deg) scale(0.5);
            opacity: 0.4;
        }
    }

    .menukaart {
        width: 100vw;
        height: 100vw;
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .menulinks {
        width: 45vw;
        height: 45vw;
        margin-left: 2.5vw;
        margin-top: 2vw;
        position: relative;
    }

    .menurechts {
        width: 45vw;
        height: 45vw;
        margin-left: 3.5vw;
        margin-top: 2vw;
        position: relative;
    }

    .backgroundShadow {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 15px;
    }

    .info {
        display: flex;
        flex-direction: row-reverse;
    }

    .row {
        padding-top: 0vw;
        width: 99.8%;
        margin-left: 1px;
        margin-bottom: 10px;
        background-color: rgba(50, 50, 50, 0.1);
        padding-right: 10px;
    }

    .row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .row:nth-last-child(1) {
        border-bottom-left-radius: 1vw;
        border-bottom-right-radius: 1vw;
    }

    .rowContent {
        padding: 0.22vw 0;
    }

    .productkind {
        color: white;
        font-size: 0.9vw;
        margin-top: 0.3vw;
        width: 3vw;
        margin-left: 2vw;
    }

    .banner {
        width: 100%;
        margin-left: 8vw;
        margin-top: 1vw;
        position: relative;
    }

    .pagebackground {
        background-image: url("/images/pricelist/roundabout69_cannabis_blue.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 1920px;
    }

    .redbg {
        position: absolute;
    }

    @font-face {
        font-family: "cordia";
        font-weight: normal;
        font-style: normal;
    }

    .logo {
        position: absolute;
        left: 20%;
        margin-top: 5vh;
        width: 60%;
        animation: rotation 25s 20s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        filter: drop-shadow(0px 0px 10px #fff);
    }

    .marginTopTitle {
        margin-top: -4vw;
    }

    .title {
        margin-left: 0.6vw;
        font-size: 2.4vw;
        font-weight: 600;
        margin-top: 1.2vw;
        margin-bottom: 1vw;
        position: relative;
        width: 20.3vw;
        color: white;
        text-shadow: 2px 2px #000000;
    }

    .titleNotAtTop {
        margin-left: 0.6vw;
        font-size: 2.4vw;
        font-weight: 600;
        margin-top: 1.2vw;
        margin-bottom: 1vw;
        position: relative;
        width: 20vw;
        color: white;
        text-shadow: 2px 2px #000000;
    }

    .particles {
        position: absolute;
        margin-left: 50vw;
        width: 300px;
    }

    .btn {
        position: absolute;
    }

    .kindBadge {
        margin: auto;
        padding: 0 0.75vw; //Padding between badge and namePrice
    }

    .nameStar {
        display: flex;
    }

    .newBadge {
    }

    .nameprice {
        font-size: 1.4vw;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        //margin-top: 0vw; // Fix for font being a little bit up
    }

    .description {
        font-size: 0.75vw;
        color: ${COLORS.descriptionColor};
        margin: 0 auto;
        display: none;
    }

    .kind {
        font-size: 0.5vw;
        position: relative;
        margin-left: -2vw;
        margin-top: 0.5vw;
        width: 5vw;
        color: white;
    }

    .priceContainer {
        position: relative;
        left: 1.4vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .price {
        font-size: 1.3vw;
        width: 5vw;
        color: white;
    }

    .priceHeader {
        position: relative;
        left: 0.15vw;
        //margin-left: 10px;
        font-size: 1.4vw;
        margin-top: -2.3vw;
        width: 4.8vw;
        color: white;
    }

    .filler {
        flex-grow: 1;
    }

    .svg-inline--fa {
        margin-left: 20px;
        margin-top: 8px;
        font-size: 30px;
    }

    .legend {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .legendContainer {
        padding: 15px;
        width: 45vw;
        background-color: ${COLORS.pricelistTableContainer};
        border-radius: 15px;
    }
`;
