import "./Pricelist.scss";

import { EuiFlexItem } from "@elastic/eui";
import { PriceLegendNew } from "components/LegendNew";
import { PriceRowNew } from "components/PriceRowNew";
import { PriceTableHeaderNew } from "components/PriceTableHeaderNew";
import React, { useCallback, useEffect, useState } from "react";

import client from "../api/Client";
import { BannerAll } from "../components/BannerAll";
import { BottomBar } from "../components/BottomBar";
// import { PriceRow } from "../components/PriceRow";
// import { PriceTableHeader } from "../components/PriceTableHeader";
import API_URL, { WEBSOCKET_URL } from "../Constants";
import { Price, PricelistColumns } from "../utils/types";
import { getCategoryNameFromPrices } from "../utils/Utils";
// import { deSteegStyle } from "./pricelist-styles/DeSteegStyle";
// import { easyGoingStyle } from "./pricelist-styles/EasyGoingStyle";
// import { maryJaneStyle } from "./pricelist-styles/MaryJaneStyle";
// import { mississippiStyle } from "./pricelist-styles/MississippiStyle";
// import { missouriStyle } from "./pricelist-styles/MissouriStyle";
import { roundabout69Style } from "./pricelist-styles/RoundAbout69Style";

// import { useParams } from "react-router";

// export const PRICE_COLS = ["half", "one", "two_five", "five", "joint", "piece"];

interface PricelistCategorySection {
    category_pricelist_column: PricelistColumns;
    category_pricelist_row: number;
    category_name: string;
    prices: Price[];
}

const PriceListNew = () => {
    // let { shopId } = useParams(); // can be replaced with const in the future
    const shopId = "f155b35a-14a1-4866-867f-93fe21e9769b";
    const [prices, setPrices] = useState([]);
    // const [cacheDate, setCacheDate] = useState<string | undefined>(undefined);
    // const [connectedToWebSocket, setConnectedToWebSocket] = useState(false);
    const styles = (() => {
        // switch (shopId) {
        //     case "bbc0cfa7-e230-4338-817a-55190debdac0":
        //         return maryJaneStyle;
        //     case "a08a13e2-a31b-4b6d-b2b4-0491cb3d2227":
        //         return missouriStyle;
        //     case "ddd70853-5e9d-45ff-82b3-24c9d81d4e32":
        //         return easyGoingStyle;
        //     case "470f3f5a-e7b9-43a5-bbfd-2ffce8c161e4":
        //         return deSteegStyle;
        //     case "f155b35a-14a1-4866-867f-93fe21e9769b":
        //         return roundabout69Style;
        //     default:
        //         return mississippiStyle;
        // }
        return roundabout69Style;
    })();

    // shopId = shopId ?? "19149768-691c-40d8-a08e-fe900fd23bc0"; // for backwards compatibility

    //use mississippi prices for de steeg

    const refreshData = useCallback(() => {
        client.get(`${API_URL}/v1/shops/${shopId}`).then((result) => {
            if (window.location.pathname.includes("extra")) {
                setPrices(
                    result.data.prices
                        .filter((price: Price) => price.active)
                        .filter(
                            (price: Price) =>
                                price.category_name.toLowerCase() === "edibles" ||
                                price.category_name.toLowerCase() === "prerolled joints"
                        )
                );
            } else {
                setPrices(
                    result.data.prices
                        .filter((price: Price) => price.active)
                        .filter((price: Price) => price.category_name.toLowerCase() !== "edibles")
                );
            }
        });
    }, [shopId]);

    const connectWebSocket = useCallback(() => {
        const socket = new WebSocket(`${WEBSOCKET_URL}?shopId=${shopId}&connectionType=shop`);

        socket.onopen = () => {
            console.log("Connection with websocket server established.", socket);
            // setConnectedToWebSocket(true);
        };
        socket.onerror = () => {
            console.log("Error connecting to websocket server, falling back to polling.");
            // setConnectedToWebSocket(false);
            // setInterval(checkCacheDate, 5000);
        };
        socket.onmessage = (event) => {
            console.log(`[message] Data received from server: ${event.data}`);
            console.log(JSON.parse(event.data));
            if (JSON.parse(event.data) === "invalidate_shop_cache") {
                refreshData();
            }
        };
        socket.onclose = () => {
            console.log("Re-spawning websocket");
            connectWebSocket();
        };
    }, [shopId, refreshData]);

    const checkCacheDate = useCallback(() => {
        client.get(`${API_URL}/v1/shops/cache-status/${shopId}`).then((result) => {
            const cacheDate = result.data.modified_at;
            const storedCacheDate = localStorage.getItem("cacheDate");
            if (storedCacheDate !== cacheDate) {
                console.log("Fallback cache check found a difference!");
                localStorage.setItem("cacheDate", cacheDate);
                refreshData();
            }
        });
    }, [shopId, refreshData]);

    useEffect(() => {
        client.get(`${API_URL}/v1/shops/cache-status/${shopId}`).then((result) => {
            const cacheDate = result.data.modified_at;
            // setCacheDate(cacheDate);
            localStorage.setItem("cacheDate", cacheDate);
            setInterval(checkCacheDate, 60000);
        });
        refreshData();
        connectWebSocket();
    }, [checkCacheDate, connectWebSocket, shopId, refreshData]);

    const getFirstNotEmptyCategory = (categories: Price[][]) => {
        for (let category of Object.values(categories)) {
            if (category.length > 0) {
                return category[0].category_name;
            }
        }
    };

    let uniqueCategories: PricelistCategorySection[] = [];
    let leftCategories: PricelistCategorySection[] = [];
    let rightCategories: PricelistCategorySection[] = [];

    //Get unique categories from the array of all prices
    for (const price of prices) {
        const { category_name, category_pricelist_column, category_pricelist_row } = price;

        if (price.active && price.kind_id !== null) {
            if (!uniqueCategories.some((category) => category.category_name === category_name)) {
                uniqueCategories.push({
                    category_pricelist_column,
                    category_pricelist_row,
                    category_name,
                    prices: [],
                });
            }

            //Push price to a unique category
            const foundCategory = uniqueCategories.find((category) => category.category_name === category_name);
            foundCategory && foundCategory.prices.push(price);
        }
    }

    //Sort unique categories into left and right
    for (const category of uniqueCategories) {
        if (category.category_pricelist_column === PricelistColumns.LEFT) {
            leftCategories.push(category);
            uniqueCategories = uniqueCategories.filter((cat) => cat.category_name !== category.category_name);
        } else if (category.category_pricelist_column === PricelistColumns.RIGHT) {
            rightCategories.push(category);
            uniqueCategories = uniqueCategories.filter((cat) => cat.category_name !== category.category_name);
        }
    }

    //Auto sorting if no left or right is specified in the database
    if (leftCategories.length === 0) {
        const result = uniqueCategories.reduce((maxObj, currentObj) => {
            return currentObj.prices.length > maxObj.prices.length ? currentObj : maxObj;
        }, uniqueCategories[0]);
        result && leftCategories.push(result);
        uniqueCategories = uniqueCategories.filter((c) => c.category_name !== result.category_name);
        rightCategories.push(...uniqueCategories);
    } else {
        rightCategories.push(...uniqueCategories);
    }

    //Finally sort by row numberc
    const sortByTableIndexAndName = (arr: PricelistCategorySection[]) => {
        return arr.sort((a, b) => {
            if (a.category_pricelist_row === null && b.category_pricelist_row === null) {
                return a.category_name.localeCompare(b.category_name);
            } else if (a.category_pricelist_row === null) {
                return 1;
            } else if (b.category_pricelist_row === null) {
                return -1;
            } else {
                return a.category_pricelist_row - b.category_pricelist_row;
            }
        });
    };

    rightCategories = sortByTableIndexAndName(rightCategories);
    leftCategories = sortByTableIndexAndName(leftCategories);

    const leftSide: Price[][] = leftCategories.length > 0 ? leftCategories.map((price) => price.prices) : [];
    const rightSide: Price[][] = rightCategories.length > 0 ? rightCategories.map((price) => price.prices) : [];
    // const firstLeftPrice = leftCategories.length > 0 ? leftCategories[0].prices : [];

    const leftColsName: string[] = [];
    leftCategories.forEach((leftCat) => {
        leftCat.prices.forEach((price) => {
            price.json_prices.forEach((jp) => {
                if (!leftColsName.includes(jp.label)) {
                    leftColsName.push(jp.label);
                }
            });
        });
    });

    const rightColsName: string[] = [];
    rightCategories.forEach((rightCat) => {
        rightCat.prices.forEach((price) => {
            price.json_prices.forEach((jp) => {
                if (!rightColsName.includes(jp.label)) {
                    rightColsName.push(jp.label);
                }
            });
        });
    });

    // const leftCols = leftPriceCols.map((col) => firstLeftPrice.some((price) => price[col]));
    // let leftColsName = [];
    // for (const [index, value] of leftCols.entries()) {
    //     if (value) {
    //         leftColsName.push(PRICE_COLS[index]);
    //     }
    // }

    // const rightCols = PRICE_COLS.map((col) =>
    //     prices
    //         .filter((price: Price) =>
    //             rightCategories
    //                 .map((price) => price.prices)
    //                 .flat()
    //                 .includes(price)
    //         )
    //         .some((price) => price[col])
    // );
    // let rightColsName = leftColsName;
    // for (const [index, value] of rightCols.entries()) {
    //     if (value) {
    //         rightColsName.push(PRICE_COLS[index]);
    //     }
    // }

    function sortCols(array: string[]) {
        return array.sort((a, b) => {
            const aIsGram = /^[\d.]+g$/.test(a);
            const bIsGram = /^[\d.]+g$/.test(b);

            if (aIsGram && bIsGram) {
                return (parseFloat(a) * 10 - parseFloat(b) * 10) / 10;
            } else if (aIsGram) {
                return -1;
            } else if (bIsGram) {
                return 1;
            } else {
                return a.localeCompare(b);
            }
        });
    }

    const Categories = ({ categories, colsName }: { categories: Price[][]; colsName: any }) => {
        return (
            <>
                {Object.values(categories).map((prices) => {
                    if (prices.length > 0) {
                        console.log(prices);
                        const cols: string[] = [];

                        prices.forEach((price) => {
                            price.json_prices.forEach((jp) => {
                                if (!cols.includes(jp.label)) {
                                    cols.push(jp.label);
                                }
                            });
                        });

                        return (
                            <div key={getCategoryNameFromPrices(prices)}>
                                <PriceTableHeaderNew
                                    title={getCategoryNameFromPrices(prices)}
                                    cols={sortCols(cols)}
                                    showColumsHeader={
                                        getFirstNotEmptyCategory(categories) === getCategoryNameFromPrices(prices)
                                    }
                                    topHeader={
                                        getFirstNotEmptyCategory(categories) === getCategoryNameFromPrices(prices)
                                    }
                                />
                                <div className="backgroundShadow">
                                    <PriceRowNew prices={prices} cols={sortCols(cols)} shopId={shopId} />
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </>
        );
    };

    return (
        <EuiFlexItem css={styles}>
            <embed className="logo" src="/images/pricelist/roundabout69_logo_black.png" />
            <div className="pagebackground">
                <BottomBar />
                <BannerAll shopId={shopId} />
                {/* <div className="pattern" /> */}
                <div className="menukaart">
                    <div className="menulinks">
                        <div className="marginTopTitle">
                            <Categories categories={leftSide} colsName={leftColsName} />
                        </div>
                    </div>
                    <div className="menurechts">
                        <div className="marginTopTitle">
                            <Categories categories={rightSide} colsName={rightColsName} />
                        </div>
                    </div>
                </div>
            </div>
            <PriceLegendNew shopId={shopId} />
        </EuiFlexItem>
    );
};

export default PriceListNew;
