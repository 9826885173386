import { EuiFlexGroup, EuiFlexItem, EuiPageBody } from "@elastic/eui";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import client from "../../api/Client";
import { DynamicPricelist } from "../../components/DynamicPricelist/DynamicPricelist";
import KindBadge from "../../components/DynamicPricelist/KindBadge";
import PriceTable from "../../components/OrderTable";
import API_URL from "../../Constants";
import {
    cannabisDynamicPricelistSettingsLoader,
    filterActivePrices,
    generateTableRowsAndColumns,
    sortByCategoryAndLowestPrice,
} from "../../utils/DynamicPricelistUtils";
import { PricelistQueryKey, PricelistTableRow, RowType } from "../../utils/types";
import { formatMoney } from "../../utils/Utils";
import { useGetLivePricelistUpdates } from "../../utils/Websocket";
import { DeSteegPriceModel } from "../dynamicPricelistSettings/cannabis/DeSteegCannabisSettings";

const DynamicPricelistCannabis = () => {
    const { shopId } = useParams();
    useGetLivePricelistUpdates(shopId, PricelistQueryKey.CANNABIS);
    const { styles, maxRowsPerColumn, rowHeight, badgesStyles, renderBackground, excludedCategories, renderLegend } =
        cannabisDynamicPricelistSettingsLoader(shopId);
    const maxHeight = maxRowsPerColumn * rowHeight;
    const { data, isLoading } = useQuery(PricelistQueryKey.CANNABIS, () =>
        client.get(`${API_URL}/v1/shops/${shopId}?is_horeca=false`)
    );
    const activePrices = useMemo(
        () =>
            filterActivePrices<DeSteegPriceModel>(data?.data?.prices, excludedCategories).sort(
                sortByCategoryAndLowestPrice
            ),
        [data, excludedCategories]
    );
    const tableRowsAndColumns = useMemo(
        () => generateTableRowsAndColumns<DeSteegPriceModel>(activePrices, maxRowsPerColumn),
        [activePrices, maxRowsPerColumn]
    );

    // TODO: This is currently tuned for DeSteeg, but eventually should be moved to the settings
    const renderCannabisRow = (row: PricelistTableRow<DeSteegPriceModel>) => {
        if (row.type === RowType.Title) {
            return (
                <div className="titleRow">
                    <p>{row.text}</p>
                </div>
            );
        } else if (row.type === RowType.DataRow) {
            return (
                <EuiFlexGroup
                    gutterSize={"xs"}
                    className="namePriceRow"
                    justifyContent={"spaceBetween"}
                    alignItems={"center"}
                >
                    <EuiFlexItem>
                        <EuiFlexGroup alignItems="center" gutterSize="s">
                            {badgesStyles && (
                                <EuiFlexItem className="kindBadge" grow={false}>
                                    <KindBadge row={row} badgesStyles={badgesStyles} />
                                </EuiFlexItem>
                            )}
                            <EuiFlexItem grow={false} className="name">
                                <p>{row.text}</p>
                            </EuiFlexItem>
                            <EuiFlexItem className="kindIcon" grow={false}>
                                <EuiFlexGroup gutterSize="xs">
                                    {row.price?.flags?.map((flag) => (
                                        <EuiFlexItem>{flag}</EuiFlexItem>
                                    ))}
                                    {row.price?.icons?.map((icon) => (
                                        <EuiFlexItem>{icon}</EuiFlexItem>
                                    ))}
                                </EuiFlexGroup>
                            </EuiFlexItem>
                            {row.price?.new && (
                                <EuiFlexItem className="starIcon" grow={false}>
                                    <p>⭐</p>
                                </EuiFlexItem>
                            )}
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <div className="priceQuantityContainer">
                            {row.price?.json_prices
                                ?.sort((a, b) => a.price - b.price)
                                .map((priceModel: DeSteegPriceModel) => (
                                    <div className="priceQuantityItem">
                                        <p className="quantity">
                                            {priceModel.label.replace("gram", "g").replace(" ", "")}
                                        </p>
                                        <p className="price">{formatMoney(priceModel.price, "short")}</p>
                                    </div>
                                ))}
                            {!row.price?.json_prices && <PriceTable price={row.price ?? {}} padding={20} />}
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>
            );
        } else if (row.type === RowType.Legend) {
            return renderLegend ? renderLegend : <></>;
        }
    };

    return (
        <EuiFlexItem css={styles}>
            <EuiPageBody className="pricelistBody">
                {/* <div className="pricelistLogoHeader">
                    {bannersURLs?.bannerFront && (
                        <img alt="Banner front" className="bannerFront" src={bannersURLs?.bannerFront} />
                    )}
                    <img alt="Banner logo" className="bannerLogo" src={bannersURLs.bannerLogo} />
                    {bannersURLs?.bannerBack && (
                        <img alt="Banner back" className="bannerBack" src={bannersURLs?.bannerBack} />
                    )}
                </div> */}
                <DynamicPricelist
                    tableRowsColumns={tableRowsAndColumns}
                    isLoading={isLoading}
                    maxHeight={maxHeight}
                    renderBackground={renderBackground}
                    renderRow={renderCannabisRow}
                />
            </EuiPageBody>
        </EuiFlexItem>
    );
};

export default DynamicPricelistCannabis;
