/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import { Action, ProductForTable } from "utils/types";

export function actionOptions(
    product: ProductForTable,
    completeAction: (e: React.MouseEvent<HTMLButtonElement>) => void,
    deleteAction: (e: React.MouseEvent<HTMLButtonElement>) => void
): Action[] {
    //TODO scope on the context of logged in-user
    const complete = {
        euiIcon: "pencil",
        label: "Bewerk product",
        action: completeAction,
    };
    const _delete = {
        euiIcon: "trash",
        label: "Wis product",
        action: deleteAction,
        danger: true,
    };
    const options = [complete, _delete];

    return options;
}
