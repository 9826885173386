/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import "../EditPictures.scss";

import crypto from "crypto";

import {
    EuiButton,
    EuiFilePicker,
    EuiFlexGroup,
    EuiFlexItem,
    EuiImage,
    EuiPanel,
    EuiSpacer,
    EuiText,
    EuiTitle,
} from "@elastic/eui";
import AWS from "aws-sdk";
import noImage from "images/no_image.jpg";
import React, { Fragment, createRef, useEffect, useState } from "react";

import client from "../../api/Client";
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, REGION, S3_BUCKET, S3_UPLOAD_BUCKET } from "../../Constants";
import { KindForTable, MutationType, ProductForTable, ResourceType } from "../../utils/types";
import { imageCounter, simulateMouseClick } from "../../utils/Utils";
import { getImageSrc } from "../ProductImage";

AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

interface IProps {
    data: ProductForTable | KindForTable;
    id: String;
    type: ResourceType;
    setPictureUploadVisible: (arg: boolean) => void;
    updateProduct: (
        product: ProductForTable | KindForTable,
        updateType: MutationType,
        objectType?: string | undefined
    ) => void;
}

interface UpdatedPicture {
    index: number;
    file: File;
}

function EditPictures({ data, id, type, setPictureUploadVisible, updateProduct }: IProps) {
    const [product, setProduct] = useState<KindForTable | ProductForTable>(data);
    const [loading, setLoading] = useState<boolean>(true);
    const [progress, setProgress] = useState(0);
    const [imageCount, setImageCount] = useState(0);
    const [filesState, setFilesState] = useState<any>({});
    const [uploading, setUploading] = useState(false);
    const [filePickerRef, setFilePickerRef] = useState<any>([]);
    const [currentFilePickerIndex, setCurrentFilePickerIndex] = useState<number>(-1);
    const [updatedPicturesIndexes, setUpdatedPicturesIndexes] = useState<UpdatedPicture[]>([]);
    var element = document.getElementById("reset_filepicker_button");

    useEffect(() => {
        setLoading(false);
        setImageCount(imageCounter(data));
        createRefs(imageCounter(data));
    }, [id, data]);

    useEffect(() => {
        if (progress === 100) {
            setProgress(0);
        }
    }, [progress]);

    useEffect(() => {
        if (filesState.length > 0) {
            uploadFile(filesState[0]);
            simulateMouseClick(element);
        }
        // }, [filesState.length]);
    }); // Todo: fix deps problem

    const createRefs = (imageCount: number) => {
        for (let i = 0; i < imageCount; i++) {
            setFilePickerRef((filePickerRef: any) => [...filePickerRef, createRef()]);
        }
    };

    const onChange = (files: FileList | null, index: number) => {
        let newFile = files?.item(0);
        setCurrentFilePickerIndex(index);
        // @ts-ignore
        setFilesState(files.length > 0 ? Array.from(files) : []);
        if (newFile !== null) {
            if (!updatedPicturesIndexes.find((o) => o.index === index)) {
                setUpdatedPicturesIndexes((updatedPicturesIndexes) => [
                    ...updatedPicturesIndexes,
                    { index: index, file: newFile } as UpdatedPicture,
                ]);
            } else {
                const foundIndex = updatedPicturesIndexes.findIndex((o) => o.index === index);
                let newArray = updatedPicturesIndexes;
                newArray.splice(foundIndex, 1, { index: index, file: newFile } as UpdatedPicture);
                setUpdatedPicturesIndexes(newArray);
            }
        }
    };

    function uploadFile(file: any) {
        const uuid = crypto.randomBytes(16).toString("hex");
        const reader = new FileReader();
        let img = new Image();

        let filetype = "." + file.type.replace("image/", "");
        let fullImageName = "";

        reader.readAsDataURL(file);
        reader.addEventListener(
            "load",
            () => {
                img.title = file.name;
                if (typeof reader.result === "string") {
                    img.src = reader.result;
                }
            },
            false
        );

        img.onload = function () {
            fullImageName = `${uuid}---${img.width}x${img.height}${filetype}`;

            const params = {
                ACL: "public-read",
                Body: file,
                Bucket: S3_UPLOAD_BUCKET,
                Key: "upload/" + fullImageName,
            };

            myBucket
                .putObject(params)
                .on("httpUploadProgress", (evt) => {
                    setProgress(Math.round((evt.loaded / evt.total) * 100));
                })
                .send((err, data) => {
                    if (err) console.log(err);
                    else {
                        let newProduct = JSON.parse(JSON.stringify(product));
                        newProduct[`image_${currentFilePickerIndex + 1}`] = fullImageName;
                        setProduct(newProduct);
                    }
                });
        };
    }

    const renderFiles = () => {
        if (filesState.length > 0) {
            return (
                <ul>
                    {filesState.map((file: any, i: any) => (
                        <li key={i}>
                            <strong>{file.name}</strong> ({file.size} bytes)
                        </li>
                    ))}
                </ul>
            );
        }
        // else {
        //     return <p>Add some files to see a demo of retrieving from the FileList</p>;
        // }
    };

    //use the images uploaded to S3_BUCKET/upload folder before pressing Submit
    const getFolderIfImageUpdated = (index: number) => {
        return updatedPicturesIndexes.find((o) => o.index === index) ? "upload/" : "non-existing/";
    };

    const renderFilePickers = () => {
        let filePickers = [];
        for (let i = 0; i < imageCount; i++) {
            filePickers.push(
                <EuiFlexItem key={i} grow={1} style={{ width: 150, maxWidth: "20vw" }}>
                    <EuiImage
                        hasShadow
                        // @ts-ignore
                        allowFullScreen={updatedPicturesIndexes.find((o) => o.index === i)}
                        size="xl"
                        caption={`Foto ${i + 1}`}
                        alt="alt"
                        src={getImageSrc(
                            // @ts-ignore
                            getFolderIfImageUpdated(i) + product[`image_${i + 1}`],
                            2000,
                            S3_UPLOAD_BUCKET
                        )}
                        wrapperProps={{ className: "eui-textLeft" }}
                        onError={(e) => {
                            e.currentTarget.src = noImage;
                        }}
                    />

                    <EuiSpacer />
                    <EuiFilePicker
                        // @ts-ignore
                        ref={filePickerRef[i]}
                        id={`filepicker_image_${i + 1}`}
                        initialPromptText="Blader of sleep"
                        onChange={(e) => onChange(e, i)}
                        display="default"
                        aria-label={`Filepicker image ${i + 1}`}
                    />
                </EuiFlexItem>
            );
        }

        return filePickers;
    };

    const renderInvisibleButton = () => {
        return (
            <EuiButton
                style={{ display: "none" }}
                id="reset_filepicker_button"
                color="danger"
                iconType="trash"
                disabled={filesState.length > 0 ? false : true}
                // @ts-ignore
                onClick={() => filePickerRef[currentFilePickerIndex].current.removeFiles()}
            >
                <h3>Remove files</h3>
            </EuiButton>
        );
    };

    //TODO: Implement a function that asks user changes unsaved ? Delete on close, back
    //TODO: fix product list to show new pic when clicked on back

    const handleSubmitButton = () => {
        setUploading(true);
        client
            .post("/v1/images/move")
            .then((p) => {
                console.log(p);
                updateProduct(product, MutationType.PictureUpload);
            })
            .catch((e) => console.error(e));
    };

    const handleCancelButton = () => {
        setPictureUploadVisible(false);
        // setProduct()
        client
            .post("/v1/images/delete-temp")
            .then((p) => {
                console.log(p);
            })
            .catch((e) => console.error(e));
    };

    return (
        <EuiPanel color="transparent" hasBorder={false}>
            <EuiTitle>
                <h1>Bewerk foto's</h1>
            </EuiTitle>
            {type === ResourceType.Category && (
                <EuiText>
                    <span style={{ fontSize: 15 }}>
                        <b>
                            Let op: voor een correcte weergave in de mobile app is deze aspect ratio nodig; 5 / 2.2.
                            Minimale afmetingen: 1920px x 326px
                        </b>
                    </span>
                </EuiText>
            )}
            <EuiSpacer />
            <Fragment>
                <EuiText style={{ marginTop: "20px", marginBottom: "10px" }}>Upload voortgang {progress}%</EuiText>
                <EuiFlexGroup>{!loading && renderFilePickers()}</EuiFlexGroup>
                <EuiSpacer />
            </Fragment>
            {renderFiles()}
            {renderInvisibleButton()}
            <EuiFlexGroup>
                <EuiFlexItem style={{ maxWidth: 100 }}>
                    <EuiButton onClick={() => handleCancelButton()}>Cancel</EuiButton>
                </EuiFlexItem>
                <EuiFlexItem style={{ maxWidth: 150 }}>
                    <EuiButton isLoading={uploading} onClick={() => handleSubmitButton()} fill>
                        Submit
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            {renderFiles()}
        </EuiPanel>
    );
}

export default EditPictures;
