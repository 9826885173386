/*
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import "./Forms.scss";

import {
    EuiBadge,
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiGlobalToastList,
    EuiInMemoryTable,
    EuiLink,
    EuiModal,
    EuiModalBody,
    EuiPanel,
    EuiText,
    EuiTitle,
} from "@elastic/eui";
import { EuiBasicTableColumn } from "@elastic/eui/src/components/basic_table/basic_table";
import { EuiSearchBarProps } from "@elastic/eui/src/components/search_bar/search_bar";
import React, { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";

import client, { get_all_resources_by_type, postPutJson } from "../api/Client";
import { useMultiQuery } from "../api/ReactQuery";
import InlineTableTextEditor from "../components/editors/InlineTableTextEditor";
import GlobalToastContext from "../contextProviders/GlobalToastProvider";
import { ShopContext } from "../utils/Shop";
import {
    CreateResource,
    MainCategory,
    MutationType,
    PricelistColumns,
    ResourceItem,
    ResourcePayload,
    ResourceType,
    TableItem,
} from "../utils/types";
import { capitalizeFirstLetter } from "../utils/Utils";
import ActionContainer from "./ActionContainer";
import CreateForm from "./CreateForm";
import { DropDownActionsWaitDelete } from "./DropDownActionsWaitDelete";
import RadioButtonEditor from "./editors/RadioButtonEditor";
import ConfirmationDialog from "./modals/ConfirmationDialog";
import ProductImage from "./ProductImage";

interface IProps {
    type: ResourceType;
    extraQueries?: string[];
}

let debounceTimeoutId: ReturnType<typeof setTimeout>;
let requestTimeoutId: ReturnType<typeof setTimeout>;

export default function CRUDResourceItem({ type, extraQueries }: IProps) {
    let { shopId } = useContext(ShopContext);
    let { toasts, addToastHandler, removeToast, addErrorToast } = useContext(GlobalToastContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resourceItems, setResourceItems] = useState<TableItem[]>([]);
    const [filteredItems, setFilteredItems] = useState<TableItem[]>([]);
    const [currentSelectedItem, setCurrentSelectedItem] = useState({ row: -1, column: -1 });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState<TableItem>({ index: 0, value: { id: "", name: "" } });

    const history = useHistory();
    let queryClient = useQueryClient();
    const { data } = useQuery([`${type}s`, shopId], () => get_all_resources_by_type(`${type}s`, shopId).then(), {
        refetchOnWindowFocus: false,
        onSuccess: (successData) => {
            const reversedItems = successData.slice().reverse();
            const indexedItems = giveIndexes(reversedItems);
            setResourceItems(indexedItems);
            setFilteredItems(indexedItems);
        },
    });
    useMultiQuery(extraQueries, get_all_resources_by_type);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
        pageSizeOptions: [15, 30, 50, 0],
    });

    const create_resource_mutation = useMutation((resource: ResourcePayload) => {
        return postPutJson(`v1/${type}s/`, resource, "post", false, true);
    });

    const update_resource_mutation = useMutation((resource: ResourcePayload) => {
        return client.put(`/v1/${type}s/${resource.uuid}`, resource);
    });

    const delete_resource_mutation = useMutation((resource: ResourcePayload) => {
        return client.delete(`/v1/${type}s/${resource.uuid}`);
    });

    const deleteResource = (deleteItem?: TableItem) => {
        const resource: ResourcePayload = {
            uuid: deleteItem ? deleteItem.value.id : data[currentSelectedItem.row].id, //if the id is passed from a text editor or actions
        };
        delete_resource_mutation.mutate(resource, {
            onSuccess: () => {
                let newData = [...data];
                deleteItem ? newData.splice(deleteItem.index, 1) : newData.splice(currentSelectedItem.row, 1);
                queryClient.setQueryData([`${type}s`, shopId], newData);
                addToastHandler(MutationType.Deleted, type);
            },
            onError: (error: any) => {
                queryClient.refetchQueries([`${type}s`, shopId]);
                addErrorToast(error);
            },
        });
    };

    const updateResource = (value: string | number, key: string) => {
        const resource: ResourcePayload = {
            ...data[currentSelectedItem.row],
            [key]: value,
            uuid: data[currentSelectedItem.row].id,
        };
        update_resource_mutation.mutate(resource, {
            onSuccess: () => {
                let newData = [...data];
                let object = newData[currentSelectedItem.row];
                object[key] = value;
                newData[currentSelectedItem.row] = object;
                queryClient.setQueryData([`${type}s`, shopId], newData);
                addToastHandler(MutationType.Edited, type);
            },
            onError: (error: any) => {
                queryClient.refetchQueries([`${type}s`, shopId]);
                addErrorToast(error);
            },
        });
    };

    const createResource = (resource: CreateResource) => {
        create_resource_mutation.mutate(resource, {
            onSuccess: () => {
                client
                    .get(`/v1/${type}s/name/${resource.name}?shop_id=${shopId}`)
                    .then((response) => {
                        let newData = [...data];
                        let newResource = {
                            isNew: false,
                            id: "",
                            ...resource,
                        };
                        newResource.isNew = true;
                        newResource.id = response.data.id;
                        newData.push(newResource);
                        queryClient.setQueryData([`${type}s`, shopId], newData);
                        closeModal();
                        addToastHandler(MutationType.Added, type);
                    })
                    .catch((error) => {
                        queryClient.refetchQueries([`${type}s`, shopId]);
                        addErrorToast(error);
                    });
            },
            onError: (error: any) => {
                queryClient.refetchQueries([`${type}s`, shopId]);
                addErrorToast(error);
            },
        });
    };

    const handleSubmit = (userInputs: any) => {
        let resource: CreateResource = {};

        if (type === "categorie") {
            resource = {
                ...resource,
                name: userInputs["category_name"],
                name_en: userInputs["name_en"],
                // description: userInputs["description"],
                shop_id: shopId,
                main_category_id: userInputs["main_category_id"],
                color: userInputs["color"],
                icon: userInputs["icon"],
                cannabis: userInputs["is_cannabis"],
            };
        } else {
            resource = {
                ...resource,
                name: userInputs[`${type}_name`],
            };
        }
        createResource(resource);
    };

    const onSave = (value: string, mutationType: MutationType, keyName: string) => {
        if (mutationType === MutationType.Edited) {
            updateResource(value, keyName);
        } else if (mutationType === MutationType.Deleted) {
            deleteResource();
        }
    };

    const onSaveInt = (value: string, mutationType: MutationType, keyName: string) => {
        updateResource(parseInt(value), keyName);
    };

    const closeModal = () => setIsModalVisible(false);
    const showModal = () => setIsModalVisible(true);

    const changeCurrentTag = (row: number, column: number) => {
        setCurrentSelectedItem({ row: row, column: column });
    };

    function giveIndexes(data: ResourceItem[]) {
        let newData = [];
        for (let i = 0; i < data.length; i++) {
            newData.push({ index: data.length - (i + 1), value: data[i], isNew: data[i].isNew });
        }
        return newData;
    }

    const columns: Array<EuiBasicTableColumn<TableItem>> = [
        {
            name: `${capitalizeFirstLetter(type)} name`,
            truncateText: true,
            sortable: true,
            render: (object: TableItem) => (
                <InlineTableTextEditor
                    keyName={"name"}
                    initialValue={object.value.name}
                    isNew={!!object.value.isNew}
                    readonly={false}
                    onSave={onSave}
                    onEdit={changeCurrentTag}
                    selectedItem={currentSelectedItem}
                    selectedColumn={0}
                    index={object.index}
                    showDelete={!(type === ResourceType.Category)} // add the Actions also to edit tags/strains also instead of this
                />
            ),
            style: {
                cursor: "pointer",
                width: "100%",
            },
        },
    ];

    if (type === ResourceType.Category) {
        columns.push(
            {
                name: `${capitalizeFirstLetter(type)} EN name`,
                truncateText: true,
                render: (object: TableItem) => (
                    <InlineTableTextEditor
                        keyName={"name_en"}
                        initialValue={object.value.name_en}
                        isNew={!!object.value.isNew}
                        readonly={false}
                        onSave={onSave}
                        onEdit={changeCurrentTag}
                        selectedItem={currentSelectedItem}
                        selectedColumn={1}
                        index={object.index}
                        showDelete={false}
                    />
                ),
                style: {
                    cursor: "pointer",
                    width: "100%",
                },
            },
            {
                name: `Pricelist Position`,
                truncateText: true,
                render: (object: TableItem) => (
                    <RadioButtonEditor
                        keyName={"pricelist_column"}
                        initialValue={`${object.value.pricelist_column}`}
                        isNew={false}
                        onSave={onSave}
                        onEdit={changeCurrentTag}
                        selectedItem={currentSelectedItem}
                        selectedColumn={2}
                        index={object.index}
                        showDelete={false}
                        readonly={!object.value.cannabis}
                        options={[
                            { value: PricelistColumns.LEFT, label: "Left" },
                            { value: PricelistColumns.RIGHT, label: "Right" },
                        ]}
                    />
                ),
                style: {
                    cursor: "pointer",
                    width: "100%",
                },
            },
            {
                name: `Order Number`,
                truncateText: true,
                render: (object: TableItem) => (
                    <InlineTableTextEditor
                        keyName={"pricelist_row"}
                        initialValue={object.value.pricelist_row}
                        isNew={false}
                        onSave={onSaveInt}
                        onEdit={changeCurrentTag}
                        selectedItem={currentSelectedItem}
                        selectedColumn={3}
                        index={object.index}
                        showDelete={false}
                        readonly={!object.value.cannabis}
                    />
                ),
                style: {
                    cursor: "pointer",
                    width: "100%",
                },
            },
            {
                name: `Main Category`,
                width: "10vw",
                truncateText: true,
                render: (object: TableItem) => (
                    <EuiLink>
                        {queryClient.getQueryData(["main-categories"])
                            ? (queryClient.getQueryData("main-categories") as MainCategory[]).find(
                                  (item: MainCategory) => item.id === object.value.main_category_id
                              )?.name || ""
                            : ""}
                    </EuiLink>
                ),
            },
            {
                name: `Kleur`,
                width: "8vw",
                truncateText: true,
                render: (object: TableItem) => <EuiBadge color={object.value.color}>{object.value.color}</EuiBadge>,
            },
            {
                name: `Icoon`,
                width: "8vw",
                truncateText: true,
                render: (object: TableItem) => <EuiText>{object.value.icon}</EuiText>,
            },
            {
                name: `Image 1`,
                width: "10vw",
                truncateText: true,
                render: (object: TableItem) => <ProductImage imageKey={object.value.image_1} width={150} height={50} />,
            },
            {
                name: `Image 2`,
                width: "10vw",
                truncateText: true,
                render: (object: TableItem) => <ProductImage imageKey={object.value.image_2} width={150} height={50} />,
            },
            {
                name: "",
                width: "4vw",
                truncateText: true,
                render: (object: TableItem) => (
                    <EuiLink
                        onClick={
                            object.index === currentSelectedItem.row
                                ? () => setCurrentSelectedItem({ row: -1, column: -1 })
                                : () => setCurrentSelectedItem({ row: object.index, column: -1 })
                        }
                    >
                        <ActionContainer
                            title={"Actions"}
                            renderButtonContent={(active) => {
                                const classes = ["dropdown-button-content", active ? "active" : ""].join(" ");
                                return (
                                    <span className={classes}>
                                        <i className={"fa fa-bars"} />
                                    </span>
                                );
                            }}
                            isOpen={object.index === currentSelectedItem.row && currentSelectedItem.column === -1}
                            renderContent={() => (
                                <DropDownActionsWaitDelete
                                    type={type}
                                    object={object}
                                    options={[
                                        {
                                            label: "Bewerk foto's",
                                            euiIcon: "image",
                                            action: () => history.push(`/view/categories/${object.value.id}`),
                                        },
                                    ]}
                                    deleteOption={{
                                        label: "Wis category",
                                        action: () => {
                                            setDeleteItem(object);
                                            setConfirmDialogOpen(true);
                                        },
                                    }}
                                />
                            )}
                        />
                    </EuiLink>
                ),
            }
        );
    }

    const sorting = {
        sort: {
            field: "name",
            direction: "desc" as const,
        },
    };

    const onQueryChange: EuiSearchBarProps["onChange"] = ({ query }) => {
        clearTimeout(debounceTimeoutId);
        clearTimeout(requestTimeoutId);

        debounceTimeoutId = setTimeout(() => {
            setIsLoading(true);

            requestTimeoutId = setTimeout(() => {
                const items = resourceItems.filter((tableItem: TableItem) => {
                    const normalizedName = `${tableItem.value.name}`.toLowerCase();
                    const normalizedQuery = query?.text.toLowerCase() || "";
                    return normalizedName.indexOf(normalizedQuery) !== -1;
                });

                setIsLoading(false);
                setFilteredItems(items);
            }, 0);
        }, 0);
    };

    const search: EuiSearchBarProps = {
        onChange: onQueryChange,
        box: {
            incremental: true,
        },
    };

    let modal;
    if (isModalVisible) {
        modal = (
            <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
                <EuiModalBody>
                    <CreateForm
                        formKey={`create_${type}_form`}
                        handleSubmit={handleSubmit}
                        goBack={false}
                        closeModal={closeModal}
                    />
                </EuiModalBody>
            </EuiModal>
        );
    }

    return (
        <EuiFlexItem>
            <EuiPanel hasBorder={true} hasShadow={true} style={{ marginTop: "20px" }}>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiTitle>
                            <h3>Manage {capitalizeFirstLetter(type)}s</h3>
                        </EuiTitle>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton fill iconType="plus" onClick={showModal}>
                            Add new {type}
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
                {filteredItems && (
                    <EuiInMemoryTable
                        tableCaption="Demo of EuiInMemoryTable"
                        items={filteredItems}
                        search={search}
                        columns={columns}
                        pagination={pagination}
                        sorting={sorting}
                        loading={isLoading}
                        onTableChange={(page) => {
                            setPagination({
                                pageSizeOptions: [],
                                pageIndex: page.page?.index,
                                pageSize: page.page?.size,
                            });
                        }}
                    />
                )}
                {modal}
            </EuiPanel>
            <ConfirmationDialog
                isOpen={confirmDialogOpen}
                cancel={() => setConfirmDialogOpen(false)}
                confirm={() => {
                    deleteResource(deleteItem);
                    setConfirmDialogOpen(false);
                }}
                question={"Weet je zeker dat je deze wilt wissen?"}
            />
            <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} side="left" />
        </EuiFlexItem>
    );
}
