import { css } from "@emotion/react";

import { COLORS } from "../../stylesheets/emotion/vars";
import fontBold from "../font/agrandir-narrow-bold.ttf";
import background from "../images/easy-going/easyGoingBackground.png";
import image from "../images/mississippi/mississippi-pattern.png";

const BORDER_RADIUS = 0.4;

export const easyGoingStyle = css`
    @font-face {
        font-family: "agrandir-bold";
        src: url("${fontBold}");
        font-weight: bold 1;
        font-style: normal;
    }

    body {
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .bannerAll {
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .bannerBack {
        width: 9%;
        margin-top: 0.5vw;
        position: absolute;
        margin-left: 52.6%;
    }

    .bannerFront {
        width: 6.9%;
        margin-left: 40.6%;
        margin-top: 0.65vw;
        position: absolute;
    }

    .bannerLogo {
        width: 4vw;
        position: relative;
        margin-top: 0.1vw;
        margin-left: 133.7%;
        z-index: 1000;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg) scale(0.5);
            opacity: 0.15;
        }
        5% {
            transform: rotate(180deg) scale(0.5);
            opacity: 0.3;
        }
        10% {
            transform: rotate(360deg) scale(0.5);
            opacity: 0.15;
        }
        100% {
            transform: rotate(360deg) scale(0.5);
            opacity: 0.15;
        }
    }

    @keyframes opacity {
        0% {
            opacity: 0.05;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 0.05;
        }
    }

    .menukaart {
        width: 100vw;
        height: 100vw;
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .menulinks {
        width: 45vw;
        height: 45vw;
        margin-left: 2.5vw;
        margin-top: 2vw;
        position: relative;
    }

    .menurechts {
        width: 45vw;
        height: 45vw;
        margin-left: 3.5vw;
        margin-top: 2vw;
        position: relative;
    }

    .backgroundShadow {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: ${BORDER_RADIUS}vw;
    }

    .info {
        display: flex;
        flex-direction: row-reverse;
    }

    .row {
        display: flex;
        padding-top: 0vw;
        background-color: ${COLORS.easyGoingColor};
    }

    .row:nth-child(even) {
        background-color: ${COLORS.easyGoingSecondaryColor};
    }

    .row:nth-child(odd) {
        background-color: ${COLORS.easyGoingColor};
        border-top-left-radius: ${BORDER_RADIUS}vw;
        border-top-right-radius: ${BORDER_RADIUS}vw;
    }

    .row:nth-last-child(1) {
        border-bottom-left-radius: ${BORDER_RADIUS}vw;
        border-bottom-right-radius: ${BORDER_RADIUS}vw;
    }

    .row:first-child {
        border-top-left-radius: ${BORDER_RADIUS}vw;
        border-top-right-radius: ${BORDER_RADIUS}vw;
    }

    .rowContent {
        padding: 0.18vw 0;
        display: flex;
    }

    .productkind {
        color: white;
        font-size: 0.9vw;
        margin-top: 0.3vw;
        width: 3vw;
        margin-left: 2vw;
    }

    .banner {
        width: 100%;
        margin-left: 8vw;
        margin-top: 1vw;
        position: relative;
    }

    .pagebackground {
        background-image: url("${background}");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
    }

    .pattern {
        //background-image: url("${image}");
        background-size: 100% 70%;
        position: absolute;
        margin-left: -3%;
        top: -8%;
        height: 300vw;
        margin-top: -39%;
        width: 20%;
        opacity: 0.1;
        animation: opacity 10s 1s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
    }

    .redbg {
        position: absolute;
    }

    @font-face {
        font-family: "cordia";
        font-weight: normal;
        font-style: normal;
    }

    .logo {
        display: none;
        position: absolute;
        margin-left: 20%;
        margin-top: -6.5%;
        width: 60%;
        opacity: 0.2;
        //animation: rotation 25s 20s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
    }

    .marginTopTitle {
        margin-top: -4vw;
    }

    .title {
        margin-left: 1.5vw;
        font-size: 1.6vw;
        font-weight: 600;
        margin-top: 1.2vw;
        margin-bottom: 0.1vw;
        position: relative;
        width: 20.3vw;
        color: white;
        text-shadow: 1px 1px #000000;
    }

    .titleNotAtTop {
        margin-left: 1.5vw;
        font-size: 1.6vw;
        font-weight: 600;
        margin-top: 0.4vw;
        margin-bottom: 0.1vw;
        position: relative;
        width: 20vw;
        color: white;
        text-shadow: 1px 1px #000000;
    }

    .particles {
        position: absolute;
        margin-left: 50vw;
        width: 300px;
    }

    .btn {
        position: absolute;
    }

    .kindBadge {
        margin: auto;
        padding: 0 0.75vw;
    }

    .nameStar {
        display: flex;
    }

    .newBadge {
    }

    .nameprice {
        font-size: 1.1vw;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
    }

    .description {
        font-size: 0.75vw;
        color: ${COLORS.descriptionColor};
        margin: 0 auto;
        display: none;
    }

    .kind {
        font-size: 0.5vw;
        position: relative;
        margin-left: -2vw;
        margin-top: 0.5vw;
        width: 5vw;
        color: white;
    }

    .priceContainer {
        position: relative;
        left: 1.4vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .price {
        font-size: 1.05vw;
        width: 4.45vw;
        color: white;
    }

    .priceHeader {
        position: relative;
        left: 1.35vw;
        margin-left: -0.55vw;
        font-size: 1vw;
        margin-top: -1.5vw;
        width: 4.8vw;
        color: white;
    }

    .filler {
        flex-grow: 1;
    }

    .svg-inline--fa {
        margin-left: 20px;
        margin-top: 8px;
        font-size: 30px;
    }
`;
