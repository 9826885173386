import { css } from "@emotion/react";

import { COLORS } from "../stylesheets/emotion/vars";

export const mississippiLegend = css`
    .legend {
        position: absolute;
        bottom: -5vw;
        width: 45vw;
        .container {
            width: 100%;
            .content {
                display: flex;
                background-color: ${COLORS.pricelistTableContainer};
                border-radius: 1vw;
                flex-grow: 1;
                justify-content: center;
                padding: 0.75vw;
                .legendBadge {
                    height: 1.4vw;
                }
                .legendRow {
                    margin-top: -0vw;
                    display: flex;
                    flex-direction: row;
                    padding-right: 3vw;
                    .newIcon {
                        position: relative;
                        top: -5px;
                        margin-bottom: 2px;
                    }
                    .label {
                        padding-left: 0.9vw;
                        font-size: 1.2vw;
                        color: ${COLORS.legendTextColor};
                    }
                }
            }
        }
    }
`;

export const maryJaneLegend = css`
    .legend {
        position: absolute;
        bottom: -2.5vw;
        width: 45vw;
        .container {
            width: 100%;

            .content {
                display: flex;
                border-radius: 1vw;
                flex-grow: 1;
                justify-content: center;
                padding: 0.75vw;
                .legendBadge {
                    height: 1.3vw;
                }
                .legendRow {
                    margin-top: 0vw;
                    display: flex;
                    flex-direction: row;
                    padding-right: 3vw;
                    .newIcon {
                        position: relative;
                        top: -0.25vw;
                    }
                    .label {
                        padding-left: 0.5vw;
                        font-size: 1.2vw;
                        font-family: "anton-black", "sans-serif";
                        color: ${COLORS.maryJaneColor};
                    }
                }
            }
            .content::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: ${COLORS.maryJaneRowColor};
                opacity: 0.72;
                z-index: -1;
            }
        }
    }
`;

export const easyGoingLegend = css`
    .legend {
        position: absolute;
        bottom: -5.3vw;
        width: 45vw;
        .container {
            width: 100%;
            .content {
                display: flex;
                background-color: ${COLORS.easyGoingColor};
                border-radius: 1vw;
                flex-grow: 1;
                justify-content: center;
                padding: 0.75vw;
                .legendBadge {
                    height: 1.4vw;
                    margin-bottom: 0.75vw;
                }
                .legendRow {
                    margin-top: 0vw;
                    display: flex;
                    flex-direction: row;
                    padding-right: 3vw;
                    .newIcon {
                        position: relative;
                        top: -0.2vw;
                    }
                    .label {
                        padding-left: 0.9vw;
                        font-size: 1.2vw;
                        color: ${COLORS.legendTextColor};
                    }
                }
            }
        }
    }
`;

export const deSteegLegend = css`
    .legend {
        position: absolute;
        bottom: -3vw;
        width: 45vw;
        .container {
            width: 100%;
            .content {
                display: flex;
                background-color: rgba(31, 72, 31, 0.5);
                box-shadow: inset 0 0 128px rgba(0, 0, 0, 0.6);
                border-radius: 15px;
                flex-grow: 1;
                justify-content: center;
                padding: 1.7vw;
                .legendRow {
                    margin-top: -1vh;
                    display: flex;
                    flex-direction: row;
                    padding-right: 3vw;
                    .newIcon {
                        position: relative;
                        top: -5px;
                        margin-bottom: 2px;
                    }
                    .label {
                        padding-left: 0.9vw;
                        font-size: 1.2vw;
                        color: white;
                    }
                }
            }
        }
    }
`;

export const roundAboutLegend = css`
    .legend {
        width: 45vw;
        .container {
            width: 100%;
            .content {
                background-color: ${COLORS.pricelistTableContainer};
                border-radius: 1vw;
                padding: 0.75vw;
                .legendBadge {
                    height: 1.4vw;
                }
                .legendRow {
                    .newIcon {
                        position: relative;
                        top: -5px;
                        margin-bottom: 2px;
                    }
                    .label {
                        padding-left: 2vw;
                        font-size: 1.2vw;
                        color: ${COLORS.legendTextColor};
                    }
                }
            }
        }
    }
`;
