/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import "pages/OrdersPage.scss";

import { EuiButtonIcon, EuiPage, EuiPageSection } from "@elastic/eui";
import DropDownActions from "components/DropDownActions";
import Explain from "components/Explain";
import ConfirmationDialog from "components/modals/ConfirmationDialog";
import { OrdersTable, initialOrdersFilterAndSort, initialOrdersTableSettings } from "components/tables/OrderTable";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { stop } from "utils/Utils";
import { orderActionOptions } from "validations/Orders";

import client from "../api/Client";
import API_URL, { WEBSOCKET_URL } from "../Constants";
import { ShopContext } from "../utils/Shop";
import { Order } from "../utils/types";

interface OrdersPageProps {}

const OrdersPage: React.FC<OrdersPageProps> = () => {
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationDialogAction, setConfirmationDialogAction] = useState<((e: React.MouseEvent) => void) | null>(
        null
    );
    const [confirmationDialogQuestion, setConfirmationDialogQuestion] = useState("");
    const [showExplanation, setShowExplanation] = useState(false);
    // const [lastCompletedOrder, setLastCompletedOrder] = useState<undefined | string>(undefined);
    // const [lastPendingOrder, setLastPendingOrder] = useState<undefined | string>(undefined);
    const { shopId } = useContext(ShopContext);

    const refreshData = (orderTable: string) => {
        window.location.href = "/orders";
    };

    const checkLastCompletedOrder = useCallback(() => {
        client.get(`${API_URL}/v1/shops/last-completed-order/${shopId}`).then((result) => {
            const lastCompletedOrder = String(result.data.last_completed_order);
            const storedLastCompletedOrder = String(localStorage.getItem("lastCompletedOrder"));
            if (storedLastCompletedOrder !== lastCompletedOrder) {
                localStorage.setItem("lastCompletedOrder", lastCompletedOrder);
                refreshData("complete");
            }
        });
    }, [shopId]);

    const checkLastPendingOrder = useCallback(() => {
        client.get(`${API_URL}/v1/shops/last-pending-order/${shopId}`).then((result) => {
            const lastPendingOrder = String(result.data.last_pending_order);
            const storedLastPendingOrder = String(localStorage.getItem("lastPendingOrder"));
            console.log("stored: ", storedLastPendingOrder);
            console.log("last: ", lastPendingOrder);
            if (storedLastPendingOrder !== lastPendingOrder) {
                localStorage.setItem("lastPendingOrder", lastPendingOrder);
                refreshData("pending");
            }
        });
    }, [shopId]);

    const connectWebSocket = useCallback(
        (connectionType: string, checkFunction: Function, refreshFunction: Function) => {
            const socket = new WebSocket(`${WEBSOCKET_URL}?shopId=${shopId}&connectionType=${connectionType}`);
            socket.onopen = () => {
                console.log("Connection with websocket server established.", socket);
            };
            socket.onerror = () => {
                console.log("Error connecting to websocket server, falling back to polling.");
                setInterval(checkFunction(), 5000);
            };
            socket.onmessage = (event) => {
                console.log(`[message] Data received from server: ${event.data}`);
                console.log(JSON.parse(event.data));
                if (JSON.parse(event.data) === `invalidate_${connectionType}_cache`) {
                    refreshFunction();
                }
            };
            socket.onclose = () => {
                console.log("Re-spawning websocket");
                connectWebSocket(connectionType, checkFunction, refreshFunction);
            };
        },
        [shopId]
    );

    useEffect(() => {
        client.get(`${API_URL}/v1/shops/last-completed-order/${shopId}`).then((result) => {
            const lastCompletedOrder = result.data.last_completed_order;
            // setLastCompletedOrder(lastCompletedOrder);
            localStorage.setItem("lastCompletedOrder", lastCompletedOrder);
        });

        client.get(`${API_URL}/v1/shops/last-pending-order/${shopId}`).then((result) => {
            const lastPendingOrder = result.data.last_pending_order;
            // setLastPendingOrder(lastPendingOrder);
            localStorage.setItem("lastPendingOrder", lastPendingOrder);
        });

        connectWebSocket("completed_orders", checkLastCompletedOrder, refreshData);
        connectWebSocket("pending_orders", checkLastPendingOrder, refreshData);
    }, [checkLastCompletedOrder, checkLastPendingOrder, connectWebSocket, shopId]);

    const cancelConfirmation = () => setConfirmationDialogOpen(false);

    const confirmation = (question: string, action: (e: React.MouseEvent) => void) => {
        setConfirmationDialogOpen(true);
        setConfirmationDialogQuestion(question);
        setConfirmationDialogAction((e: React.MouseEvent) => {
            cancelConfirmation();
            action(e);
        });
    };

    const handleCompleteOrder = (order: Order) => (e: React.MouseEvent) => {
        stop(e);
        const payload = {
            status: "complete",
        };
        confirmation("Weet je zeker dat je de order handmatig op compleet wil zetten?", () =>
            client.patch(`/v1/orders/${order.id}`, payload).then(() => {
                console.log("Order forcefully completed");
                window.location.href = "/orders";
            })
        );
    };

    const handleCancelOrder = (order: Order) => (e: React.MouseEvent) => {
        stop(e);
        const payload = {
            status: "complete",
        };
        confirmation("Weet je zeker dat je de order wil annuleren?", () =>
            client.patch(`/v1/orders/${order.id}`, payload).then(() => {
                console.log("Order forcefully completed");
                window.location.href = "/orders";
            })
        );
    };

    const handleDeleteOrder = (order: Order) => () => {
        console.log("Delete called");
    };

    const renderActions = (order: Order) => {
        const options = orderActionOptions(
            order,
            handleCompleteOrder(order),
            handleCancelOrder(order),
            handleDeleteOrder(order)
        );
        return <DropDownActions options={options} i18nPrefix="OrdersPage.actions" />;
    };

    const renderExplain = () => {
        return <EuiButtonIcon iconType="questionInCircle" onClick={() => setShowExplanation(true)} />;
    };

    const activeSettings = initialOrdersTableSettings(
        "table.orders-page.bezig",
        initialOrdersFilterAndSort(shopId, "pending"),
        ["id", "shop_id", "status", "completed_at", "completed_by"],
        { showSettings: false, pageSize: 10, refresh: false }
    );
    const completedSettings = initialOrdersTableSettings(
        "table.orders-page.compleet",
        initialOrdersFilterAndSort(shopId, "c"),
        ["id", "shop_id", "completed_by"],
        { showSettings: false, pageSize: 5, refresh: false }
    );

    return (
        <EuiPage>
            <EuiPageSection>
                <Explain close={() => setShowExplanation(false)} isVisible={showExplanation} title="OrdersPage Help">
                    <h1>OrdersPage</h1>
                    <p>In de bovenste zie je alle lopende orders. De onderste tabel laat afgehandelde orders zien.</p>
                    <h2>Uitleg</h2>
                    <h2>Uitleg</h2>
                    <p>
                        De tabellen slaan hun instellingen op in je browser. Wil je dat resetten, gebruik dan het
                        potlood icoontje en druk op "Reset".
                    </p>
                </Explain>
                <ConfirmationDialog
                    isOpen={confirmationDialogOpen}
                    cancel={cancelConfirmation}
                    confirm={confirmationDialogAction || (() => {})}
                    question={confirmationDialogQuestion}
                />
                <div className="actions">{renderExplain()}</div>
                <OrdersTable key={"pending"} initialTableSettings={activeSettings} renderActions={renderActions} />
                <OrdersTable key={"complete"} initialTableSettings={completedSettings} renderActions={renderActions} />
            </EuiPageSection>
        </EuiPage>
    );
};

export default OrdersPage;
