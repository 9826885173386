/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import "./PricelistTable.scss";

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiGlobalToastList, EuiPanel, EuiSpacer, EuiText } from "@elastic/eui";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    Column,
    Hooks,
    LocalTableSettings,
    Row,
    RowPropGetter,
    SessionTableSettings,
    TableSettings,
    TableState,
    useExpanded,
    useFilters,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";

import GlobalToastContext from "../../contextProviders/GlobalToastProvider";
import { ShopContext } from "../../utils/Shop";
import { ShopId } from "../../utils/types";
import { capitalizeFirstLetter, removePlural } from "../../utils/Utils";
import { ShopGroupSelector } from "../ShopGroupSelector";
import MiniPaginator from "./MiniPaginator";
import Paginator from "./Paginator";
import Preferences from "./Preferences";
import { TableRenderer } from "./TableRenderer";
import { SHOP_GROUP_GLOBAL_STRING, tableSettingsReducer } from "./tableSettingsUtils";
import useFilterableDataFetcher from "./useFilterableDataFetcher";
import useInterval from "./useInterval";

export function isLocalTableSettings<T>(
    settings: LocalTableSettings<T> | SessionTableSettings
): settings is LocalTableSettings<T> {
    return (settings as LocalTableSettings<T>).hiddenColumns !== undefined;
}

interface IImproviserTableProps<T extends object> {
    columns: Column<T>[];
    initialState: TableState<T>;
    persistSettings: (state: LocalTableSettings<T> | SessionTableSettings) => void;
    endpoint: string;
    initialTableSettings: TableSettings<T>;
    extraRowPropGetter: RowPropGetter<T>;
    renderSubComponent: ({ row }: { row: Row<T> }) => JSX.Element;
    excludeInFilter: string[];
    advancedSearch: boolean;
}

export function PricelistTable<T extends object>({
    columns,
    initialState,
    persistSettings,
    endpoint,
    initialTableSettings,
    extraRowPropGetter,
    renderSubComponent,
    excludeInFilter,
}: IImproviserTableProps<T>) {
    const { shopGroups } = useContext(ShopContext);
    const [currentShopGroupId, setCurrentShopGroupId] = useState<ShopId>(shopGroups[0]?.id ?? SHOP_GROUP_GLOBAL_STRING);
    const endointWithShopGroup =
        currentShopGroupId !== SHOP_GROUP_GLOBAL_STRING ? `${endpoint}?shop_group_id=${currentShopGroupId}` : endpoint;
    const [data, pageCount, fetchData] = useFilterableDataFetcher<T>(endointWithShopGroup);

    const handleShopGroupChange = (value: string) => {
        setCurrentShopGroupId(value);
        gotoPage(0);
    };

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        allColumns,
        visibleColumns,
        state,
        dispatch,
    } = useTable<T>(
        {
            columns,
            data,
            pageCount,
            manualFilters: true,
            manualPagination: true,
            manualSortBy: true,
            autoResetFilters: false,
            autoResetSortBy: false,
            autoResetExpanded: true,
            debug: true,
            // @ts-ignore Compiler expects id and value even on ActionTypes without them
            stateReducer: tableSettingsReducer,
            initialState: initialState,
        },
        (hooks: Hooks<T>) => {
            hooks.getRowProps.push(extraRowPropGetter);
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );
    const {
        name,
        sortBy,
        filterBy,
        showSettings,
        showPaginator,
        refresh,
        delay,
        hiddenColumns,
        pageIndex,
        pageSize,
        minimized,
    } = state;

    const preferencesProps = {
        state,
        allColumns,
        dispatch,
        initialTableSettings,
        excludeInFilter,
    };

    const TableRendererProps = {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        visibleColumns,
        renderSubComponent,
    };
    const paginatorProps = {
        canNextPage,
        canPreviousPage,
        gotoPage,
        nextPage,
        pageCount,
        pageIndex,
        pageOptions,
        pageSize,
        previousPage,
        setPageSize,
    };

    // Update localStorage
    useEffect(() => {
        persistSettings({
            showSettings,
            showPaginator,
            hiddenColumns,
            delay,
            filterBy,
            sortBy,
        });
    }, [persistSettings, showSettings, showPaginator, hiddenColumns, delay, filterBy, sortBy]);

    // Update session storage and URL
    useEffect(() => {
        persistSettings({
            pageSize,
            pageIndex,
            filterBy,
            sortBy,
            refresh,
            minimized,
        });
    }, [persistSettings, pageSize, pageIndex, filterBy, sortBy, refresh, minimized]);

    // fetch new data whenever page index, size sort or filter changes
    useEffect(() => {
        fetchData(dispatch, pageIndex, pageSize, sortBy, filterBy);
    }, [fetchData, dispatch, pageIndex, pageSize, sortBy, filterBy]);

    /*
     * poll for updates at an interval. because this is a hook the interval will be
     * removed when the table is unmounted
     */
    const autoRefreshDelay = refresh ? delay : -1;
    useInterval(() => {
        fetchData(dispatch, pageIndex, pageSize, sortBy, filterBy);
    }, autoRefreshDelay);

    const history = useHistory();
    console.log("endpooint,", endpoint);
    const type =
        endpoint === "kinds"
            ? "soorten"
            : endpoint === "products"
            ? "horeca"
            : endpoint === "categories"
            ? "categories"
            : "undefined";

    let { toasts, removeToast } = useContext(GlobalToastContext);

    return (
        <EuiPanel id={name}>
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <Preferences<T> {...preferencesProps} />
                </EuiFlexItem>
                {!minimized && (
                    <EuiFlexItem grow={false} component="span">
                        <MiniPaginator {...paginatorProps} />
                    </EuiFlexItem>
                )}
                <EuiFlexItem grow={false}>
                    <EuiSpacer size={"s"} />
                    <EuiFlexGroup>
                        <EuiText style={{ marginTop: 10, marginRight: 10, marginLeft: 10 }} size="s">
                            <b>Toon {type} van:</b>
                        </EuiText>
                        <ShopGroupSelector
                            currentShopGroupId={currentShopGroupId}
                            handleShopGroupChange={handleShopGroupChange}
                        />
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ paddingBottom: 15 }}>
                    <EuiButton
                        size="s"
                        iconType="plus"
                        onClick={() => {
                            history.push(`/manage/${type}/new`);
                        }}
                    >
                        {capitalizeFirstLetter(removePlural(type))}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            {/*{!minimized && advancedSearch && <AdvancedSearch {...advancedSearchProps} />}*/}
            {!minimized && <TableRenderer {...TableRendererProps} />}
            {!minimized && data.length === 0 && <div className={"no-results"}>no_results</div>}
            {!minimized && showPaginator && <Paginator {...paginatorProps} />}
            <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} side="left" />
        </EuiPanel>
    );
}
