import React from "react";

import { getPriceQuantityDescription } from "../utils/Utils";

export const PriceTableHeader = (props) => {
    const { title, cols, showColumsHeader, topHeader } = props;

    return (
        <div>
            <div>
                <h2 className={topHeader ? "title" : "titleNotAtTop"}>{title}</h2>
            </div>
            <div className="info">
                {showColumsHeader &&
                    cols
                        .slice(0)
                        .reverse()
                        .map((col) => <p className="priceHeader">{getPriceQuantityDescription(col)}</p>)}
            </div>
        </div>
    );
};
