import { EuiPage } from "@elastic/eui";
import { Component } from "react";
import React from "react";

import ResetForm from "../components/ResetForm";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            loading: false,
            email: "",
        };
    }

    componentDidMount = () => {
        console.log("Reset password page mounted");
    };

    render() {
        const token = new URLSearchParams(this.props.location.search).get("token");

        return (
            <EuiPage>
                <ResetForm token={token} />
            </EuiPage>
        );
    }
}

export default ResetPassword;

ResetPassword.propTypes = {};

ResetPassword.defaultProps = {};
