// eslint-disable-next-line no-prototype-builtins
import PropTypes from "prop-types";
import React from "react";
import Currency from "react-currency-formatter";

export default function PriceTable(props) {
    const { price, padding } = props;
    let space = "3px";
    if (padding) {
        space = `${padding}px`;
    }
    const headers = (
        <tr>
            {price.half && <th style={{ paddingLeft: space }}>0.5 gram</th>}
            {price.one && <th style={{ paddingLeft: space }}>1 gram</th>}
            {price.two_five && <th style={{ paddingLeft: space }}>2.5 gram</th>}
            {price.five && <th style={{ paddingLeft: space }}>5 gram</th>}
            {price.joint && <th style={{ paddingLeft: space }}>1 joint</th>}
            {price.piece && <th style={{ paddingLeft: space }}>1 item</th>}
        </tr>
    );

    const prices = (
        <tr>
            {price.half && (
                <td style={{ paddingLeft: space }}>
                    <Currency quantity={price.half} currency="EUR" locale="nl_NL" />
                </td>
            )}
            {price.one && (
                <td style={{ paddingLeft: space }}>
                    <Currency quantity={price.one} currency="EUR" locale="nl_NL" />
                </td>
            )}
            {price.two_five && (
                <td style={{ paddingLeft: space }}>
                    <Currency quantity={price.two_five} currency="EUR" locale="nl_NL" />
                </td>
            )}
            {price.five && (
                <td style={{ paddingLeft: space }}>
                    <Currency quantity={price.five} currency="EUR" locale="nl_NL" />
                </td>
            )}
            {price.joint && (
                <td style={{ paddingLeft: space }}>
                    <Currency quantity={price.joint} currency="EUR" locale="nl_NL" />
                </td>
            )}
            {price.piece && (
                <td style={{ paddingLeft: space }}>
                    <Currency quantity={price.piece} currency="EUR" locale="nl_NL" />
                </td>
            )}
        </tr>
    );

    return (
        <table>
            {headers}
            {prices}
        </table>
    );
}

PriceTable.propTypes = {
    price: PropTypes.object.isRequired,
    padding: PropTypes.number,
};
