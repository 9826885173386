import "./ProductInfo.scss";

import { EuiDescriptionList, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiTitle } from "@elastic/eui";
import React, { useState } from "react";
import { useQuery } from "react-query";

import { get_all_flavors, get_all_strains, get_all_tags, get_product_by_id_generic } from "../../api/Client";
import {
    FlavorWithIndex,
    KindRelation,
    MutationType,
    NameWithDescription,
    StrainWithIndex,
    TagWithIndex,
} from "../../utils/types";
import ProductInfoDropdownEditor from "../editors/ProductInfoDropdownEditor";
import { EditKindFlags } from "./EditKindFlags";
import { EditKindIcons } from "./EditKindIcons";
import { EditProductInfo } from "./EditProductInfo";

export const ProductInfo = React.memo(({ type, updateProduct, updateRelation, urlType, id }: any) => {
    const productInfo: NameWithDescription[] = [];
    const strainInfo: StrainWithIndex[] = [];
    const flavorInfo: FlavorWithIndex[] = [];
    const tagInfo: TagWithIndex[] = [];
    const product_keys = [
        "name",
        "description_nl",
        "description_en",
        "short_description_nl",
        "short_description_en",
        "created_at",
        "modified_at",
    ];

    const [enableFlavorsQuery, setEnableFlavorsQuery] = useState(false);
    const [enableStrainsQuery, setEnableStrainsQuery] = useState(false);
    const [enableTagsQuery, setEnableTagsQuery] = useState(false);

    // Combobox options [{ label: string; color: string }] array to display items
    const [flavorOptions, setFlavorOptions] = useState<[{ label: string; color: string }]>([
        { label: "Loading", color: "" },
    ]);
    const [strainOptions, setStrainOptions] = useState<[{ label: string }]>([{ label: "Loading" }]);
    const [tagOptions, setTagOptions] = useState<[{ label: string }]>([{ label: "Loading" }]);

    // Tracks the editing state of each editor
    const [editingProductInfo, setEditingProductInfo] = useState<boolean[]>([]);
    const [editingFlavorInfo, setEditingFlavorInfo] = useState<boolean[]>([]);
    const [editingStrainInfo, setEditingStrainInfo] = useState<boolean[]>([]);
    const [editingTagInfo, setEditingTagInfo] = useState<boolean[]>([]);

    // Doing useQuery again instead of passing data as a prop, so the component re-renders again when cache gets invalidated
    const { data } = useQuery([urlType, id], () => get_product_by_id_generic(urlType, id).then(), {
        refetchOnWindowFocus: false,
        onSuccess: (successData) => {
            if (type === "cannabis") {
                setEditingFlavorInfo(successData.flavors.map(() => false).concat(false));
                setEditingStrainInfo(successData.strains.map(() => false).concat(false));
                setEditingTagInfo(successData.tags.map(() => false).concat(false));
            }
            setEditingProductInfo(product_keys.map(() => false));
        },
    });

    //Fetch all existing flavors and put them as options of the combobox
    const { data: flavors, isLoading: flavorsIsLoading } = useQuery(["flavors"], () => get_all_flavors().then(), {
        enabled: enableFlavorsQuery,
        onSuccess: (successData) => {
            setFlavorOptions(
                successData.map((object: FlavorWithIndex) => ({ label: object.name, color: object.color }))
            );
        },
        refetchOnWindowFocus: false,
    });

    //Fetch all existing strains and put them as options of the combobox
    const { data: strains, isLoading: strainsIsLoading } = useQuery(["strains"], () => get_all_strains().then(), {
        enabled: enableStrainsQuery,
        onSuccess: (successData) => {
            setStrainOptions(successData.map((object: StrainWithIndex) => ({ label: object.name })));
        },
        refetchOnWindowFocus: false,
    });

    //Fetch all existing tags and put them as options of the combobox
    const { data: tags, isLoading: tagsIsLoading } = useQuery(["tags"], () => get_all_tags().then(), {
        enabled: enableTagsQuery,
        onSuccess: (successData) => {
            setTagOptions(successData.map((object: TagWithIndex) => ({ label: object.name })));
        },
        refetchOnWindowFocus: false,
    });

    if (data) {
        for (const k of product_keys) {
            productInfo.push({ title: k, description: data[k] });
        }
        if (data.strains) {
            let c = 0;
            for (const f of data.strains) {
                strainInfo.push({ index: c, name: f.name, id: f.id });
                c++;
            }
        }
        if (data.tags) {
            let c = 0;
            for (const f of data.tags) {
                tagInfo.push({ index: c, name: f.name, id: f.id, amount: f.amount });
                c++;
            }
        }
        if (data.flavors) {
            let c = 0;
            for (const f of data.flavors) {
                flavorInfo.push({ index: c, name: f.name, color: f.color, id: f.id });
                c++;
            }
        }
    }

    const save = (value: string, key?: string) => {
        let newProduct = data;
        if (key) {
            newProduct[key] = value;
        }
        updateProduct(newProduct, MutationType.Edited, "Product info");
    };

    const saveRelation = (relation: KindRelation, mutationType: MutationType) => {
        relation.kind_id = data.id;
        updateRelation(relation, mutationType);
    };

    // Filters the combobox options so existing ones don't show
    const filterOptions = (initialOption: any, type: string) => {
        if (type === "flavor") {
            return flavorOptions.filter(
                (item: { label: string; color: string }) =>
                    !flavorInfo.find((el) => el.name === item.label && item.label !== initialOption.name)
            );
        } else if (type === "strain") {
            return strainOptions.filter(
                (item: { label: string }) =>
                    !strainInfo.find((el) => el.name === item.label && item.label !== initialOption.name)
            );
        } else if (type === "tag") {
            return tagOptions.filter(
                (item: { label: string }) =>
                    !tagInfo.find((el) => el.name === item.label && item.label !== initialOption.name)
            );
        }
    };

    // Sets all editors editing states
    const changeEditStates = (value: boolean, index: number, type: string) => {
        let prInfoEditState = [...editingProductInfo].map(() => false);
        let strainsEditState = [...editingStrainInfo].map(() => false);
        let flavorsEditState = [...editingFlavorInfo].map(() => false);
        let tagsEditState = [...editingTagInfo].map(() => false);

        type === "info" && prInfoEditState.splice(index, 1, value);
        type === "strain" && strainsEditState.splice(index, 1, value);
        type === "flavor" && flavorsEditState.splice(index, 1, value);
        type === "tag" && tagsEditState.splice(index, 1, value);

        setEditingProductInfo(prInfoEditState);
        setEditingStrainInfo(strainsEditState);
        setEditingFlavorInfo(flavorsEditState);
        setEditingTagInfo(tagsEditState);
    };

    const renderFlavorInfo = (flavorInfo: FlavorWithIndex[]) => {
        const maxRelations = 5;
        let flavorInfoRendered = [];
        const type = "flavor";
        for (let item of flavorInfo) {
            flavorInfoRendered.push(
                <EuiPanel key={item.index} color="transparent" paddingSize="xs" className="panelInfo">
                    <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={true}>
                            <ProductInfoDropdownEditor
                                type={type}
                                allData={flavors}
                                // @ts-ignore
                                options={filterOptions(item, type)}
                                name={"Flavor " + (item.index + 1)}
                                initialValue={item}
                                // @ts-ignore
                                onSave={saveRelation}
                                onEdit={() => {
                                    setEnableFlavorsQuery(true);
                                }}
                                // @ts-ignore
                                isLoading={flavorsIsLoading}
                                hasColors={true}
                                setEditing={(value: boolean) => {
                                    changeEditStates(value, item.index, type);
                                }}
                                editing={editingFlavorInfo[item.index]}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            );
        }
        flavorInfoRendered.push(
            <EuiPanel color="transparent" paddingSize="xs">
                <ProductInfoDropdownEditor
                    type={type}
                    allData={flavors}
                    // @ts-ignore
                    options={filterOptions("", type)}
                    name={"Add new flavor"}
                    // @ts-ignore
                    onSave={saveRelation}
                    onEdit={() => {
                        setEnableFlavorsQuery(true);
                    }}
                    setEditing={(value: boolean) => {
                        changeEditStates(value, editingFlavorInfo.length - 1, type);
                    }}
                    editing={editingFlavorInfo[editingFlavorInfo.length - 1]}
                    isLoading={flavorsIsLoading}
                    hasColors={true}
                    isAddNew={true}
                    readonly={flavorInfo.length >= maxRelations}
                />
            </EuiPanel>
        );

        return flavorInfoRendered;
    };

    const renderStrainInfo = (strainInfo: StrainWithIndex[]) => {
        const maxRelations = 3;
        let strainInfoRendered = [];
        const type = "strain";
        for (let item of strainInfo) {
            strainInfoRendered.push(
                <EuiPanel key={item.index} color="transparent" paddingSize="xs" className="panelInfo">
                    <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={9}>
                            <ProductInfoDropdownEditor
                                type={type}
                                allData={strains}
                                // @ts-ignore
                                options={filterOptions(item, type)}
                                name={"Strain " + (item.index + 1)}
                                initialValue={item}
                                // @ts-ignore
                                onSave={saveRelation}
                                onEdit={() => {
                                    setEnableStrainsQuery(true);
                                }}
                                isLoading={strainsIsLoading}
                                setEditing={(value: boolean) => {
                                    changeEditStates(value, item.index, type);
                                }}
                                editing={editingStrainInfo[item.index]}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            );
        }
        strainInfoRendered.push(
            <EuiPanel color="transparent" paddingSize="xs">
                <ProductInfoDropdownEditor
                    type={type}
                    allData={strains}
                    // @ts-ignore
                    options={filterOptions("", type)}
                    name={"Add new strain"}
                    // @ts-ignore
                    onSave={saveRelation}
                    onEdit={() => {
                        setEnableStrainsQuery(true);
                    }}
                    isLoading={strainsIsLoading}
                    isAddNew={true}
                    readonly={strainInfo.length >= maxRelations}
                    setEditing={(value: boolean) => {
                        changeEditStates(value, editingStrainInfo.length - 1, type);
                    }}
                    editing={editingStrainInfo[editingStrainInfo.length - 1]}
                    maxRelations={maxRelations}
                />
            </EuiPanel>
        );

        return strainInfoRendered;
    };

    const renderTagInfo = (tagInfo: TagWithIndex[]) => {
        const maxRelations = 5;
        let tagInfoRendered = [];
        const type = "tag";
        for (let item of tagInfo) {
            tagInfoRendered.push(
                <EuiPanel key={item.index} color="transparent" paddingSize="xs" className="panelInfo">
                    <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={9}>
                            <ProductInfoDropdownEditor
                                type={type}
                                allData={tags}
                                // @ts-ignore
                                options={filterOptions(item, type)}
                                name={"Tag " + (item.index + 1) + " / Amount"}
                                initialValue={item}
                                // @ts-ignore
                                onSave={saveRelation}
                                onEdit={() => {
                                    setEnableTagsQuery(true);
                                }}
                                isLoading={tagsIsLoading}
                                setEditing={(value: boolean) => {
                                    changeEditStates(value, item.index, type);
                                }}
                                editing={editingTagInfo[item.index]}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            );
        }
        tagInfoRendered.push(
            <EuiPanel color="transparent" paddingSize="xs">
                <ProductInfoDropdownEditor
                    type={type}
                    allData={tags}
                    // @ts-ignore
                    options={filterOptions("", type)}
                    name={"Add new tag"}
                    // @ts-ignore
                    onSave={saveRelation}
                    onEdit={() => {
                        setEnableTagsQuery(true);
                    }}
                    isLoading={tagsIsLoading}
                    isAddNew={true}
                    readonly={tagInfo.length >= maxRelations}
                    setEditing={(value: boolean) => {
                        changeEditStates(value, editingTagInfo.length - 1, type);
                    }}
                    editing={editingTagInfo[editingTagInfo.length - 1]}
                />
            </EuiPanel>
        );

        return tagInfoRendered;
    };

    return (
        <>
            <EuiTitle>
                <h1>Beheer {data ? data.name : ""}</h1>
            </EuiTitle>
            <EuiFlexGroup>
                <EuiFlexItem grow={type === "cannabis" ? 5 : 10}>
                    <EuiPanel color="transparent" hasBorder={false}>
                        <EuiTitle>
                            <h2>Product info</h2>
                        </EuiTitle>
                        <EuiDescriptionList>
                            <EditProductInfo
                                prInfo={productInfo}
                                save={save}
                                editingProductInfo={editingProductInfo}
                                changeEditStates={changeEditStates}
                            />
                        </EuiDescriptionList>
                    </EuiPanel>
                </EuiFlexItem>
                {type === "cannabis" && (
                    <EuiFlexItem grow={2}>
                        <EuiPanel color="transparent" hasBorder={false}>
                            <EuiTitle>
                                <h2>Strain info</h2>
                            </EuiTitle>
                            <EuiDescriptionList>{renderStrainInfo(strainInfo)}</EuiDescriptionList>
                        </EuiPanel>
                    </EuiFlexItem>
                )}
                {type === "cannabis" && (
                    <EuiFlexItem grow={2}>
                        <EuiPanel color="transparent" hasBorder={false}>
                            <EuiTitle>
                                <h2>Tag info</h2>
                            </EuiTitle>
                            <EuiDescriptionList>{renderTagInfo(tagInfo)} </EuiDescriptionList>
                        </EuiPanel>
                    </EuiFlexItem>
                )}
                {type === "cannabis" && (
                    <EuiFlexItem grow={2}>
                        <EuiPanel color="transparent" hasBorder={false}>
                            <EuiTitle>
                                <h2>Flavor info</h2>
                            </EuiTitle>
                            <EuiDescriptionList>{renderFlavorInfo(flavorInfo)} </EuiDescriptionList>
                        </EuiPanel>
                    </EuiFlexItem>
                )}
                {type === "cannabis" && (
                    <EuiFlexItem grow={2}>
                        <EuiPanel color="transparent" hasBorder={false}>
                            <EuiTitle>
                                <h2>Flags</h2>
                            </EuiTitle>
                            <EuiDescriptionList>
                                <EditKindFlags productId={id} />
                            </EuiDescriptionList>
                        </EuiPanel>
                        <EuiPanel color="transparent" hasBorder={false}>
                            <EuiTitle>
                                <h2>Extra Icons</h2>
                            </EuiTitle>
                            <EuiDescriptionList>
                                <EditKindIcons productId={id} />
                            </EuiDescriptionList>
                        </EuiPanel>
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
        </>
    );
});

ProductInfo.displayName = "ProductInfo";
export default ProductInfo;
