/*
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiGlobalToastList,
    EuiIcon,
    EuiPage,
    EuiPageSection,
    EuiPanel,
} from "@elastic/eui";
import Explain from "components/Explain";
import React, { useCallback, useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";

import client, { get_resource_by_id } from "../api/Client";
import EditPictures from "../components/ProductDetail/EditPictures";
import ShowPictures from "../components/ProductDetail/ShowPictures";
import GlobalToastContext from "../contextProviders/GlobalToastProvider";
import { CreateResource, MutationType, ResourceType } from "../utils/types";

export default function CategoryDetailPage() {
    const { id } = useParams();
    let urlType = "categories"; // Default value

    const { data, isLoading } = useQuery([urlType, id], () => get_resource_by_id(urlType, id).then(), {
        refetchOnWindowFocus: false,
        onError: (err) => {
            addErrorToast(err);
        },
    });
    const [showExplanation, setShowExplanation] = useState(false);
    const [pictureUploadVisible, setPictureUploadVisible] = useState(false);
    let history = useHistory();
    let queryClient = useQueryClient();
    let { toasts, addErrorToast, addToastHandler, removeToast } = useContext(GlobalToastContext);

    const update_product_mutation = useMutation((resource: CreateResource) => {
        return client.put(`/v1/${urlType}/${id}`, resource);
    });

    const updateResource = useCallback(
        (resource: CreateResource, updateType: MutationType, objectType?: string) => {
            update_product_mutation.mutate(resource, {
                onSuccess: () => {
                    queryClient.setQueryData([urlType, id], resource);
                    setPictureUploadVisible(false);
                    addToastHandler(updateType, objectType);
                },
                onError: (error: any) => {
                    queryClient.refetchQueries([urlType, id]);
                    addErrorToast(error);
                },
            });
        },
        [update_product_mutation, queryClient, urlType, id, addToastHandler, addErrorToast]
    );

    const handleBackButton = () => {
        history.goBack();
    };

    return (
        <EuiPage>
            <EuiPageSection>
                {data && (
                    <EuiPanel hasBorder={true} hasShadow={true} style={{ marginTop: "20px" }}>
                        <div className="actions">
                            <div onClick={() => setShowExplanation(true)}>
                                <EuiIcon type={"questionInCircle"} size={"xxl"} />
                            </div>
                        </div>
                        <Explain
                            close={() => setShowExplanation(false)}
                            isVisible={showExplanation}
                            title="Product details"
                        >
                            <p>
                                Op deze pagina kun je snel plaatjes van producten bewerken. Tevens kun je vanaf hier
                                snel navigeren naar plekken waar je alle product attributen kan aanpassen.
                            </p>
                        </Explain>
                        {!isLoading && (
                            <ShowPictures
                                data={data}
                                showDelete={pictureUploadVisible}
                                updateProduct={updateResource}
                            />
                        )}
                        {!pictureUploadVisible && (
                            <EuiFlexGroup>
                                <EuiFlexItem style={{ maxWidth: 100 }}>
                                    <EuiButton onClick={() => handleBackButton()}>
                                        <EuiIcon type="arrowLeft" /> Back
                                    </EuiButton>
                                </EuiFlexItem>

                                <EuiFlexItem style={{ maxWidth: 150 }}>
                                    <EuiButton fill onClick={() => setPictureUploadVisible(true)}>
                                        Bewerk foto's
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        )}
                        {!isLoading && pictureUploadVisible && (
                            <EditPictures
                                data={data}
                                id={id}
                                type={ResourceType.Category}
                                setPictureUploadVisible={setPictureUploadVisible}
                                updateProduct={updateResource}
                            />
                        )}
                    </EuiPanel>
                )}
                <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} side="left" />
            </EuiPageSection>
        </EuiPage>
    );
}
