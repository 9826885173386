import { EuiGlobalToastList } from "@elastic/eui";
import React, { useCallback, useContext } from "react";
import { useQueryClient } from "react-query";

import GlobalToastContext from "../../contextProviders/GlobalToastProvider";
import { countryFlags } from "../../utils/KindIcons";
import { KindForTable, MutationType } from "../../utils/types";
import InlineIconEditor from "../editors/InlineFlagEditor";
import { mapFlagsDataToSelectedOptions, mapKindIconsToOptions, useProductDetailMutations } from "./utils";

interface EditStrainInfoProps {
    productId: string;
}

export const EditKindFlags = ({ productId }: EditStrainInfoProps) => {
    const { update_product_mutation } = useProductDetailMutations("kinds");
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData(["kinds", productId]) as KindForTable;
    const { toasts, addErrorToast, addToastHandler, removeToast } = useContext(GlobalToastContext);
    const updateProduct = useCallback(
        (product: KindForTable, updateType: MutationType, objectType?: string) => {
            update_product_mutation.mutate(product, {
                onSuccess: () => {
                    queryClient.invalidateQueries(["kinds", product.id]);
                    queryClient.setQueryData(["kinds", product.id], product);
                    addToastHandler(updateType, objectType);
                },
                onError: (error: any) => {
                    queryClient.refetchQueries(["kinds", product.id]);
                    addErrorToast(error);
                },
            });
        },
        [addErrorToast, addToastHandler, queryClient, update_product_mutation]
    );
    const save = (newFlags: string[]) => {
        let newProduct = data;
        newProduct["flags"] = newFlags;
        updateProduct(newProduct, MutationType.Edited, "Product info");
    };

    const initialFlags = data?.flags ?? [];
    const options = mapKindIconsToOptions(Object.values(countryFlags));
    const initialOptions = mapFlagsDataToSelectedOptions(Object.values(countryFlags), initialFlags);

    return (
        <div style={{ padding: 4 }}>
            <InlineIconEditor
                iconType={"flag"}
                options={options}
                initialOptions={initialOptions}
                initialValue={initialFlags}
                readonly={false}
                onSave={save}
                isLoading={false}
            />
            <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} side="left" />
        </div>
    );
};
