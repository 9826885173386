import { EuiProvider } from "@elastic/eui";
import { parse_translations_dict } from "locale/i18n";
import NewPrice from "pages/NewPrice";
import PriceListNew from "pages/PriceListNew";
import PricesPage from "pages/Prices";
import React from "react";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "react-query";
import { Redirect } from "react-router";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import { setUser } from "./api/Client";
import { setTableUser } from "./api/filterable";
import ErrorBoundary from "./components/ErrorBoundary";
import Header from "./components/Header";
import ProtectedRoute from "./components/ProtectedRoute";
import { GlobalToastContextWrapper } from "./contextProviders/GlobalToastProvider/index";
import CategoryDetailPage from "./pages/CategoryDetailPage";
import CreateCannabisProduct from "./pages/CreateCannabisProduct";
import DynamicPricelistCannabis from "./pages/dynamicPricelist/DynamicPricelistCannabis";
import DynamicPricelistHoreca from "./pages/dynamicPricelist/DynamicPricelistHoreca";
import ImageLibrary from "./pages/ImageLibrary";
import KindsPage from "./pages/KindsPage";
import Login from "./pages/Login";
import ManageCategories from "./pages/ManageCategories";
import ManageStrainsTags from "./pages/ManageStrainsTags";
import NewKindOrProduct from "./pages/NewKindOrProduct";
import NewProduct from "./pages/NewProduct";
import OrdersPage from "./pages/OrdersPage";
import Pricelist from "./pages/PriceList";
import ProductDetailPage from "./pages/ProductDetailPage";
import Products from "./pages/Products";
import ProductsPage from "./pages/ProductsPage";
import QuickEditor from "./pages/QuickEditor";
import ResetPassword from "./pages/ResetPassword";
import { ShopContext } from "./utils/Shop";
import { isEmpty, resetAppState } from "./utils/Utils";
import { APP_VERSION } from "./utils/Version";

// Signed-in user context
export const UserContext = React.createContext({
    user: {},
});

export const ThemeContext = React.createContext({
    theme: "light",
});

const queryClient = new QueryClient();

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            intl: "nl-NL",
            user: { isLoggedIn: false, profile: undefined },
            shop: "Mississippi",
            shopId: "19149768-691c-40d8-a08e-fe900fd23bc0",
            shopGroups: [],
        };
    }
    logout = () => {
        const appState = resetAppState();
        this.setState(appState);
        window.location.href = "/login";
    };

    getCurrentShop = async () => {
        const currentShop = window.localStorage.getItem(`currentShop`);
        const currentShopId = window.localStorage.getItem(`currentShopId`);
        if (currentShop !== null) {
            console.log("setting stored shop", currentShop);
            this.setState({
                shop: currentShop,
                shopId: currentShopId,
            });
        } else {
            // if local storage empty set to default: Mississippi
            await this.setCurrentShopId("19149768-691c-40d8-a08e-fe900fd23bc0");
            await this.setCurrentShopName("Mississippi");
        }
    };

    getCurrentShopId = async () => {
        const currentShopId = window.localStorage.getItem(`currentShopId`);
        if (currentShopId !== null) {
            console.log("setting stored shopId", currentShopId);
            this.setState({
                shopId: currentShopId,
            });
        } else {
            // if local storage empty set to default: Mississippi
            await this.setCurrentShopId("19149768-691c-40d8-a08e-fe900fd23bc0");
            await this.setCurrentShopName("Mississippi");
        }
    };

    setCurrentShopName = async (shopName) => {
        this.setState({
            shop: shopName,
        });
        window.localStorage.setItem(`currentShop`, shopName);
    };

    setCurrentShopId = async (shopId) => {
        this.setState({
            shopId: shopId,
        });
        window.localStorage.setItem(`currentShopId`, shopId);
    };

    setCurrentShopGroups = async (shopGroups) => {
        this.setState({
            shopGroups: shopGroups,
        });
        window.localStorage.setItem(`currentShopGroups`, shopGroups);
    };

    async componentDidMount() {
        const messages = await import("locale/nl");
        const appState = localStorage["appState"];
        document.title = `Shop v${APP_VERSION}`;
        if (!isEmpty(appState)) {
            const newState = JSON.parse(appState);
            if (newState.profile) {
                setUser(newState.profile.token);
                setTableUser(newState.profile.token);
            }
            this.getCurrentShop().then();
            this.setState({
                user: {
                    isLoggedIn: newState.isLoggedIn,
                    profile: newState.profile,
                },
                messages: parse_translations_dict(messages.default),
                theme: localStorage.getItem("darkMode") || false ? "dark" : "light",
                shopGroups: newState?.profile?.shop_groups || [],
            });
        }
    }

    render() {
        const { user, shop, shopId, shopGroups, theme, messages } = this.state;

        return (
            <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <UserContext.Provider value={user}>
                        <ThemeContext.Provider value={theme}>
                            <EuiProvider colorMode={theme}>
                                <ShopContext.Provider
                                    value={{
                                        shopName: shop,
                                        setShopName: this.setCurrentShopName,
                                        shopId: shopId,
                                        setShopId: this.setCurrentShopId,
                                        shopGroups: shopGroups,
                                        setShopGroups: this.setCurrentShopGroups,
                                    }}
                                >
                                    <GlobalToastContextWrapper>
                                        <IntlProvider locale="en" defaultLocale="en" messages={messages}>
                                            <Router>
                                                <QueryParamProvider ReactRouterRoute={Route}>
                                                    {!window.location.pathname.startsWith("/pricelist") && <Header />}
                                                    <div>
                                                        <Switch>
                                                            <Route exact path="/login" render={() => <Login />} />
                                                            <Route exact path="/logout" render={() => this.logout()} />
                                                            <Route
                                                                exact
                                                                path="/pricelist/f155b35a-14a1-4866-867f-93fe21e9769b"
                                                                render={() => <PriceListNew />}
                                                            />
                                                            <Route
                                                                exact
                                                                path="/pricelist/f155b35a-14a1-4866-867f-93fe21e9769b/extra"
                                                                render={() => <PriceListNew />}
                                                            />

                                                            <Route
                                                                exact
                                                                path="/pricelist/:shopId?"
                                                                render={() => <Pricelist />}
                                                            />

                                                            <Route
                                                                exact
                                                                path="/pricelist/:shopId?/horeca"
                                                                render={() => <DynamicPricelistHoreca />}
                                                            />
                                                            <Route
                                                                exact
                                                                path="/pricelist/:shopId?/cannabis"
                                                                render={() => <DynamicPricelistCannabis />}
                                                            />
                                                            <Route
                                                                path="/reset-password"
                                                                render={(props) => <ResetPassword {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/manage/quick-editor"
                                                                render={(props) => <QuickEditor {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/manage/:type/new"
                                                                render={(props) => <NewKindOrProduct {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/manage/horeca"
                                                                render={(props) => <ProductsPage {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/manage/soorten"
                                                                render={(props) => <KindsPage {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/view/categories/:id"
                                                                render={(props) => <CategoryDetailPage {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/view/:type/:id"
                                                                render={(props) => <ProductDetailPage {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/products/cannabis"
                                                                render={(props) => (
                                                                    <Products showCannabis={true} {...props} />
                                                                )}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/products/horeca"
                                                                render={(props) => (
                                                                    <Products showCannabis={false} {...props} />
                                                                )}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/products/edit-product/cannabis/:id"
                                                                render={(props) => (
                                                                    <NewProduct addCannabis={true} {...props} />
                                                                )}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/products/new-product/cannabis"
                                                                render={(props) => (
                                                                    <NewProduct addCannabis={true} {...props} />
                                                                )}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/products/new-product/kind"
                                                                render={(props) => <CreateCannabisProduct {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/products/manage/strains-tags"
                                                                render={(props) => <ManageStrainsTags {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/products/manage/categories"
                                                                render={(props) => <ManageCategories {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/products/edit-product/horeca/:id"
                                                                render={(props) => (
                                                                    <NewProduct addCannabis={false} {...props} />
                                                                )}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/products/new-product/horeca"
                                                                render={(props) => (
                                                                    <NewProduct addCannabis={false} {...props} />
                                                                )}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                exact
                                                                path="/image-library"
                                                                render={(props) => <ImageLibrary {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/orders"
                                                                render={(props) => <OrdersPage {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                exact
                                                                path="/prices"
                                                                render={(props) => <PricesPage {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/prices/new"
                                                                render={(props) => <NewPrice {...props} />}
                                                            />
                                                            <ProtectedRoute
                                                                user={user}
                                                                path="/prices/edit/:id"
                                                                render={(props) => <NewPrice {...props} />}
                                                            />
                                                            <Route
                                                                path="/"
                                                                render={() => <Redirect to={"/products/cannabis"} />}
                                                            />
                                                        </Switch>
                                                    </div>
                                                    {/*<ReactQueryDevtools/>*/}
                                                </QueryParamProvider>
                                            </Router>
                                        </IntlProvider>
                                    </GlobalToastContextWrapper>
                                </ShopContext.Provider>
                            </EuiProvider>
                        </ThemeContext.Provider>
                    </UserContext.Provider>
                </QueryClientProvider>
            </ErrorBoundary>
        );
    }
}

export default App;
