import { EuiSuperSelect, EuiText } from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select/super_select_control";
import React, { useContext } from "react";

import { ShopContext } from "../utils/Shop";
import { ShopId } from "../utils/types";
import { SHOP_GROUP_GLOBAL_STRING } from "./tables/tableSettingsUtils";

interface ShopGroupSelectorProps {
    currentShopGroupId: ShopId;
    handleShopGroupChange: (value: ShopId) => void;
}

export const ShopGroupSelector = ({ currentShopGroupId, handleShopGroupChange }: ShopGroupSelectorProps) => {
    const { shopGroups } = useContext(ShopContext);

    const options: EuiSuperSelectOption<ShopId>[] = [
        ...shopGroups.map((shopGroup) => {
            return {
                value: shopGroup.id,
                inputDisplay: shopGroup.name,
                dropdownDisplay: (
                    <>
                        <strong>{shopGroup.name}</strong>
                        <EuiText size="s" color="subdued">
                            <p>Toon alleen producten voor {shopGroup.name} shop groep.</p>
                        </EuiText>
                    </>
                ),
            };
        }),
        {
            value: SHOP_GROUP_GLOBAL_STRING,
            inputDisplay: "Global",
            dropdownDisplay: (
                <>
                    <strong>Global</strong>
                    <EuiText size="s" color="subdued">
                        <p>Toon alleen de globale producten. Deze zijn niet gekoppeld aan een shop groep.</p>
                    </EuiText>
                </>
            ),
        },
    ];

    return (
        <EuiSuperSelect
            style={{ minWidth: "300px" }}
            options={options}
            valueOfSelected={currentShopGroupId}
            onChange={handleShopGroupChange}
        />
    );
};
