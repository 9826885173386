import { EuiButton } from "@elastic/eui";
import AWS from "aws-sdk";
import React, { useState } from "react";

import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, REGION, S3_BUCKET } from "../Constants";
import ProductImage from "./ProductImage";

AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

const ListS3Images = () => {
    const [images, setImages] = useState([]);

    const retrieve = () => {
        myBucket.listObjects({ Delimiter: "/" }, function (err, data) {
            if (err) {
                return alert("There was an error listing your albums: " + err.message);
            } else {
                console.log(
                    data.Contents.map((item) => {
                        return item.Key;
                    })
                );
                setImages(
                    data.Contents.map((item) => {
                        return item.Key;
                    })
                );
                console.log(images);
            }
        });
    };

    console.log(images);

    return (
        <div>
            <EuiButton size="s" iconType="refresh" onClick={retrieve}>
                {" "}
                Retrieve list
            </EuiButton>
            <div>
                {images.map((item) => (
                    <ProductImage imageKey={item} />
                ))}
            </div>
        </div>
    );
};

export default ListS3Images;
