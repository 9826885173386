import { JSONSchema6 } from "json-schema";
import React from "react";
export interface State {
    [index: string]: any;
}

export type Theme = "light" | "dark";

export interface Action {
    euiIcon: string;
    label: string;
    action: (e: React.MouseEvent<HTMLButtonElement>) => void;
    danger?: boolean;
    loading?: boolean;
    id?: string;
}

export interface User {
    first_name: string;
    last_name: string;
    id: string;
    email: string;
    username?: string;
    auth_token?: string;
    quick_token?: string;
    quick_token_created_at?: string;
    created_at: string;
    timestamp?: string;
    roles: string[];
}

export type ShopId = string;
export type ShopGroup = {
    id: string;
    name: string;
    shop_ids: ShopId[];
};

/* Tables */
export interface FilterArgument {
    id: string;
    values: string[];
}

export interface Tag {
    id: string;
    name: string;
    isNew?: boolean;
}

export interface Strain {
    id: string;
    name: string;
    isNew?: boolean;
}

export interface Flavor {
    id: string;
    name: string;
    icon: string;
    color: string;
    isNew?: boolean;
}

export interface FormOption<Value = string> {
    value: Value;
    label: string;
}

export interface OptionBool {
    value: boolean;
    label: string;
}

export type PriceQuantity = "half" | "one" | "two_five" | "five" | "joint" | "piece";
// #231F20
/* API responses */
export interface OrderLine {
    description: string;
    price: number;
    kind_id?: string;
    kind_name?: string;
    product_id?: string;
    product_name?: string;
    internal_product_id?: string;
    quantity: number;
}

export interface Order {
    id: string;
    shop_id: string;
    shop_name?: string;
    order_info: OrderLine[];
    total: number;
    status: "pending" | "complete" | "cancelled";
    created_at: string;
    completed_at?: string;
    completed_by?: string;
    table_id?: string;
    table_name?: string;
}

export interface Shop {
    id: string;
    name: string;
    description: string;
}

export type JsonPrice = {
    label: string;
    quantity: number;
    price: number;
    active: boolean;
};

export type PriceForForm = {
    id: string;
    internal_product_id: string;
    shop_group_id: string;
    created_at: string;
    modified_at: string;
    //New Model
    cannabis: JsonPrice[];
    pre_rolled_joints: JsonPrice[];
    edible: JsonPrice[];
    //Old Model
    half: number;
    one: number;
    two_five: number;
    five: number;
    joint: number;
    piece: number;
};

export type PriceForTable = {
    id: string;
    internal_product_id: string;
    prices: object;
    shop_group_id: string;
    created_at: string;
    modified_at: string;
    model: string;
};

export interface Price<T = object> {
    id: string;
    internal_product_id: number;
    active: boolean;
    new: boolean;
    category_id: string;
    category_name: string;
    category_name_en: string | null;
    category_icon: string | null;
    category_order_number: number;
    main_category_id: string;
    main_category_name: string;
    main_category_name_en: string | null;
    main_category_icon: string;
    main_category_order_number: number;
    kind_id: string | null;
    kind_image: string | null;
    kind_name: string;
    kind_short_description_nl: string;
    kind_short_description_en: string;
    product_id: string | null;
    product_image: string | null;
    product_name: string;
    product_short_description_nl: string;
    product_short_description_en: string;
    // TODO: add strains
    kind_c: boolean;
    kind_h: boolean;
    kind_i: boolean;
    kind_s: boolean;
    half: number | null;
    one: number | null;
    two_five: number | null;
    five: number | null;
    joint: number | null;
    piece: number | null;
    created_at: string;
    modified_at: string;
    json_prices: T[];
    flags: string[];
    icons: string[];
    category_pricelist_row: number;
}

export interface ProductForEditor {
    id: string;
    name: string;
    description_nl: string;
    description_en: string;
    modified_at: string;
    c?: boolean;
    h?: boolean;
    i?: boolean;
    s?: boolean;
    images_amount: number;
}
export interface ProductForEditorEnriched extends ProductForEditor {
    product_type: string;
    product_state: "text_problem" | "text_complete" | "text_and_picture_complete";
}

export enum ProductType {
    C = "CBD",
    H = "Hybrid",
    I = "Indica",
    S = "Sativa",
}

export interface Filter {
    name: string;
    count: number;
    selected: boolean;
}

export interface SortOption<nameStrings = string> {
    name: nameStrings;
    descending: boolean;
}

/* Form stuff */

export interface FormNotCompleteResponse {
    form: InputForm;
    hasNext?: boolean;
}

export interface Form {
    stepUserInput?: JSONSchema6;
    hasNext?: boolean;
}

export type InputForm = JSONSchema6;

export interface ValidationError {
    input_type: string;
    loc: (string | number)[];
    msg: string;
    type: string;
    ctx?: ValidationErrorContext;
}

export interface ValidationErrorContext {
    [index: string]: string;
}

/* Tables */

export interface ProductForTable {
    name: string;
    short_description_nl: string;
    description_nl?: string;
    short_description_en?: string;
    description_en?: string;
    complete: boolean;
    image_1?: string;
    image_2?: string;
    image_3?: string;
    image_4?: string;
    image_5?: string;
    image_6?: string;
    id: string;
    created_at?: string;
    modified_at?: string;
    approved_at?: string;
    images_amount: number;
}

export interface Product {
    name: string;
    short_description_nl: string;
    description_nl?: string;
    short_description_en?: string;
    description_en?: string;
}

// Todo: add cannabis specific fields
export interface Kind {
    name: string;
    short_description_nl: string;
    description_nl?: string;
    short_description_en?: string;
    description_en?: string;
    c: boolean;
    h: boolean;
    i: boolean;
    s: boolean;
}

export interface KindForTable {
    name: string;
    short_description_nl: string;
    description_nl?: string;
    short_description_en?: string;
    description_en?: string;
    complete: boolean;
    image_1?: string;
    image_2?: string;
    image_3?: string;
    image_4?: string;
    image_5?: string;
    image_6?: string;
    id: string;
    created_at?: string;
    modified_at?: string;
    approved_at?: string;
    images_amount: number;
    flags: string[];
    icons: string[];
    shop_group_id: string;
}

export interface Category {
    id: string;
    name: string;
    name_en: string;
    description?: string;
    main_category_id?: string;
    color: string;
    shop_name?: boolean;
    image_1?: string;
    image_2?: string;
    pricelist_column?: number;
    pricelist_row?: number;
    cannabis?: boolean;
}

export interface MainCategory {
    name: string;
    name_en: string;
    description: string;
    icon: string;
    order_number: number;
    id: string;
    shop_name: string;
    main_category_and_shop: string;
}

export enum MutationType {
    PictureUpload,
    Edited,
    Added,
    Deleted,
}

//Either one type or the other, but not both
type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export interface NameWithDescription {
    title: string;
    description: string;
}

export interface FlavorWithIndex {
    id: string;
    index: number;
    name: string;
    color: string;
}

export interface ComboBoxValue {
    label: string;
}

export interface StrainWithIndex {
    id: string;
    index: number;
    name: string;
}

export interface TagWithIndex {
    id: string;
    index: number;
    name: string;
    amount: number;
}

export interface FlagWithIndex {
    id: string;
    index: number;
    name: string;
}

interface RelationExtras {
    id: string;
    index: number;
    kind_id: string;
    name: string;
}

export interface KindToFlavorWithExtras extends RelationExtras {
    flavor_id: string;
    color: string;
}

export interface KindToStrainWithExtras extends RelationExtras {
    strain_id: string;
}

export interface KindToTagWithExtras extends RelationExtras {
    tag_id: string;
    amount: number;
}

export enum ResourceType {
    Category = "categorie",
    Strain = "strain",
    Tag = "tag",
    Kind = "kind",
    Product = "product",
}

export interface ResourcePayload {
    name?: string;
    uuid?: string;
}

export interface TableItem {
    index: number;
    value: ResourceItem;
}

export interface CreateResource extends ResourcePayload {
    short_description_nl?: string;
    short_description_en?: string | undefined;
    description_en?: string | undefined;
    description_nl?: string | undefined;
    c?: boolean;
    h?: boolean;
    i?: boolean;
    s?: boolean;
    name_en?: string;
    description?: string;
    shop_id?: string;
    main_category_id?: string;
    color?: string;
    icon?: string;
    cannabis?: string;
}

export enum PricelistColumns {
    LEFT = "Left",
    RIGHT = "Right",
}

export enum RowType {
    Title,
    DataRow,
    Blank,
    Legend,
}

export interface PricelistTableRow<T> {
    text: string;
    price: Price<T> | null;
    type: RowType;
    row: number;
    column: number;
}

export enum PricelistQueryKey {
    HORECA = "horeca",
    CANNABIS = "cannabis",
}

export type Background = {
    type: string;
    src: string;
};

export type KindRelation = XOR<KindToFlavorWithExtras, XOR<KindToStrainWithExtras, KindToTagWithExtras>>;
export type KindRelationWithIndex = XOR<TagWithIndex, XOR<StrainWithIndex, FlavorWithIndex>>;
export type ResourceItem = XOR<Tag, XOR<Strain, XOR<Category, Flavor>>>;
