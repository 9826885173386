import { EuiPage, EuiPanel } from "@elastic/eui";
import client from "api/Client";
import { PriceForm } from "components/PriceForm";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

import { ShopContext } from "../utils/Shop";

export default function NewPrice() {
    const [price, setPrice] = useState(undefined);
    const { shopId } = useContext(ShopContext);
    const [useOldModel, setUseOldModel] = useState(shopId !== "f155b35a-14a1-4866-867f-93fe21e9769b");

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            client.get(`/v1/prices/${id}`).then((result) => {
                setPrice(result.data);
                if (
                    result.data.cannabis === null ||
                    result.data.edible === null ||
                    result.data.pre_rolled_joints === null
                ) {
                    setUseOldModel(true);
                }
            });
        }
    }, [id]);

    return (
        <EuiPage>
            <EuiPanel>
                <PriceForm price={price} useOldModel={useOldModel} />
            </EuiPanel>
        </EuiPage>
    );
}
