import "./Forms.scss";

import {
    EuiButton,
    EuiCallOut,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiSwitch,
    EuiText,
} from "@elastic/eui";
import React from "react";

import client from "../api/Client";
import API_URL from "../Constants";
import { ShopContext } from "../utils/Shop";

class CannabisForm extends React.Component {
    // Get shop context
    static contextType = ShopContext;

    constructor(props) {
        super(props);

        this.state = {
            redirectToListEnabled: true,
            prices: [],
            priceOptions: [],
            piece: undefined,
            pricesLoaded: false,
            categories: [],
            categoriesLoaded: false,
            kinds: [],
            kindsLoaded: false,
            selectedPrice: this.props.product ? this.props.product.price_id : "-1",
            selectedCategory: this.props.product ? this.props.product.category_id : "-1",
            selectedKind: this.props.product ? this.props.product.kind_id : "-1",
            loading: false,
            message: undefined,
            errors: false,
            messageHelp: undefined,

            //checked prices states
            checkedHalf: false,
            checkedOne: false,
            checkedTwohalf: false,
            checkedFive: false,
            checkedJoint: false,
            checkedPiece: false,
            checkedNew: true,

            //disable price toggles states
            halfDisabled: true,
            oneDisabled: true,
            twohalfDisabled: true,
            fiveDisabled: true,
            jointDisabled: true,
            pieceDisabled: true,
        };
    }

    componentDidMount = async () => {
        const currentShop = this.context;
        const product = this.props.product;
        const shopGroupsRes = await client.get(`${API_URL}/v1/shop-groups?skip=0&limit=1000`);
        const shopGroupIds = shopGroupsRes.data
            .filter((group) => group.shop_ids.includes(currentShop.shopId))
            .map((group) => group.id);
        client.get(`${API_URL}/v1/prices?skip=0&limit=1000&sort=internal_product_id`).then((result) => {
            const priceOptions = [];
            result.data
                .filter((price) => shopGroupIds.includes(price.shop_group_id))
                .forEach((price) => {
                    let summary = `${price.internal_product_id}`;
                    if (price.half) summary += ` - 0,5g voor ${price.half}`;
                    if (price.one) summary += ` - 1g voor ${price.one}`;
                    if (price.two_five) summary += ` - 2,5g voor ${price.two_five}`;
                    if (price.five) summary += ` - 5g voor ${price.five}`;
                    if (price.joint) summary += ` - 1j voor ${price.joint}`;
                    if (price.piece) summary += ` - 1s voor ${price.piece}`;
                    priceOptions.push({
                        text: summary,
                        value: price.id,
                    });
                });

            priceOptions.unshift({ label: "", value: "-1" });

            this.setState({
                prices: result.data,
                priceOptions: priceOptions,
                pricesLoaded: true,
                selectedPrice: "-1",
            });
            if (product) {
                this.setState({
                    selectedPrice: product.price_id,
                    checkedNew: product.new,
                    halfDisabled: product.half === null,
                    checkedHalf: product.use_half,
                    oneDisabled: product.one === null,
                    checkedOne: product.use_one,
                    twohalfDisabled: product.two_five === null,
                    checkedTwohalf: product.use_two_five,
                    fiveDisabled: product.five === null,
                    checkedFive: product.use_five,
                    jointDisabled: product.joint === null,
                    checkedJoint: product.use_joint,
                    pieceDisabled: product.piece === null,
                    checkedPiece: product.use_piece,
                });
            }
        });

        client.get(`${API_URL}/v1/categories?skip=0&limit=1000&sort=name`).then((result) => {
            const filterCategories = result.data.filter(
                (value) => value.shop_id === currentShop.shopId && value.cannabis
            );
            let categories = filterCategories.map((category) => {
                category.label = category.name;
                category.value = category.id;
                return category;
            });
            // Add blanco first option
            categories.unshift({ label: "", value: "-1" });

            this.setState({
                categories: categories,
                categoriesLoaded: true,
                selectedCategory: product ? product.category_id : "-1",
            });
        });
        client.get(`${API_URL}/v1/kinds/all`).then((result) => {
            const sortedData = result.data.sort((a, b) => a.name.localeCompare(b.name));
            const kinds = sortedData.map((kind) => {
                kind.label = kind.shop_group_id ? `${kind.name}` : `${kind.name}  (Global)`;
                kind.value = kind.id;
                return kind;
            });
            // Add blanco first option
            kinds.unshift({ label: "", value: "-1" });
            let selectedKind = product ? product.kind_id : "-1";
            if (this.props.preselectedWiet) {
                console.log("Preselecting a wiet", this.props.preselectedWiet);
                selectedKind = this.props.preselectedWiet;
            }
            this.setState({
                kinds: kinds,
                kindsLoaded: true,
                selectedKind: selectedKind,
            });
        });
    };

    onCategorySelect = (e) => {
        this.setState({ selectedCategory: e.target.value });
    };

    onPriceSelect = (price_id) => {
        const { prices } = this.state;
        const selectedPriceTemplate = prices.find((price) => price.id === price_id);
        this.setState({ selectedPrice: price_id });

        const half = selectedPriceTemplate.half;
        if (half != null) {
            this.setState({ halfDisabled: false, checkedHalf: true });
        } else this.setState({ halfDisabled: true, checkedHalf: false });
        console.log("half:", half);

        const one = selectedPriceTemplate.one;
        if (one != null) {
            this.setState({ oneDisabled: false, checkedOne: true });
        } else this.setState({ oneDisabled: true, checkedOne: false });
        console.log("one:", one);

        const twohalf = selectedPriceTemplate.two_five;
        if (twohalf != null) {
            this.setState({ twohalfDisabled: false, checkedTwohalf: true });
        } else this.setState({ twohalfDisabled: true, checkedTwohalf: false });
        console.log("two half:", twohalf);

        const five = selectedPriceTemplate.five;
        if (five != null) {
            this.setState({ fiveDisabled: false, checkedFive: true });
        } else this.setState({ fiveDisabled: true, checkedFive: false });
        console.log("five:", five);

        const joint = selectedPriceTemplate.joint;
        if (joint != null) {
            this.setState({ jointDisabled: false, checkedJoint: true });
        } else this.setState({ jointDisabled: true, checkedJoint: false });
        console.log("joint:", joint);

        const piece = selectedPriceTemplate.piece;
        if (piece != null) {
            this.setState({ pieceDisabled: false, checkedPiece: true });
        } else this.setState({ pieceDisabled: true, checkedPiece: false });
        console.log("piece:", piece);
    };

    onChangeNew = (e) => {
        this.setState({ checkedNew: e.target.checked });
    };

    onKindSelect = (e) => {
        this.setState({ selectedKind: e.target.value });
    };

    onChangeHalf = (e) => {
        this.setState({ checkedHalf: e.target.checked });
    };

    onChangeOne = (e) => {
        this.setState({ checkedOne: e.target.checked });
    };

    onChangeTwohalf = (e) => {
        this.setState({ checkedTwohalf: e.target.checked });
    };

    onChangeFive = (e) => {
        this.setState({ checkedFive: e.target.checked });
    };

    onChangeJoint = (e) => {
        this.setState({ checkedJoint: e.target.checked });
    };

    onChangePiece = (e) => {
        this.setState({ checkedPiece: e.target.checked });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const product = this.props.product;
        const currentShop = this.context;
        const {
            selectedPrice,
            selectedKind,
            selectedCategory,
            checkedHalf,
            checkedOne,
            checkedTwohalf,
            checkedFive,
            checkedJoint,
            checkedPiece,
            checkedNew,
            kinds,
        } = this.state;

        if (selectedKind === "-1" || selectedPrice === "-1" || selectedCategory === "-1") {
            this.setState({
                message: "Error",
                messageHelp:
                    "Het form is niet compleet ingevuld. Prijs, categorie en product-soort moeten ingevuld zijn.",
                errors: true,
            });
            setTimeout(() => {
                this.setState({
                    message: undefined,
                    messageHelp: undefined,
                    errors: false,
                });
            }, 3000);
            return;
        }

        let payload = {
            shop_id: currentShop.shopId,
            new: checkedNew,
            price_id: selectedPrice,
            product_id: null,
            category_id: selectedCategory,
            kind_id: selectedKind,
            use_half: checkedHalf,
            use_one: checkedOne,
            use_two_five: checkedTwohalf,
            use_five: checkedFive,
            use_joint: checkedJoint,
            use_piece: checkedPiece,
        };
        if (!product) {
            payload.active = true;
        } else {
            payload.active = product.active;
        }

        this.setState({ loading: true });
        const kindName = kinds.find((kind) => kind.id === selectedKind).name;
        if (product) {
            client
                .put(`/v1/shops-to-prices/${product.id}`, payload) // Todo: probably jsonify needed
                .then((result) => {
                    this.setState({
                        message: `Product: ${kindName}, is opgeslagen. Redirecting..`,
                        messagehelp: undefined,
                        errors: false,
                    }); // debugger;
                    setTimeout(() => {
                        window.location.replace("/products/cannabis");
                    }, 1500);
                })
                .catch((result) => {
                    if (result.message === "Request failed with status code 409") {
                        this.setState({
                            message: "Error",
                            messageHelp: "U heeft dit product al toegevoegd aan deze shop!",
                            errors: true,
                        });
                        setTimeout(() => {
                            this.setState({
                                message: undefined,
                                messageHelp: undefined,
                                errors: false,
                            });
                        }, 3000);
                    } else {
                        this.setState({
                            message: "Error",
                            messageHelp: "Een form veld klopt niet.",
                            errors: true,
                        });
                        setTimeout(() => {
                            this.setState({
                                message: undefined,
                                messageHelp: undefined,
                                errors: false,
                            });
                        }, 3000);
                    }
                });
        } else {
            client
                .post(`/v1/shops-to-prices`, payload) // Todo: probably jsonify needed
                .then((result) => {
                    this.setState({
                        message: `Product: ${kindName}, is toegevoegd`,
                        messagehelp: undefined,
                        errors: false,
                    }); // debugger;
                    if (this.state.redirectToListEnabled) {
                        setTimeout(() => {
                            window.location.replace("/products/cannabis");
                        }, 3000);
                    } else {
                        this.resetFormState();
                        setTimeout(() => {
                            this.setState({
                                message: undefined,
                                messageHelp: undefined,
                                errors: false,
                            });
                        }, 3000);
                    }
                })
                .catch((result) => {
                    // Todo RD: check error handling in general for axios.
                    //  https://github.com/acidjunk/pricelist-frontend/issues/69

                    if (result.message === "Request failed with status code 409") {
                        this.setState({
                            message: "Error",
                            messageHelp: "U heeft dit product al toegevoegd aan deze shop!",
                            errors: true,
                        });
                        setTimeout(() => {
                            this.setState({
                                message: undefined,
                                messageHelp: undefined,
                                errors: false,
                            });
                        }, 3000);
                    } else {
                        this.setState({
                            message: "Error",
                            messageHelp: "Een form veld klopt niet.",
                            errors: true,
                        });
                        setTimeout(() => {
                            this.setState({
                                message: undefined,
                                messageHelp: undefined,
                                errors: false,
                            });
                        }, 3000);
                    }
                });
        }
    };

    resetFormState = () => {
        this.setState({
            selectedKind: "-1",
            selectedPrice: "-1",
            selectedCategory: "-1",
            checkedHalf: false,
            checkedOne: false,
            checkedTwohalf: false,
            checkedFive: false,
            checkedJoint: false,
            checkedPiece: false,
            checkedNew: false,
        });
    };

    handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            console.log("Enter key pressed; submitting form");
            this.handleSubmit(event);
        }
    };

    render() {
        const { product } = this.props;
        const {
            redirectToListEnabled,
            priceOptions,
            categories,
            categoriesLoaded,
            kinds,
            kindsLoaded,
            selectedCategory,
            selectedPrice,
            selectedKind,
            errors,
            message,
            messageHelp,
            loading,
            checkedHalf,
            checkedOne,
            checkedTwohalf,
            checkedFive,
            checkedJoint,
            checkedPiece,
            checkedNew,
            halfDisabled,
            oneDisabled,
            twohalfDisabled,
            fiveDisabled,
            pieceDisabled,
            jointDisabled,
        } = this.state;

        return (
            <EuiForm>
                {message && (
                    <>
                        <EuiCallOut
                            title={message}
                            color={errors ? "danger" : "primary"}
                            iconType={errors ? "alert" : "notebookApp"}
                        >
                            {messageHelp && <p>{messageHelp}</p>}
                        </EuiCallOut>
                        <EuiSpacer />
                    </>
                )}

                {!product && (
                    <>
                        <EuiSwitch
                            label="Blijf op deze pagina na toevoegen"
                            checked={!redirectToListEnabled}
                            onChange={() => this.setState({ redirectToListEnabled: !redirectToListEnabled })}
                        />
                        <EuiSpacer />
                    </>
                )}

                <EuiSwitch
                    label="Publiceer product met label 'NEW'"
                    checked={checkedNew}
                    onChange={(e) => this.onChangeNew(e)}
                />
                <EuiSpacer />

                <EuiFormRow
                    label="Categorie"
                    labelAppend={<EuiText size="m">Selecteer een categorie</EuiText>}
                    id="select-category"
                    fullWidth
                >
                    <EuiSelect
                        value={selectedCategory}
                        onChange={(e) => this.onCategorySelect(e)}
                        options={categories}
                        isLoading={!categoriesLoaded}
                        autoFocus={true}
                        onKeyDown={this.handleKeyDown}
                        fullWidth
                    />
                </EuiFormRow>

                <EuiSpacer />

                <EuiFormRow
                    label="Soort"
                    labelAppend={
                        <EuiText size="m">
                            Selecteer een cannabis soort. (Global zijn beschikbaar voor alle shops.)
                        </EuiText>
                    }
                    id="select-kind"
                    fullWidth
                >
                    <EuiSelect
                        value={selectedKind}
                        onChange={(e) => this.onKindSelect(e)}
                        options={kinds}
                        isLoading={!kindsLoaded}
                        onKeyDown={this.handleKeyDown}
                        fullWidth
                    />
                </EuiFormRow>

                <EuiSpacer />

                <EuiFormRow
                    label="Prijs"
                    labelAppend={<EuiText size="m">Selecteer een kassa code</EuiText>}
                    id="select-price"
                    fullWidth
                >
                    <EuiSelect
                        onChange={(e) => this.onPriceSelect(e.target.value)}
                        options={priceOptions}
                        value={selectedPrice}
                        onKeyDown={this.handleKeyDown}
                        fullWidth
                    />
                </EuiFormRow>

                <EuiSpacer />

                <EuiFormRow
                    label="Prijs"
                    labelAppend={<EuiText size="m">Kies welke prijzen je wilt gebruiken van dit product</EuiText>}
                    id="select-price-options"
                    fullWidth
                >
                    <>
                        <EuiSpacer />
                        <EuiSwitch
                            label="0.5g"
                            checked={checkedHalf}
                            onChange={(e) => this.onChangeHalf(e)}
                            disabled={halfDisabled}
                        />
                        <EuiSwitch
                            label="1g"
                            checked={checkedOne}
                            onChange={(e) => this.onChangeOne(e)}
                            style={{ marginLeft: "20px" }}
                            disabled={oneDisabled}
                        />
                        <EuiSwitch
                            label="2.5g"
                            checked={checkedTwohalf}
                            onChange={(e) => this.onChangeTwohalf(e)}
                            style={{ marginLeft: "20px" }}
                            disabled={twohalfDisabled}
                        />
                        <EuiSpacer />
                        <EuiSwitch
                            label="5g"
                            checked={checkedFive}
                            onChange={(e) => this.onChangeFive(e)}
                            disabled={fiveDisabled}
                        />
                        <EuiSwitch
                            label="Joint"
                            checked={checkedJoint}
                            onChange={(e) => this.onChangeJoint(e)}
                            style={{ marginLeft: "20px" }}
                            disabled={jointDisabled}
                        />
                        <EuiSwitch
                            label="Stuks prijs"
                            checked={checkedPiece}
                            onChange={(e) => this.onChangePiece(e)}
                            style={{ marginLeft: "20px" }}
                            disabled={pieceDisabled}
                        />
                    </>
                </EuiFormRow>

                <EuiSpacer />

                <EuiFormRow>
                    <EuiFlexGroup>
                        {!product && (
                            <EuiFlexItem>
                                {" "}
                                <EuiButton fill color="danger" onClick={this.resetFormState} disabled={false}>
                                    Reset
                                </EuiButton>
                            </EuiFlexItem>
                        )}

                        <EuiFlexItem>
                            <EuiButton
                                fill
                                onClick={(e) => this.handleSubmit(e)}
                                disabled={selectedKind === "-1" || selectedCategory === "-1" || selectedPrice === "-1"}
                                type="submit"
                                isloading={loading}
                            >
                                {product ? "Opslaan" : "Toevoegen"}
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFormRow>
            </EuiForm>
        );
    }
}

export default CannabisForm;
