import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <>
        <link
            rel="stylesheet"
            type="text/css"
            href={localStorage.getItem("darkMode") || false ? "/eui_theme_dark.css" : "/eui_theme_light.css"}
        />
        <link
            rel="stylesheet"
            type="text/css"
            href={localStorage.getItem("darkMode") || false ? "/dark.css" : "/light.css"}
        />
        <App />
    </>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
