/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import { EuiFieldText } from "@elastic/eui";
import debounce from "lodash/debounce";
import React, { Dispatch } from "react";
import Select from "react-select";
import { ColumnInstance, TableState } from "react-table";

import { Order } from "../../utils/types";
import DropDownContainer from "./DropDownContainer";
import { ActionType, TableSettingsAction } from "./tableSettingsUtils";

/*
 * Functional components to use as filter input fields in tables.
 * Note that none of the renderers are valid standalone react components as they expect the complete table state and dispatch.
 */

const renderFilterIcon = (filtering: boolean) => (active: boolean) => {
    return (
        <>
            {filtering ? (
                <i className={`fa fa-check-square${active ? " active" : ""}`} />
            ) : (
                <i className={`fa fa-square-o${active ? " active" : ""}`} />
            )}
            <i className={active ? "fa fa-caret-down active" : "fa fa-caret-right"} />
        </>
    );
};

export function renderMultiSelectFilter(
    allOptions: string[],
    i18nPrefix: string | null,
    {
        state,
        dispatch,
        column,
    }: {
        state: TableState<Order>;
        dispatch: Dispatch<TableSettingsAction<Order>>;
        column: ColumnInstance;
    }
) {
    const current = state.filterBy.find((filter) => filter.id === column.id);
    const currentFilter = current ? current.values : null;
    const options = allOptions.map((val) => ({ value: val, label: val }));
    const selected = currentFilter ? options.filter(({ value }) => currentFilter.includes(value)) : [];
    const filtering = selected.length > 0;
    const onChange = (selected: any, action: any) => {
        if (action && action.action === "select-option") {
            dispatch({
                type: ActionType.FILTER_ADD,
                id: column.id,
                value: action.option.value,
            });
        } else if (action.action === "remove-value") {
            dispatch({
                type: ActionType.FILTER_REMOVE,
                id: column.id,
                value: action.removedValue.value,
            });
        } else if (action.action === "clear") {
            dispatch({ type: ActionType.FILTER_CLEAR, id: column.id });
        }
    };
    return (
        <DropDownContainer
            title={column.id}
            renderButtonContent={renderFilterIcon(filtering)}
            renderContent={(disabled, reset) => (
                <Select
                    ref={(ref) => ref?.focus()}
                    id={`filter-${state.name}.${column.id}`}
                    inputId={`input-filter-${state.name}.${column.id}`}
                    isDisabled={disabled}
                    isMulti
                    value={selected}
                    name={"multi-select"}
                    options={options}
                    onChange={onChange}
                    placeholder={column.id}
                    onBlur={reset}
                />
            )}
        />
    );
}

export function renderSingleSelectFilter(
    allOptions: string[],
    i18nPrefix: string | null,
    {
        state,
        dispatch,
        column,
    }: {
        state: TableState<Order>;
        dispatch: Dispatch<TableSettingsAction<Order>>;
        column: ColumnInstance;
    }
) {
    const current = state.filterBy.find((filter) => filter.id === column.id);
    const currentFilter = current ? current.values : null;
    const options = allOptions.map((val) => ({ value: val, label: val }));
    const selected = currentFilter ? options.filter(({ value }) => currentFilter.includes(value)) : [];
    const filtering = selected.length > 0;
    const onChange = (selected: any, action: any) => {
        if (action && action.action === "select-option") {
            if (filtering) {
                dispatch({ type: ActionType.FILTER_CLEAR, id: column.id });
            }
            dispatch({
                type: ActionType.FILTER_ADD,
                id: column.id,
                value: action.option.value,
            });
        } else if (action.action === "remove-value") {
            dispatch({
                type: ActionType.FILTER_REMOVE,
                id: column.id,
                value: action.removedValue.value,
            });
        } else if (action.action === "clear") {
            dispatch({ type: ActionType.FILTER_CLEAR, id: column.id });
        }
    };
    return (
        <DropDownContainer
            title={column.id}
            renderButtonContent={renderFilterIcon(filtering)}
            renderContent={(disabled, reset) => (
                <Select
                    ref={(ref) => ref?.focus()}
                    id={`filter-${state.name}.${column.id}`}
                    inputId={`input-filter-${state.name}.${column.id}`}
                    isDisabled={disabled}
                    isMulti
                    value={selected}
                    name={"multi-select"}
                    options={options}
                    onChange={onChange}
                    placeholder={column.id}
                    onBlur={reset}
                />
            )}
        />
    );
}

const debouncedFilterReplace = debounce((dispatch, id, values) => {
    dispatch({ type: ActionType.FILTER_REPLACE, id, values });
}, 300);

export function renderILikeFilter({
    state,
    dispatch,
    column,
}: {
    state: TableState<Order>;
    dispatch: Dispatch<TableSettingsAction<Order>>;
    column: ColumnInstance;
}) {
    const current = state.filterBy.find((filter) => filter.id === column.id);
    const currentFilter = current ? current.values[0] : null;
    if (column.filterValue && column.filterValue !== currentFilter) {
        debouncedFilterReplace(dispatch, column.id, [column.filterValue]);
    } else if (!column.filterValue && currentFilter) {
        dispatch({ type: ActionType.FILTER_CLEAR, id: column.id });
    }
    return (
        <EuiFieldText
            id={`input-filter-${state.name}.${column.id}`}
            value={column.filterValue}
            onChange={(e) => {
                column.setFilter(e.target.value || undefined);
            }}
            placeholder={column.id}
        />
    );
}
