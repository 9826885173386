import {
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiMarkdownEditor,
    EuiMarkdownFormat,
    EuiSpacer,
} from "@elastic/eui";
import React, { useState } from "react";

function InlineMarkdownEditor({
    name,
    initialValue,
    readonly,
    onSave,
    onEdit,
}: {
    name: string;
    initialValue: string;
    readonly: boolean;
    onSave: (value: string) => void;
    onEdit: (value: string) => void;
}) {
    const [value, setValue] = useState(initialValue);
    const [_editing, _setEditing] = useState(false);
    const [messages] = useState([]);
    // const [ast, setAst] = useState(null);
    // const onParse = useCallback((err, { messages, ast }) => {
    //     setMessages(err ? [err] : messages);
    //     // @ts-ignore
    //     setAst(JSON.stringify(ast, null, 2));
    // }, []);

    const save = () => {
        _setEditing(false);
        onSave(value);
    };

    const toggleEditing = () => {
        if (readonly) {
            _setEditing(false);
        } else {
            _setEditing(!_editing);
        }
        // Todo: add name here
        onEdit(name);
    };

    const editor = (
        <>
            <EuiMarkdownEditor
                aria-label="Markdown capable editor"
                placeholder="Your markdown here..."
                value={value}
                onChange={setValue}
                height={400}
                // onParse={onParse}
                errors={messages}
                // initialViewMode="viewing"
            />
            <EuiSpacer size="s" />
            <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false} />
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="stop without save"
                                iconType="editorUndo"
                                iconSize="l"
                                color="danger"
                                onClick={() => {
                                    toggleEditing();
                                    setValue(initialValue);
                                }}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon aria-label="save and stop" iconType="save" iconSize="l" onClick={save} />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );

    return (
        <div>
            {!_editing && (
                <div onClick={toggleEditing} style={{ fontSize: "1.1em", marginTop: "-0.3em" }}>
                    <EuiMarkdownFormat>{initialValue}</EuiMarkdownFormat>
                </div>
            )}
            {_editing && editor}
        </div>
    );
}

export default InlineMarkdownEditor;
