import React from "react";

import { easyGoingStyle } from "../pages/pricelist-styles/EasyGoingStyle";
import { maryJaneStyle } from "../pages/pricelist-styles/MaryJaneStyle";
import { mississippiStyle } from "../pages/pricelist-styles/MississippiStyle";
import { missouriStyle } from "../pages/pricelist-styles/MissouriStyle";
import { ShopGroup } from "./types";

export interface ShopContextInterface {
    shopName: string;
    shopId: string;
    shopGroups: ShopGroup[];
    setShopName: (shopName: string) => void;
    setShopId: (shopId: string) => void;
    setShopGroups: (shopGroups: ShopGroup[]) => void;
}

// create the shop context with default selected shop mississippi
export const ShopContext = React.createContext<ShopContextInterface>({
    shopName: "Mississippi",
    shopId: "19149768-691c-40d8-a08e-fe900fd23bc0",
    shopGroups: [],
    setShopName: (Shop: string) => {},
    setShopId: (ShopId: string) => {},
    setShopGroups: (shopGroups: ShopGroup[]) => {},
});

export const getHorecaPricelistStyleForShop = (shopId: string) => {
    switch (shopId) {
        case "bbc0cfa7-e230-4338-817a-55190debdac0":
            return maryJaneStyle;
        case "a08a13e2-a31b-4b6d-b2b4-0491cb3d2227":
            return missouriStyle;
        case "ddd70853-5e9d-45ff-82b3-24c9d81d4e32":
            return easyGoingStyle;
        default:
            return mississippiStyle;
    }
};
