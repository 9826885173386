// import "../pages/Pricelist.scss";

import { EuiBottomBar, EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import React, { useState } from "react";
import Snow from "react-snow-effect";

export const BottomBar = () => {
    const [showBar, setShowBar] = useState(false);
    const [snowParticlesEnabled, setSnowParticlesEnabled] = useState(false);
    const [showRedBG, setShowRedBG] = useState(false);

    const button = (
        <div style={{ position: "absolute", opacity: "0" }}>
            <EuiButton onClick={() => setShowBar(!showBar)}>BottomBar</EuiButton>
        </div>
    );

    const snow = (
        <div>
            <Snow />
        </div>
    );

    const showRed = (
        <div>
            <img alt="background-red" className="redbg" src="/images/pricelist/backgroundred.jpg" />
        </div>
    );

    const bottomBar = (
        <EuiBottomBar>
            <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup gutterSize="s">
                        <EuiFlexItem grow={false}>
                            <EuiButton href="/" color="ghost" size="s" iconType="home">
                                Home
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton onClick={() => setShowRedBG(!showRedBG)} color="ghost" size="s" iconType="brush">
                                Change Background to {showRedBG ? "green" : "red"}
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton
                                onClick={() => setSnowParticlesEnabled(!snowParticlesEnabled)}
                                color="ghost"
                                size="s"
                                iconType="snowflake"
                                fill={snowParticlesEnabled}
                            >
                                Let it {snowParticlesEnabled ? "stop" : "snow"}!
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup gutterSize="s">
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty
                                onClick={() => setShowBar(!showBar)}
                                color="ghost"
                                size="s"
                                iconType="cross"
                            ></EuiButtonEmpty>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiBottomBar>
    );

    return (
        <div>
            {button}
            {showBar && bottomBar}
            {snowParticlesEnabled && snow}
            {showRedBG && showRed}
        </div>
    );
};
