import "./ResetForm.css";

import { EuiButton, EuiCallOut, EuiFieldText, EuiForm, EuiFormRow, EuiPanel, EuiSpacer } from "@elastic/eui";
import PropTypes from "prop-types";
import React, { Component } from "react";

import client from "../api/Client";
import { isEmpty } from "../utils/Utils";

class ResetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            errorMessage: "",
            loading: false,
            formValid: false,
        };
    }

    handleResetInternal = (e) => {
        const { token } = this.props;
        if (!this.formValid()) {
            this.setState({
                errorMessage: this.state.errorMessage + "Form not valid: all fields are required",
            });
            return;
        }
        this.setState({ loading: true });
        const data = { token: token, new_password: this.state.password };
        client
            .post("/v1/reset-password/", data)
            .then((res) => {
                this.setState({ loading: false });
                console.log("Redirecting");
                window.location.href = "/login";
            })
            .catch((res) => {
                this.setState({ loading: false });
                debugger;
                const data = res.response.data;

                if (
                    data.meta.code === 400 &&
                    data.response.hasOwnProperty("error") &&
                    data.response.error === "You can only access this endpoint when not logged in."
                ) {
                    // The user already has correct cookie stuff (probably from backend) so we redirect to the cookie based user profile
                    debugger;
                } else if (data.meta.code === 400) {
                    const errorResponse = JSON.stringify(data.response.errors);
                    this.setState({ errorMessage: errorResponse });
                    setTimeout(() => {
                        this.setState({ errorMessage: undefined });
                    }, 3000);
                } else {
                    this.setState({ errorMessage: `Unknown error occurred` });
                    setTimeout(() => {
                        this.setState({ errorMessage: undefined });
                    }, 3000);
                }
            });
    };

    formValid = () => {
        const { password } = this.state;
        return password.length >= 4;
    };

    handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            console.log("Enter key pressed; submitting form");
            this.handleResetInternal(event);
        }
    };

    render() {
        const { token, loading, errorMessage } = this.state;
        const formValid = this.formValid();

        return (
            <div className="reset-form">
                <EuiPanel>
                    <EuiForm
                        style={{ marginTop: "20px" }}
                        component="form"
                        size="large"
                        onSubmit={this.handleResetInternal}
                    >
                        {!isEmpty(token) && <div>loading</div>}
                        <div>
                            <EuiFormRow label="Reset your password">
                                <EuiFieldText
                                    name="password"
                                    autocomplete="password"
                                    required
                                    onChange={(event) =>
                                        this.setState({
                                            password: event.target.value,
                                            errorMessage: "",
                                        })
                                    }
                                    autoFocus={true}
                                    onKeyDown={this.handleKeyDown}
                                />
                            </EuiFormRow>
                            <EuiSpacer />
                            {errorMessage && (
                                <>
                                    <EuiCallOut title={errorMessage} color="danger" iconType="alert"></EuiCallOut>
                                </>
                            )}
                            <EuiSpacer />
                            <EuiButton
                                disabled={!formValid}
                                fluid
                                isLoading={loading}
                                onClick={this.handleResetInternal}
                            >
                                Reset
                            </EuiButton>
                        </div>
                    </EuiForm>
                </EuiPanel>
            </div>
        );
    }
}

export default ResetForm;

ResetForm.propTypes = {
    token: PropTypes.string.isRequired,
};
