import { EuiCallOut, EuiLink } from "@elastic/eui";
import PropTypes from "prop-types";
import React from "react";
import { Route } from "react-router-dom";

export default function ProtectedRoute({ path, user, render }) {
    /**
     * This provides the hook to restrict access based on memberships of the logged in user. For
     * now we will allow everyone access
     */
    if (user.isLoggedIn) {
        // We might need to check if the user.profile.roles has permissions to the path here: for now we show all URL's:
        return <Route path={path} render={render} />;
    } else {
        const message = (
            <EuiCallOut title="Login required" color="danger" iconType="alert">
                <p>
                    Please <EuiLink href="/login">Login</EuiLink>.
                </p>
            </EuiCallOut>
        );
        return <Route path={path} render={() => message} />;
    }
}

ProtectedRoute.propTypes = {
    path: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    render: PropTypes.func.isRequired,
};
