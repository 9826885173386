import { useRef } from "react";
import { useQueryClient } from "react-query";

import client from "../api/Client";
import API_URL, { WEBSOCKET_URL } from "../Constants";
import { PricelistQueryKey } from "./types";

export const CACHE_REFRESH_TIMEOUT_MS = 30000;

export const useGetLivePricelistUpdates = (shopId: string, queryKey: PricelistQueryKey) => {
    const isInitialized = useRef(false);
    const queryClient = useQueryClient();

    const connectPricelistToWebSocket = (shopId: string, queryKey: PricelistQueryKey) => {
        const socket = new WebSocket(`${WEBSOCKET_URL}?shopId=${shopId}&connectionType=shop`);

        socket.onopen = () => {
            console.log("Connection with websocket server established.", socket);
        };
        socket.onerror = () => {
            console.log("Error connecting to websocket server, falling back to polling.");
        };
        socket.onmessage = (event) => {
            console.log(`[message] Data received from server: ${event.data}`);
            console.log(JSON.parse(event.data));
            if (JSON.parse(event.data) === "invalidate_shop_cache") {
                queryClient.invalidateQueries(queryKey);
            }
        };
        socket.onclose = () => {
            console.log("Re-spawning websocket");
            connectPricelistToWebSocket(shopId, queryKey);
        };
        return socket;
    };

    const checkPricelistCacheDate = (shopId: string, queryKey: PricelistQueryKey) => {
        console.log("Checking cache date");
        client.get(`${API_URL}/v1/shops/cache-status/${shopId}`).then((result) => {
            const cacheDate = result.data.modified_at;
            const cacheKey = `pricelist-${queryKey}-${shopId}`;
            const storedCacheDate = localStorage.getItem(cacheKey);
            if (storedCacheDate !== cacheDate) {
                console.log("Fallback cache check found a difference!");
                localStorage.setItem(cacheKey, cacheDate);
                queryClient.invalidateQueries(queryKey);
            }
        });
    };

    const checkPricelistCache = () => {
        if (!isInitialized.current) {
            connectPricelistToWebSocket(shopId, queryKey);
            setInterval(() => {
                checkPricelistCacheDate(shopId, queryKey);
            }, CACHE_REFRESH_TIMEOUT_MS);
            isInitialized.current = true;
        }
    };

    return checkPricelistCache();
};
