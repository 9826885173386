import UserInputFormWizard from "components/inputForms/UserInputFormWizard";
import React, { useCallback, useContext, useEffect, useState } from "react";
// import { useIntl } from "react-intl";
import { setFlash } from "utils/Flash";
import { Form } from "utils/types";

import { catchErrorStatus, startForm } from "../api/Client";
import { ShopContext } from "../utils/Shop";

interface IProps {
    preselectedInput?: any;
    formKey: string;
    handleSubmit: (userInputs: any) => void;
    title?: string;
    goBack?: boolean;
    closeModal?: () => void;
}

export default function CreateForm(props: IProps) {
    // const intl = useIntl();
    const { preselectedInput, formKey, handleSubmit, title } = props;
    const [form, setForm] = useState<Form>({});
    const { stepUserInput, hasNext } = form;
    const { shopId } = useContext(ShopContext);
    console.log("step user input", stepUserInput);

    const submit = useCallback(
        (userInputs: {}[]) => {
            // if (preselectedInput.product) {
            // Todo: decide if we want to implement pre-selections and design a way to do it generic
            //     userInputs = [{ preselectedInput }, ...userInputs];
            // }
            let promise = startForm(formKey, userInputs, shopId).then(
                (form) => {
                    handleSubmit(form);
                    setFlash(title ? `${title} gecreërd` : "Form afgehandeld");
                },
                (e) => {
                    throw e;
                }
            );

            return catchErrorStatus(promise, 503, (json: any) => {
                setFlash("Probleem", "error");
                // redirect("/tickets");
            });
        },
        [formKey, handleSubmit, title, shopId]
    );

    useEffect(() => {
        if (formKey) {
            catchErrorStatus(submit([]), 510, (json: any) => {
                setForm({
                    stepUserInput: json.form,
                    hasNext: json.hasNext ?? false,
                });
            });
        }
    }, [preselectedInput, formKey, submit]);

    return (
        <div>
            {stepUserInput && (
                <UserInputFormWizard
                    stepUserInput={stepUserInput}
                    validSubmit={submit}
                    cancel={() => {
                        props.goBack ? console.log("Cancel") : props.closeModal!();
                    }}
                    hasNext={hasNext ?? false}
                    goBack={props.goBack}
                />
            )}
        </div>
    );
}
