import {
    AcceptField,
    BoolField,
    DateField,
    LabelField,
    ListField,
    LongTextField,
    NestField,
    NumField,
    OptGroupField,
    RadioField,
    SelectField,
    SummaryField,
    TextField,
} from "lib/uniforms-surfnet/src";
import { Context, GuaranteedProps } from "uniforms";
import { AutoField, HiddenField } from "uniforms-unstyled";

import MarkdownTextField from "./src/MarkdownTextField";

export function autoFieldFunction(props: GuaranteedProps<unknown> & Record<string, any>, uniforms: Context<unknown>) {
    const { allowedValues, checkboxes, fieldType, field } = props;
    const { format } = field;

    switch (fieldType) {
        case Number:
            // If you need custom field for numeric resource types add a switch here
            break;
        case Object:
            switch (format) {
                case "optGroup":
                    return OptGroupField;
            }
            break;
        case String:
            switch (format) {
                // case "productId":
                //     return ProductField;
                // case "organisationId":
                //     return OrganisationField;
                case "hidden":
                    return HiddenField;
                case "long":
                    return LongTextField;
                case "markdown":
                    return MarkdownTextField;
                case "label":
                    return LabelField;
                case "summary":
                    return SummaryField;
                case "accept":
                    return AcceptField;
            }
            break;
    }

    if (allowedValues && format !== "accept") {
        if (checkboxes && fieldType !== Array) {
            return RadioField;
        } else {
            return SelectField;
        }
    } else {
        switch (fieldType) {
            case Array:
                return ListField;
            case Boolean:
                return BoolField;
            case Date:
                return DateField;
            case Number:
                return NumField;
            case Object:
                return NestField;
            case String:
                return TextField;
        }
    }

    // Todo React upgrade: fix uniform types
    // @ts-ignore
    return AutoField.defaultComponentDetector(props, uniforms);
}
