import React from "react";

import { OrderLine } from "../utils/types";
import { formatMoney } from "../utils/Utils";

export function OrderInfo({ orderLines }: { orderLines: OrderLine[] }) {
    const renderInfo = orderLines.map((item: OrderLine) => (
        <tr>
            <td>{item.quantity}x</td>
            <td width={200}>
                <i>{item.description.replace("piece", "")}</i>{" "}
                <strong>{item.kind_name ? item.kind_name : item.product_name}</strong>
            </td>
            <td style={{ textAlign: "right" }}>{formatMoney(item.quantity * item.price)}</td>
        </tr>
    ));
    return <table>{renderInfo}</table>;
}
