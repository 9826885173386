import { css } from "@emotion/react";

// import { COLORS } from "../../stylesheets/emotion/vars";
// import image from "../images/mississippi/mississippi-pattern.png";

export const deSteegStyle = css`
    body {
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .bannerAll {
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .bannerBack {
        width: 17.5%;
        margin-top: 0.55vw;
        position: absolute;
        margin-left: 53.6%;
    }

    .bannerFront {
        width: 19%;
        margin-left: 27.3%;
        margin-top: 0.68vw;
        position: absolute;
    }

    .bannerLogo {
        width: 10vw;
        position: relative;
        margin-top: 0.1vw;
        margin-left: 112.2%;
        z-index: 1000;
        filter: drop-shadow(0px 5px 5px #000);
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg) scale(0.5);
            opacity: 0.15;
        }
        5% {
            transform: rotate(180deg) scale(0.5);
            opacity: 0.3;
        }
        10% {
            transform: rotate(360deg) scale(0.5);
            opacity: 0.15;
        }
        100% {
            transform: rotate(360deg) scale(0.5);
            opacity: 0.15;
        }
    }

    @keyframes opacity {
        0% {
            opacity: 0.05;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 0.05;
        }
    }

    .menukaart {
        width: 100vw;
        height: 100vw;
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .menulinks {
        width: 45vw;
        height: 45vw;
        margin-left: 2.5vw;
        margin-top: 2vw;
        position: relative;
    }

    .menurechts {
        width: 45vw;
        height: 45vw;
        margin-left: 3.5vw;
        margin-top: 2vw;
        position: relative;
    }

    .backgroundShadow {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 15px;
    }

    .info {
        display: flex;
        flex-direction: row-reverse;
    }

    .row {
        display: flex;
        padding-top: 0.1vw;
        background-color: rgba(255, 242, 0, 0.11);
    }

    .row:nth-child(even) {
        background-color: rgba(31, 72, 31, 0.5);
    }

    .row:nth-last-child(1) {
        border-bottom-left-radius: 1vw;
        border-bottom-right-radius: 1vw;
    }

    .rowContent {
        padding: 0.3vw 0;
        display: flex;
    }

    .productkind {
        color: white;
        font-size: 0.9vw;
        margin-top: 0.3vw;
        width: 3vw;
        margin-left: 2vw;
    }

    .banner {
        width: 100%;
        margin-left: 8vw;
        margin-top: 1vw;
        position: relative;
    }

    .pagebackground {
        background-color: rgba(57, 132, 37, 0.9);
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
    }

    .pattern {
        background-image: url("https://coffeeshopdesteeg.nl/wp-content/uploads/2020/10/Steeg_Header_small.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        height: 1080px;
        width: 3840px;
        top: 0%;
        opacity: 0.1;
        animation: scroll 30s ease-in-out infinite alternate;
        filter: blur(2px);
    }

    @keyframes scroll {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-1920px, 0, 0); /* The image width */
        }
    }

    .redbg {
        position: absolute;
    }

    @font-face {
        font-family: "cordia";
        font-weight: normal;
        font-style: normal;
    }

    .logo {
        position: absolute;
        margin-left: 20%;
        margin-top: -6.5%;
        width: 40%;
        opacity: 0;
    }

    .marginTopTitle {
        margin-top: -4vw;
    }

    .title {
        margin-left: 1.5vw;
        font-size: 1.6vw;
        font-weight: 600;
        margin-top: 1vw;
        margin-bottom: 0.5vw;
        position: relative;
        width: 20.3vw;
        color: white;
        text-shadow: 2px 2px #000000;
    }

    .titleNotAtTop {
        margin-left: 1.5vw;
        font-size: 1.6vw;
        font-weight: 600;
        margin-top: 0.6vw;
        margin-bottom: 0.5vw;
        position: relative;
        width: 20vw;
        color: white;
        text-shadow: 2px 2px #000000;
    }

    .particles {
        position: absolute;
        margin-left: 50vw;
        width: 300px;
    }

    .btn {
        position: absolute;
    }

    .kindBadge {
        margin: auto;
        padding: 0 0.75vw;
    }

    .nameStar {
        display: flex;
    }

    .newBadge {
    }

    .nameprice {
        font-size: 1.1vw;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
    }

    .description {
        font-size: 0.75vw;
        color: white;
        margin: 0 auto;
    }

    .kind {
        font-size: 0.5vw;
        position: relative;
        margin-left: -2vw;
        margin-top: 0.5vw;
        width: 5vw;
        color: white;
    }

    .priceContainer {
        position: relative;
        left: 1.4vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .price {
        font-size: 1.05vw;
        width: 4.45vw;
        color: white;
    }

    .priceHeader {
        position: relative;
        left: 1.35vw;
        margin-left: -0.55vw;
        font-size: 1vw;
        margin-top: -1.5vw;
        width: 4.8vw;
        color: white;
    }

    .filler {
        flex-grow: 1;
    }

    .svg-inline--fa {
        margin-left: 20px;
        margin-top: 8px;
        font-size: 30px;
    }
`;
