import {
    EuiBadge,
    EuiButtonEmpty,
    EuiButtonIcon,
    EuiComboBox,
    EuiDescriptionListDescription,
    EuiDescriptionListTitle,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHealth,
    EuiHighlight,
    EuiIcon,
    EuiToolTip,
} from "@elastic/eui";
import { parseInt } from "lodash";
import React, { useEffect, useState } from "react";

import { Flavor, KindRelation, KindRelationWithIndex, MutationType, Strain, Tag } from "../../utils/types";

function ProductInfoDropdownEditor({
    type,
    allData, // FlavorID
    options,
    name,
    initialValue = null, //Kind_to_flavor_ID
    onSave,
    onEdit,
    isLoading,
    readonly = false,
    isAddNew = false,
    hasColors = false,
    setEditing,
    editing,
    maxRelations = 5,
}: {
    type: string;
    allData: Flavor[] | Strain[] | Tag[];
    options: [{ label: string; color?: string }];
    name: string;
    initialValue?: KindRelationWithIndex | null;
    readonly?: boolean;
    onSave: (relation: KindRelation, mutationType: MutationType) => void;
    onEdit: () => void;
    isLoading: boolean;
    isAddNew?: boolean;
    hasColors?: boolean;
    setEditing: (value: boolean) => void;
    editing: boolean;
    maxRelations: number;
}) {
    const [value, setValue] = useState<KindRelationWithIndex | null>(initialValue);
    const [selectedOption, setSelectedOption] = useState(isAddNew ? [] : [options[0]]);
    useEffect(() => {
        if (options.length > 1 && initialValue) {
            let index = options.findIndex((object) => object.label === initialValue.name);
            setSelectedOption([options[index]]);
        }
    }, [options, value, editing, initialValue]);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const saveHandler = () => {
        stopEditing();

        //Matches the selected option of the combobox with the addData equivalent
        const found_item = allData.find((obj) => obj.name === selectedOption[0].label);
        if (type === "flavor") {
            if (found_item && initialValue) {
                //if add new item initialValue will be null
                onSave(
                    {
                        id: initialValue.id,
                        index: initialValue.index,
                        name: found_item.name,
                        // @ts-ignore
                        color: found_item.color, // it detects it as a Strain
                        flavor_id: found_item.id,
                        kind_id: "",
                    },
                    MutationType.Edited
                );
            } else if (found_item) {
                onSave(
                    {
                        id: "",
                        index: 0,
                        name: found_item.name,
                        // @ts-ignore
                        color: found_item.color,
                        flavor_id: found_item.id,
                        kind_id: "",
                    },
                    MutationType.Added
                );
            }
        } else if (type === "strain") {
            if (found_item && initialValue) {
                onSave(
                    {
                        id: initialValue.id,
                        index: initialValue.index,
                        name: found_item.name,
                        strain_id: found_item.id,
                        kind_id: "",
                    },
                    MutationType.Edited
                );
            } else if (found_item) {
                onSave(
                    {
                        id: "",
                        index: 0,
                        name: found_item.name,
                        strain_id: found_item.id,
                        kind_id: "",
                    },
                    MutationType.Added
                );
            }
        } else if (type === "tag") {
            if (found_item && initialValue) {
                onSave(
                    {
                        id: initialValue.id,
                        index: initialValue.index,
                        name: found_item.name,
                        tag_id: found_item.id,
                        kind_id: "",
                        amount: value!.amount!,
                    },
                    MutationType.Edited
                );
            } else if (found_item) {
                onSave(
                    {
                        id: "",
                        index: 0,
                        name: found_item.name,
                        tag_id: found_item.id,
                        kind_id: "",
                        amount: value!.amount ? value!.amount : 0,
                    },
                    MutationType.Added
                );
            }
        }
    };

    const deleteHandler = () => {
        stopEditing();

        if (type === "flavor" && initialValue) {
            onSave(
                {
                    id: initialValue.id,
                    index: initialValue.index,
                    name: "",
                    color: "",
                    flavor_id: "",
                    kind_id: "",
                },
                MutationType.Deleted
            );
        } else if (type === "strain" && initialValue) {
            onSave(
                {
                    id: initialValue.id,
                    index: initialValue.index,
                    name: "",
                    strain_id: "",
                    kind_id: "",
                },
                MutationType.Deleted
            );
        } else if (type === "tag" && initialValue) {
            onSave(
                {
                    id: initialValue.id,
                    index: initialValue.index,
                    name: "",
                    tag_id: "",
                    amount: 0,
                    kind_id: "",
                },
                MutationType.Deleted
            );
        }
    };

    const startEditing = () => {
        if (readonly || editing) return;

        if (!initialValue && type === "tag") {
            setValue({ amount: 0, id: "", index: 0, name: "" });
        }

        setEditing(true);
        onEdit();
    };

    const stopEditing = () => {
        setEditing(false);
    };

    const renderOption = (option: { label: string; color?: string }, searchValue: string) => {
        const { color, label } = option;
        return (
            <EuiHealth color={color}>
                <span>
                    <EuiHighlight search={searchValue}>{label}</EuiHighlight>
                </span>
            </EuiHealth>
        );
    };

    const onChange = (selectedOption: any) => {
        setSelectedOption(selectedOption);
    };

    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>, value: KindRelationWithIndex) => {
        const newValue = { ...value };
        const newAmount = parseInt(e.target.value);
        if (newAmount > 100) {
            newValue.amount = 100;
        } else if (newAmount < 0) {
            newValue.amount = 0;
        } else {
            newValue.amount = newAmount;
        }
        setValue(newValue);
    };

    const editor = (
        <>
            <EuiFlexGroup gutterSize={"none"}>
                <EuiFlexItem grow={7}>
                    <EuiComboBox
                        isLoading={isLoading}
                        aria-label="Accessible screen reader label"
                        placeholder="Select a single option"
                        singleSelection={{ asPlainText: false }}
                        options={options}
                        selectedOptions={selectedOption}
                        onChange={onChange}
                        style={{ maxWidth: 300 }}
                        renderOption={hasColors ? renderOption : undefined}
                    />
                </EuiFlexItem>
                {type === "tag" && (
                    <EuiFlexItem grow={3} style={{ minWidth: 65 }}>
                        <EuiFieldNumber
                            value={value ? value.amount : 0}
                            min={0}
                            max={100}
                            onChange={(e) => onChangeNumber(e, value!)}
                        />
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>

            <EuiFlexGroup justifyContent="flexStart">
                {!isAddNew ? (
                    <EuiFlexItem grow={2} style={{ paddingInline: 5, paddingBlock: 2 }}>
                        <EuiButtonIcon
                            aria-label="delete"
                            display="base"
                            iconType="trash"
                            iconSize="m"
                            color="danger"
                            onClick={deleteHandler}
                        />
                    </EuiFlexItem>
                ) : (
                    <EuiFlexItem grow={2}> </EuiFlexItem>
                )}
                <EuiFlexItem grow={3}>
                    <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="stop without save"
                                iconType="editorUndo"
                                iconSize="l"
                                color="danger"
                                onClick={() => {
                                    stopEditing();
                                    setValue(initialValue);
                                }}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="save and stop"
                                iconType="save"
                                iconSize="l"
                                onClick={saveHandler}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );

    const renderDefault = () => {
        return (
            <div onClick={startEditing}>
                <EuiFlexGroup alignItems="center">
                    <EuiFlexItem grow={editing}>
                        <EuiDescriptionListTitle>{name}</EuiDescriptionListTitle>
                        <EuiDescriptionListDescription>
                            {!editing && (
                                <EuiFlexGroup gutterSize="xs">
                                    <EuiFlexItem>
                                        <EuiBadge
                                            style={{
                                                paddingInline: 10,
                                                paddingBlock: 2.5,
                                                fontWeight: "bold",
                                                fontSize: 14,
                                                cursor: "pointer",
                                            }}
                                            //@ts-ignore
                                            color={"color" in initialValue! ? initialValue.color : "#e0e0e0"}
                                        >
                                            {initialValue!.name}
                                        </EuiBadge>
                                    </EuiFlexItem>
                                    {type === "tag" && (
                                        <EuiFlexItem>
                                            <EuiBadge
                                                style={{
                                                    paddingInline: 10,
                                                    paddingBlock: 2.5,
                                                    fontWeight: "bold",
                                                    fontSize: 14,
                                                    cursor: "pointer",
                                                }}
                                                //@ts-ignore
                                                color={"color" in initialValue! ? initialValue.color : "#505050"}
                                            >
                                                {"amount" in initialValue! ? initialValue?.amount : 0}
                                            </EuiBadge>
                                        </EuiFlexItem>
                                    )}
                                </EuiFlexGroup>
                            )}
                            {editing && editor}
                        </EuiDescriptionListDescription>
                    </EuiFlexItem>
                    {!editing && !readonly && (
                        <EuiFlexItem>
                            <EuiIcon color="primary" type="pencil" />
                        </EuiFlexItem>
                    )}
                </EuiFlexGroup>
            </div>
        );
    };

    const renderNew = () => {
        return (
            <div onClick={startEditing}>
                <EuiFlexGroup alignItems="center">
                    <EuiFlexItem grow={true}>
                        {editing && <EuiDescriptionListTitle>{name}</EuiDescriptionListTitle>}
                        <EuiDescriptionListDescription>
                            {!editing &&
                                (!readonly ? (
                                    <EuiButtonEmpty size="m" iconType="plus" isDisabled={false}>
                                        Add {type}
                                    </EuiButtonEmpty>
                                ) : (
                                    <EuiToolTip position="right" content={`${maxRelations} ${type}s max`}>
                                        <EuiButtonEmpty size="m" iconType="plus" isDisabled={true}>
                                            Add {type}
                                        </EuiButtonEmpty>
                                    </EuiToolTip>
                                ))}
                            {editing && editor}
                        </EuiDescriptionListDescription>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </div>
        );
    };

    return isAddNew ? renderNew() : renderDefault();
}

export default ProductInfoDropdownEditor;
