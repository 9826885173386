import { EuiPage, EuiPanel, EuiTitle } from "@elastic/eui";
import React from "react";

import ListS3Images from "../components/ListS3Images";
import UploadImageToS3WithNativeSdk from "../components/UploadImageToS3WithNativeSdk";

function ImageLibrary() {
    return (
        <EuiPage>
            <EuiPanel>
                <EuiTitle size="l">
                    <h1>Image bibliotheek</h1>
                </EuiTitle>

                <EuiTitle>
                    <h2>Upload</h2>
                </EuiTitle>

                <h2>Images</h2>
                <ListS3Images />
                <UploadImageToS3WithNativeSdk />
            </EuiPanel>
        </EuiPage>
    );
}

export default ImageLibrary;
