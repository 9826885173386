import { sortedUniq } from "lodash";
import chunk from "lodash/chunk";
import isNull from "lodash/isNull";
import last from "lodash/last";
import omitBy from "lodash/omitBy";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import {
    Cell,
    Column,
    FilterAndSort,
    LocalTableSettings,
    Row,
    RowPropGetter,
    SessionTableSettings,
    TableSettings,
    TableState,
} from "react-table";
import { useQueryParam } from "use-query-params";

import { get_tables } from "../../api/Client";
import { CommaSeparatedNumericArrayParam, CommaSeparatedStringArrayParam } from "../../utils/QueryParameters";
import { ShopContext } from "../../utils/Shop";
import { Order } from "../../utils/types";
import { formatMoney } from "../../utils/Utils";
import ActionContainer from "../ActionContainer";
import { OrderInfo } from "../OrderInfo";
import { renderIdCell } from "./cellRenderers";
import { renderILikeFilter, renderMultiSelectFilter } from "./filterRenderers";
import { PricelistTable, isLocalTableSettings } from "./PricelistTable";

// export function initialOrdersFilterAndSort(showTasks: boolean, statuses: string[]) {
export function initialOrdersFilterAndSort(shopId: string, status: string) {
    const initialFilterBy: any[] = [
        // { id: "status", values: [status] },
        // { id: "shop_id", values: [shopId] },
    ];
    const initialSortBy = [{ id: "created_at", desc: true }];
    return { filterBy: initialFilterBy, sortBy: initialSortBy };
}

export function initialOrdersTableSettings(
    name: string,
    filterAndSort: FilterAndSort,
    hiddenColumns: string[],
    optional?: Partial<TableSettings<Order>>
): TableSettings<Order> {
    const defaults = {
        showAdvancedSearch: false,
        showSettings: false,
        showPaginator: true,
        refresh: false,
        delay: 3000,
        pageSize: 25,
        pageIndex: 0,
        minimized: false,
    };
    let rest = optional ? Object.assign(defaults, optional) : defaults;
    return {
        ...filterAndSort,
        name: name,
        hiddenColumns: hiddenColumns,
        ...rest,
    };
}

interface OrdersTableProps {
    initialTableSettings: TableSettings<Order>;
    renderActions: (subscription: Order) => JSX.Element;
}

export function OrdersTable({ initialTableSettings, renderActions }: OrdersTableProps) {
    const { name } = initialTableSettings;
    const { shopId } = useContext(ShopContext);

    console.log(name);
    const endpoint =
        name === "table.orders-page.compleet" ? `orders/shop/${shopId}/complete` : `orders/shop/${shopId}/pending`;
    const queryNameSpace = last(name.split("."));
    const [pageQ, setPageQ] = useQueryParam(queryNameSpace + "Page", CommaSeparatedNumericArrayParam);
    const [sortQ, setSortQ] = useQueryParam(queryNameSpace + "Sort", CommaSeparatedStringArrayParam);
    const [filterQ, setFilterQ] = useQueryParam(queryNameSpace + "Filter", CommaSeparatedStringArrayParam);

    const { data } = useQuery("tables", () => get_tables().then());

    const initialize = useMemo(
        () =>
            function (current: TableSettings<Order>): TableState<Order> {
                // First get LocalState from LocalStorage
                const settingsFromLocalStorage: LocalTableSettings<Order> | {} = JSON.parse(
                    localStorage.getItem(`table-settings:${current.name}`) || "{}"
                );
                // Then get settings from SessionStorage
                const settingsFromSessionStorage: SessionTableSettings | {} = JSON.parse(
                    sessionStorage.getItem(`table-settings:${current.name}`) || "{}"
                );
                // Then get settings from URL
                let pageIndex: number | null = null;
                let pageSize: number | null = null;
                let sortBy: { id: string; desc: boolean }[] | null = null;
                let filterBy: { id: string; values: string[] }[] | null = null;
                try {
                    if (pageQ) {
                        pageIndex = pageQ[0];
                        pageSize = pageQ[1];
                    }
                    if (sortQ) {
                        sortBy = chunk(sortQ, 2).map(([id, desc]) => ({
                            id,
                            desc: desc === "desc",
                        }));
                    }
                    if (filterQ) {
                        filterBy = chunk(filterQ, 2).map(([id, values]) => ({
                            id: id,
                            values: values ? values.split("-") : [],
                        }));
                    }
                } catch (err) {
                    console.log(err);
                }
                const settingsFromURL = omitBy(
                    {
                        pageIndex: pageIndex,
                        pageSize: pageSize,
                        sortBy: sortBy,
                        filterBy: filterBy,
                    },
                    isNull
                );
                // merge everything and return as new table state. Each object from left to right can override keys from the previous object.
                return Object.assign(
                    { loading: true, pageCount: 0, data: [] },
                    current,
                    settingsFromLocalStorage,
                    settingsFromSessionStorage,
                    settingsFromURL
                );
            },
        [filterQ, pageQ, sortQ]
    );

    const extraRowPropGetter: RowPropGetter<Order> = useCallback((props, { row }) => {
        return {
            ...props,
            id: row.values.subscription_id,
            className: `${row.values.status}`,
        };
    }, []);

    const renderSubComponent = useCallback(({ row }: { row: Row<Order> }) => {
        return <b>Yo</b>;
    }, []);

    const initialState = useMemo(() => initialize(initialTableSettings), [initialTableSettings, initialize]);
    const columns: Column<Order>[] = useMemo(
        () =>
            [
                {
                    Header: "id",
                    accessor: "id",
                    Filter: renderILikeFilter,
                    Cell: renderIdCell,
                },
                {
                    Header: "Order nr",
                    accessor: "customer_order_id",
                    Filter: renderILikeFilter,
                    disableFilters: false,
                },
                {
                    Header: "Status",
                    accessor: "status",
                    id: "status",
                    disableFilters: true,
                },
                {
                    Header: "Created",
                    id: "created_at",
                    accessor: (d: { created_at: string }) =>
                        DateTime.fromISO(d.created_at)
                            .plus({ hours: 2 })
                            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
                    disableFilters: true,
                },
                {
                    Header: "Completed at",
                    id: "completed_at",
                    accessor: (d: { completed_at: string }) =>
                        DateTime.fromISO(d.completed_at)
                            .plus({ hours: 2 })
                            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
                    disableFilters: true,
                },
                {
                    Header: "Table",
                    accessor: "table_name",
                    disableFilters: false,
                    disableSortBy: true,
                    Filter: renderMultiSelectFilter.bind(null, sortedUniq(data?.map((p: any) => p.name).sort()), null),
                },
                {
                    Header: "Info",
                    accessor: (order) => {
                        return <OrderInfo orderLines={order.order_info} />;
                    },
                    disableFilters: true,
                    disableSortBy: true,
                },
                {
                    Header: "Totaal",
                    id: "total",
                    Filter: renderILikeFilter,
                    accessor: (d: { total: number }) => <div>{formatMoney(d.total)}</div>,
                    disableFilters: false,
                },

                {
                    Header: "",
                    id: "actions",
                    accessor: (originalRow: Order, index: number) => originalRow,
                    Cell: ({ cell }: { cell: Cell }) => (
                        <ActionContainer
                            title={"Actions"}
                            renderButtonContent={(active) => {
                                const classes = ["dropdown-button-content", active ? "active" : ""].join(" ");
                                return (
                                    <span className={classes}>
                                        <i className={"fa fa-bars"} />
                                    </span>
                                );
                            }}
                            renderContent={(disabled) => renderActions(cell.value)}
                        />
                    ),
                    disableFilters: true,
                    disableSortBy: true,
                },
            ] as Column<Order>[],
        [renderActions, data]
        // [organisations, products]
    );

    const persistSettings = useCallback(
        (settings: LocalTableSettings<Order> | SessionTableSettings) => {
            if (isLocalTableSettings(settings)) {
                localStorage.setItem(`table-settings:${name}`, JSON.stringify(settings));
            } else {
                sessionStorage.setItem(`table-settings:${name}`, JSON.stringify(settings));
                setPageQ([settings.pageIndex, settings.pageSize]);
                setSortQ(settings.sortBy.map(({ id, desc }) => [id, desc ? "desc" : "asc"]).flat());
                setFilterQ(settings.filterBy.map(({ id, values }) => [id, values.join("-")]).flat());
            }
        },
        [name, setFilterQ, setPageQ, setSortQ]
    );

    return (
        <div key={name}>
            <section className="orders-table" id={`${name}-Orders`}>
                <PricelistTable<Order>
                    columns={columns}
                    initialState={initialState as TableState<Order>}
                    persistSettings={persistSettings}
                    endpoint={endpoint}
                    initialTableSettings={initialTableSettings}
                    extraRowPropGetter={extraRowPropGetter}
                    renderSubComponent={renderSubComponent}
                    excludeInFilter={["shop_id"]}
                    advancedSearch={true} /* Dream on? Or might be easy possible with algol! */
                />
            </section>
        </div>
    );
}
