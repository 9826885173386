import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiText, EuiToolTip } from "@elastic/eui";
import React, { useState } from "react";
import { useQuery } from "react-query";

import { get_resource_is_deletable } from "../api/Client";
import { Action, ResourceType, TableItem } from "../utils/types";

export const DropDownActionsWaitDelete = ({
    type,
    object,
    options,
    deleteOption,
}: {
    type: ResourceType;
    object: TableItem;
    options: Action[];
    deleteOption: any;
}) => {
    const [disabled, setDisabled] = useState(true);
    const { isLoading } = useQuery(
        [`${type}s`, object.value.id],
        () => get_resource_is_deletable(`${type}s`, object.value.id),
        {
            onSuccess: (data) => {
                setDisabled(!data.is_deletable);
            },
        }
    );

    const DeleteButton = () => {
        return (
            <EuiButtonEmpty
                iconType="trash"
                color="text"
                isLoading={isLoading}
                onClick={deleteOption.action}
                disabled={disabled}
                style={{ paddingRight: "5vw" }}
            >
                {deleteOption.label}
            </EuiButtonEmpty>
        );
    };

    return (
        <EuiFlexGroup alignItems="flexStart" direction="column" gutterSize={"xs"}>
            <EuiFlexItem grow={false}>
                <EuiText style={{ paddingRight: "10vw" }}>
                    <b>Actions</b>
                </EuiText>
                <EuiHorizontalRule margin={"xs"} />
            </EuiFlexItem>
            {options.map((item) => (
                <EuiFlexItem grow={false} key={item.id}>
                    <EuiButtonEmpty
                        style={{ paddingRight: "5vw" }}
                        iconType={item.euiIcon}
                        color="text"
                        onClick={item.action}
                    >
                        {item.label}
                    </EuiButtonEmpty>
                </EuiFlexItem>
            ))}
            <EuiFlexItem grow={false}>
                {disabled ? (
                    <EuiToolTip
                        position="left"
                        title="Delete not available."
                        content="Category is in use."
                        children={<DeleteButton />}
                    />
                ) : (
                    <DeleteButton />
                )}
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
