/*
 * Copyright 2019-2020 SURF.
 *
 */

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPage, EuiPanel, EuiSpacer } from "@elastic/eui";
import { intl } from "locale/i18n";
import debounce from "lodash/debounce";
import { DateTime } from "luxon";
import React from "react";
import { FormattedMessage, WrappedComponentProps } from "react-intl";
import { Filter, ProductForEditor, ProductForEditorEnriched, ProductType, SortOption } from "utils/types";
import { isEmpty, stop } from "utils/Utils";

import client, { get_all_products_generic, get_product_by_id_generic } from "../api/Client";
import InlineMarkdownEditor from "../components/editors/InlineMarkdownEditor";
import InlineTextEditor from "../components/editors/InlineTextEditor";
import ProductFilter from "../components/ProductFilter";
import { tableProducts } from "./ProductEditorStyling";

function getProductType(product: ProductForEditor) {
    if (product?.c) {
        return ProductType.C;
    }
    if (product?.h) {
        return ProductType.H;
    }
    if (product?.i) {
        return ProductType.I;
    }
    if (product?.s) {
        return ProductType.S;
    }
}

function getProductState(product: ProductForEditor) {
    if (isEmpty(product.name)) {
        return "text_problem";
    }
    if (isEmpty(product.description_nl)) {
        return "text_problem";
    }
    if (isEmpty(product.description_en)) {
        return "text_problem";
    }
    if (product.images_amount === 0) {
        return "text_complete";
    }
    if (product.images_amount > 0) {
        return "text_and_picture_complete";
    }
    // Not really needed if data is ok
    return "text_complete";
}

type Column = "name" | "product_type" | "description_nl" | "description_en" | "modified_at";

interface IProps extends WrappedComponentProps {}

interface FilterAttributes {
    state: Filter[];
}

interface IState {
    loading: boolean;
    products: ProductForEditorEnriched[];
    query: string;
    searchResults: ProductForEditorEnriched[];
    sortOrder: SortOption<Column>;
    filterAttributes: FilterAttributes;
    theme: string;
    editModeActive: boolean;
    editColumnName: string;
    editId: string;
}

class QuickEditor extends React.PureComponent<IProps, IState> {
    state: IState = {
        loading: true,
        products: [],
        theme: localStorage.getItem("darkMode") || false ? "dark" : "light",
        query: "",
        searchResults: [],
        sortOrder: { name: "name", descending: false },
        filterAttributes: {
            state: Object.values(ProductType)
                .filter((s) => s)
                .map((state) => ({
                    name: state ?? "",
                    // selected: state === ServiceTicketProcessState.OPEN,
                    selected: false,
                    count: 0,
                })),
        },
        editModeActive: false,
        editColumnName: "",
        editId: "",
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        console.log("Fetching products");
        get_all_products_generic("kinds", "")
            .then((res: ProductForEditor[]) => {
                // Todo: can we cast the result to ProductForEditorEnriched?
                const enrichedRes = res.map((r) => {
                    return {
                        product_type: getProductType(r),
                        product_state: getProductState(r),
                        ...r,
                    };
                });
                this.setState({
                    // @ts-ignore
                    products: enrichedRes,
                    loading: false,
                });
            })
            .catch((err) => {
                throw err;
            });
    }

    setFilter = (filterName: "state") => (item: Filter) => {
        const currentFilterAttributes = this.state.filterAttributes;
        let modifiedAttributes: Partial<FilterAttributes> = {};
        modifiedAttributes[filterName] = currentFilterAttributes[filterName].map((attr) => {
            if (attr.name === item.name) {
                attr.selected = !attr.selected;
            }
            return attr;
        });
        this.setState({
            filterAttributes: { ...currentFilterAttributes, ...modifiedAttributes },
        });
    };

    setFilterList = (filterName: "state") => (item: Filter[]) => {
        const currentFilterAttributes = this.state.filterAttributes;
        const incomingFilterNames = item.map((f) => f.name);
        let modifiedAttributes: Partial<FilterAttributes> = {};
        modifiedAttributes[filterName] = currentFilterAttributes[filterName].map((attr) => {
            attr.selected = incomingFilterNames.includes(attr.name);
            return attr;
        });
        this.setState({
            filterAttributes: { ...currentFilterAttributes, ...modifiedAttributes },
        });
    };

    singleSelectFilter = (filterName: "state") => (e: React.MouseEvent<HTMLElement>, item: Filter) => {
        stop(e);
        const currentFilterAttributes = this.state.filterAttributes;
        let modifiedAttributes: Partial<FilterAttributes> = {};
        modifiedAttributes[filterName] = currentFilterAttributes[filterName].map((attr) => {
            if (attr.name !== item.name && attr.selected) {
                attr.selected = false;
            } else if (attr.name === item.name && !attr.selected) {
                attr.selected = true;
            }
            return attr;
        });
        this.setState({
            filterAttributes: { ...currentFilterAttributes, ...modifiedAttributes },
        });
    };

    selectAll = (filterName: "state") => (e: React.MouseEvent<HTMLElement>) => {
        stop(e);
        const currentFilterAttributes = this.state.filterAttributes;
        let modifiedAttributes: Partial<FilterAttributes> = {};
        modifiedAttributes[filterName] = currentFilterAttributes[filterName].map((attr) => {
            if (!attr.selected) {
                attr.selected = true;
            }
            return attr;
        });
        this.setState({
            filterAttributes: { ...currentFilterAttributes, ...modifiedAttributes },
        });
    };

    filter = (unfiltered: ProductForEditorEnriched[]) => {
        const { state } = this.state.filterAttributes;
        if (!state.some((attr) => attr.selected)) {
            // If no filter selected, show all tickets
            return unfiltered;
        }
        return unfiltered.filter((product) => {
            const stateFilter = state.find((attr) => attr.name === product.product_type);
            return stateFilter ? stateFilter.selected : true;
        });
    };

    sortBy = (name: Column) => (a: ProductForEditorEnriched, b: ProductForEditorEnriched) => {
        const aSafe = a[name] === undefined ? "" : a[name];
        const bSafe = b[name] === undefined ? "" : b[name];
        return typeof aSafe === "string" || typeof bSafe === "string"
            ? (aSafe as string).toLowerCase().localeCompare(bSafe.toString().toLowerCase())
            : aSafe - bSafe;
    };

    toggleSort = (name: Column) => (e: React.MouseEvent<HTMLTableHeaderCellElement>) => {
        stop(e);
        const sortOrder = { ...this.state.sortOrder };
        sortOrder.descending = sortOrder.name === name ? !sortOrder.descending : false;
        sortOrder.name = name;
        this.setState({ sortOrder: sortOrder });
    };

    sort = (unsorted: ProductForEditorEnriched[]) => {
        const { name, descending } = this.state.sortOrder;
        const sorted = unsorted.sort(this.sortBy(name));
        if (descending) {
            sorted.reverse();
        }
        return sorted;
    };

    search = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        this.setState({ query: query });
        this.debouncedRunQuery(query);
    };

    runQuery = (query: string) => {
        const { products } = this.state;
        const queryToLower = query.toLowerCase();
        const results = products.filter((product) => {
            return (
                product.name.toLowerCase().includes(queryToLower) ||
                product.description_en.toLowerCase().includes(queryToLower) ||
                (product.description_nl !== null && product.description_nl.toLowerCase().includes(queryToLower))
            );
        });
        this.setState({ searchResults: results });
    };
    debouncedRunQuery = debounce(this.runQuery, 800);

    sortColumnIcon = (name: string, sortable: SortOption) => {
        if (sortable.name === name) {
            return <EuiIcon type={sortable.descending ? "sortDown" : "sortUp"} />;
        }
        return null;
    };

    save = (value: string) => {
        const { editColumnName, editId } = this.state;
        // Fetch current data
        get_product_by_id_generic("kinds", editId).then((product) => {
            // Store new version
            console.log(product);
            product[editColumnName] = value;
            client
                .put(`/v1/kinds/${product.id}`, product) // Todo: probably jsonify needed
                .then((result) => this.refreshData()) // debugger;
                .catch((result) => {});
        });
        this.setState({
            editModeActive: false,
            editColumnName: "",
            editId: "",
        });
    };

    toggleEdit = (name: string, id: string) => {
        console.log(`Edit mode => column name:${name}, id:${id}, currentEditMode:${this.state.editModeActive}`);
        if (this.state.editModeActive && name !== this.state.editColumnName) {
            console.log("Double edit attempt on name");
            return;
        }

        if (this.state.editModeActive && id !== this.state.editId) {
            console.log("Double edit attempt on id");
            return;
        }

        this.setState({
            editModeActive: !this.state.editModeActive,
            editColumnName: !this.state.editModeActive ? name : "",
            editId: !this.state.editModeActive ? id : "",
        });
    };

    render() {
        const columns: Column[] = ["name", "product_type", "modified_at", "description_nl", "description_en"];

        const th = (index: number) => {
            const name = columns[index];
            return (
                <th key={index} className={name} onClick={this.toggleSort(name)}>
                    <span>
                        <FormattedMessage id={`products.table.${name}`} />
                    </span>
                    {this.sortColumnIcon(name, this.state.sortOrder)}
                </th>
            );
        };
        const { products, query, searchResults, filterAttributes, editModeActive } = this.state;
        const filteredTickets = isEmpty(query) ? this.filter(products) : this.filter(searchResults);

        const sortedTickets = this.sort(filteredTickets);
        return (
            <EuiPage css={tableProducts}>
                <EuiPanel>
                    <div>
                        <div className="options">
                            <EuiFlexGroup justifyContent="spaceBetween">
                                <EuiFlexItem grow={false}>
                                    <ProductFilter
                                        items={filterAttributes.state}
                                        filterBy={this.setFilterList("state")}
                                        selectAll={this.selectAll("state")}
                                        label={intl.formatMessage({ id: "products.filters.state" })}
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton
                                        color={"primary"}
                                        iconType="plusInCircle"
                                        isDisabled={false}
                                        size="m"
                                        fill
                                        onClick={() => this.context.redirect("/products/new-product/kind")}
                                    >
                                        {intl.formatMessage({ id: "products.create.new_product" })}
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </div>
                        <EuiSpacer size="m" />
                    </div>
                    <table className="products">
                        <thead>
                            <tr>{columns.map((column, index) => th(index))}</tr>
                        </thead>
                        <tbody>
                            {sortedTickets.map((product) => (
                                <tr key={product.id} className={`${this.state.theme} ${product.product_state}`}>
                                    <td
                                        data-label={intl.formatMessage({
                                            id: "products.table.name",
                                        })}
                                        className="name"
                                    >
                                        <InlineTextEditor
                                            name="name"
                                            initialValue={product.name}
                                            onSave={this.save}
                                            onEdit={() => this.toggleEdit("name", product.id)}
                                            readonly={editModeActive}
                                        />
                                    </td>
                                    <td
                                        data-label={intl.formatMessage({
                                            id: "products.table.product_type",
                                        })}
                                        className="product_type"
                                    >
                                        {product.product_type}
                                    </td>
                                    <td
                                        data-label={intl.formatMessage({
                                            id: "products.table.modified_at",
                                        })}
                                        className="modified_at"
                                    >
                                        {DateTime.fromISO(product.modified_at)
                                            .plus({ hours: 2 })
                                            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                                    </td>
                                    <td
                                        data-label={intl.formatMessage({
                                            id: "products.table.description_nl",
                                        })}
                                        className="description_nl"
                                    >
                                        <InlineMarkdownEditor
                                            name="description_nl"
                                            initialValue={product.description_nl ? product.description_nl : "N/A"}
                                            onSave={this.save}
                                            onEdit={() => this.toggleEdit("description_nl", product.id)}
                                            readonly={editModeActive}
                                        />
                                    </td>
                                    <td
                                        data-label={intl.formatMessage({
                                            id: "products.table.description_en",
                                        })}
                                        className="description_en"
                                    >
                                        <InlineMarkdownEditor
                                            name="description_en"
                                            initialValue={product.description_en ? product.description_en : "N/A"}
                                            onSave={this.save}
                                            onEdit={() => this.toggleEdit("description_en", product.id)}
                                            readonly={editModeActive}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </EuiPanel>
            </EuiPage>
        );
    }
}

export default QuickEditor;
