import { EuiFlexGroup, EuiPageBody } from "@elastic/eui";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import client from "../../api/Client";
import { DynamicPricelist } from "../../components/DynamicPricelist/DynamicPricelist";
import API_URL from "../../Constants";
import {
    generateTableRows,
    horecaDynamicPricelistSettingsLoader,
    separateTableRowsInColumns,
} from "../../utils/DynamicPricelistUtils";
import { Price, PricelistQueryKey, PricelistTableRow, RowType } from "../../utils/types";
import { formatMoney } from "../../utils/Utils";
import { useGetLivePricelistUpdates } from "../../utils/Websocket";

const DynamicPricelistHoreca = () => {
    const { shopId } = useParams();
    useGetLivePricelistUpdates(shopId, PricelistQueryKey.HORECA);
    const { styles, bannersURLs, maxRowsPerColumn, rowHeight } = horecaDynamicPricelistSettingsLoader(shopId);
    const maxHeight = maxRowsPerColumn * rowHeight;
    const { data, isLoading } = useQuery(PricelistQueryKey.HORECA, () =>
        client.get(`${API_URL}/v1/shops/${shopId}?is_horeca=true`)
    );
    const activePrices = data?.data.prices.filter((price: Price) => price.active) ?? [];
    const tableRows = generateTableRows<object>(activePrices, maxRowsPerColumn);
    const tableRowsSeperatedInColumns = separateTableRowsInColumns(tableRows);

    const renderRow = (row: PricelistTableRow<object>) => {
        if (row.type === RowType.Blank || row.type === RowType.Legend) {
            return <></>;
        }
        return row.type === RowType.Title ? (
            <div className="titleRow">
                <p>{row.text}</p>
            </div>
        ) : (
            <EuiFlexGroup className="namePriceRow" justifyContent={"spaceBetween"}>
                <p> {row.text}</p>
                <p> {formatMoney(row.price?.piece, "long_euro") ?? ""}</p>
            </EuiFlexGroup>
        );
    };

    return (
        <body
            style={{
                backgroundColor: "#231f20",
                scrollbarColor: "#231f20",
                overflowX: "hidden",
                overflowY: "hidden",
                scrollbarWidth: "none",
                scrollMargin: 0,
            }}
        >
            <EuiPageBody className="pricelistBody" css={styles} style={{ backgroundColor: "#231f20" }}>
                <div className="pricelistLogoHeader">
                    {bannersURLs.bannerFront && (
                        <img alt="Banner front" className="bannerFront" src={bannersURLs.bannerFront} />
                    )}
                    <img alt="Banner logo" className="bannerLogo" src={bannersURLs.bannerLogo} />
                    {bannersURLs.bannerBack && (
                        <img alt="Banner back" className="bannerBack" src={bannersURLs.bannerBack} />
                    )}
                </div>
                <DynamicPricelist
                    tableRowsColumns={tableRowsSeperatedInColumns}
                    isLoading={isLoading}
                    maxHeight={maxHeight}
                    renderRow={renderRow}
                />
            </EuiPageBody>
            <div
                style={{
                    height: 17,
                    position: "fixed",
                    bottom: "0%",
                    left: 0,
                    width: "97.6%",
                    opacity: 1,
                    color: "#666",
                    backgroundColor: "#231f20",
                }}
            >
                &nbsp;
            </div>
        </body>
    );
};

export default DynamicPricelistHoreca;
