import {
    EuiGlobalToastList,
    EuiPage,
    EuiPageSection,
    EuiSelect,
    EuiSelectOption,
    EuiSpacer,
    EuiText,
    EuiTitle,
} from "@elastic/eui";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router";

import { postPutJson } from "../api/Client";
import CreateForm from "../components/CreateForm";
import { ResourcePayload } from "../components/CRUDResourceItem";
import GlobalToastContext from "../contextProviders/GlobalToastProvider";
import { ShopContext } from "../utils/Shop";
import { MutationType } from "../utils/types";
import { removePlural } from "../utils/Utils";

interface Resource {
    name?: string;
    short_description_nl?: string;
    short_description_en?: string | undefined;
    description_en?: string | undefined;
    description_nl?: string | undefined;
    c?: boolean;
    h?: boolean;
    i?: boolean;
    s?: boolean;
    name_en?: string;
    description?: string;
    shop_id?: string;
    main_category_id?: string;
    color?: string;
    icon?: string;
    cannabis?: string;
    shop_group_id?: string;
}

// can be renamed to something else
const NewKindOrProduct = () => {
    const { type } = useParams();
    let internalType = type === "soorten" ? "kind" : type === "horeca" ? "product" : "categorie";
    let { toasts, addToastHandler, removeToast, addErrorToast } = useContext(GlobalToastContext);
    const history = useHistory();
    let { shopId } = useContext(ShopContext);

    const [groups, setGroups] = useState<EuiSelectOption[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<String | undefined>(undefined);

    useEffect(() => {
        const shopGroups = JSON.parse(localStorage.getItem("appState")!)["profile"]["shop_groups"];
        const email = JSON.parse(localStorage.getItem("appState")!)["profile"]["email"];
        let selectableGroups: EuiSelectOption[] = [
            {
                text: "Global",
                value: "global",
            },
        ];
        shopGroups.forEach((group: { name: any; id: any }) => {
            selectableGroups.push({
                text: group.name,
                value: group.id,
            });
        });
        if (email === "theroundabout69@gmail.com") {
            selectableGroups = selectableGroups.filter((g) => g.text !== "Global");
        }
        // if (*CONDITION*) {
        //     selectableGroups.push({
        //         text: "Global",
        //         value: undefined
        //     })
        // }
        setGroups(selectableGroups);
        setSelectedGroup(selectableGroups[0].value);
    }, []);

    const create_resource_mutation = useMutation((resource: ResourcePayload) => {
        return postPutJson(`v1/${internalType}s/`, resource, "post", false, true);
    });

    const handleSubmit = (userInputs: any) => {
        debugger;
        let resource: Resource = {
            short_description_en: userInputs["short_description_en"],
            description_en: userInputs["description_en"],
            short_description_nl: userInputs["short_description_nl"],
            description_nl: userInputs["description_nl"],
        };
        if (selectedGroup !== "global") {
            resource.shop_group_id = selectedGroup;
        }

        if (internalType === "kind") {
            resource = {
                ...resource,
                name: userInputs[`kind_name`],
                c: userInputs["kind_type"] === "CBD",
                h: userInputs["kind_type"] === "Hybrid",
                i: userInputs["kind_type"] === "Indica",
                s: userInputs["kind_type"] === "Sativa",
            };
        } else if (internalType === "categorie") {
            resource = {
                name: userInputs["category_name"],
                name_en: userInputs["name_en"],
                description: userInputs["description"],
                shop_id: shopId,
                main_category_id: userInputs["main_category_id"],
                color: userInputs["color"],
                icon: userInputs["icon"],
                cannabis: userInputs["is_cannabis"],
            };
        } else {
            resource = {
                ...resource,
                name: userInputs[`product_name`],
            };
        }
        createResource(resource);
    };

    const createResource = (resource: Resource) => {
        create_resource_mutation.mutate(resource, {
            onSuccess: () => {
                addToastHandler(MutationType.Added, removePlural(type));
                if (internalType !== "categorie") {
                    history.push(`/manage/${type}`);
                } else {
                    history.push(`/products/manage/${type}`);
                }
            },
            onError: (error: any) => {
                addErrorToast(error);
            },
        });
    };

    return (
        <EuiPage>
            <EuiPageSection>
                <EuiTitle size="s">
                    <EuiText>Shop Group:</EuiText>
                </EuiTitle>
                <EuiSelect
                    options={groups}
                    value={selectedGroup}
                    onChange={(e) => setSelectedGroup(e.target.value)}
                    fullWidth
                />
                <EuiSpacer />
                <CreateForm formKey={`create_${internalType}_form`} handleSubmit={handleSubmit} goBack={true} />
                <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} side="left" />
            </EuiPageSection>
        </EuiPage>
    );
};
export default NewKindOrProduct;
