import { css } from "@emotion/react";

import { COLORS } from "../../../stylesheets/emotion/vars";
import { getTotalHeightInVh } from "../../../utils/DynamicPricelistUtils";

//Logos urls
const BANNER_LOGO = "/images/pricelist/bannerEasyGoingCenter.png";
const BANNER_FRONT = "/images/pricelist/bannerEasyGoingLeft.png";
const BANNER_BACK = "/images/pricelist/bannerEasyGoingRight.png";
const BACKGROUND_VIDEO = "/images/pricelist/smokeBackground.jpg";

const MAX_ROWS_PER_COLUMN = 34; // Maximum rows per each column, this value affects the number of columns in the table

// Table Logo Header settings: VH = Viewport Height (1vh = 1% of the screen height)
const LOGO_HEADER_HEIGHT_VH = 8;
const LOGO_HEADER_PADDING_VH = 0.5;
const ITEM_NAME_FONT_SIZE_VH = 1.6;

// Padding for each table column
const TABLE_COLUMN_PADDING_VH = 2;
const COLOR_GLOW = COLORS.easyGoingSecondaryColor;
const COLOR_GLOW2 = COLORS.easyGoingGreen;

// Styling
const STYLES = css`
    .pricelistBody {
        background-color: ${COLORS.easyGoingSecondaryColor};
    }

    .pricelistLogoHeader {
        background: ${COLORS.easyGoingSecondaryColor};
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${LOGO_HEADER_HEIGHT_VH};
        padding-block: ${LOGO_HEADER_PADDING_VH}vh;
        z-index: 3;
    }

    .bannerBack {
        height: ${LOGO_HEADER_HEIGHT_VH - 1.5}vh;
    }

    .bannerFront {
        height: ${LOGO_HEADER_HEIGHT_VH - 1.5}vh;
    }

    .bannerLogo {
        height: ${LOGO_HEADER_HEIGHT_VH}vh; // The logo height should be the same as the header height
    }

    // Pricelist table styles

    .tableColumn {
        background: ${COLORS.pricelistBackground};
        color: white;
        width: 100%;
        padding: ${TABLE_COLUMN_PADDING_VH}vh;
    }

    .rowDivider {
        border: 0.01vh solid;
        border-color: ${COLORS.easyGoingSecondaryColor};
        z-index: 3;
    }

    .tableContainer {
        position: relative;
        display: flex;
    }

    .tableRow {
        padding-block: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 2;
    }

    .titleRow {
        color: ${COLORS.easyGoingHorecaCategory};
        font-size: 1.5vw; // If the category title font size is too big, the text will be cut off
        font-weight: bold;
        animation: pulsate 1.5s ease-in-out infinite alternate;
    }

    @keyframes pulsate {
        100% {
            /* A slightly smaller blur radius */
            text-shadow: 0 0 0.04vw #000000, 0 0 0.05vw ${COLOR_GLOW}, 0 0 0.06vw ${COLOR_GLOW},
                0 0 0.07vw ${COLOR_GLOW2}, 0 0 0.08vw ${COLOR_GLOW}, 0 0 0.09vw ${COLOR_GLOW2};
        }
        0% {
            /* Larger blur radius */
            text-shadow: 0 0 0.04vw #000000, 0 0 0.18vw ${COLOR_GLOW}, 0 0 0.23vw ${COLOR_GLOW},
                0 0 0.3vw ${COLOR_GLOW2}, 0 0 0.44vw ${COLOR_GLOW}, 0 0 0.6vw ${COLOR_GLOW2};
        }
    }

    .namePriceRow {
        padding-inline: 1.5vh;
        font-size: ${ITEM_NAME_FONT_SIZE_VH}vh;
    }

    .videoContainer {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.85;
        object-fit: fill;
    }
`;

const totalHeightInVh = getTotalHeightInVh(LOGO_HEADER_HEIGHT_VH, LOGO_HEADER_PADDING_VH, TABLE_COLUMN_PADDING_VH);
const rowHeightInVh = totalHeightInVh / MAX_ROWS_PER_COLUMN;

export const easyGoingHorecaSettings = {
    styles: STYLES,
    bannersURLs: {
        bannerLogo: BANNER_LOGO,
        bannerFront: BANNER_FRONT,
        bannerBack: BANNER_BACK,
    },
    maxRowsPerColumn: MAX_ROWS_PER_COLUMN,
    rowHeight: rowHeightInVh,
    backgroundUrl: BACKGROUND_VIDEO,
};
