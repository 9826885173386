import { EuiButtonEmpty, EuiButtonIcon, EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { capitalize } from "lodash";
import React, { useState } from "react";

function InlineIconEditor({
    iconType,
    isLoading,
    initialValue,
    initialOptions,
    options,
    onSave,
    readonly = false,
}: {
    iconType: string;
    isLoading: boolean;
    options: EuiComboBoxOptionOption<string>[];
    initialValue: string[];
    initialOptions: EuiComboBoxOptionOption<string>[];
    onSave: (value: string[]) => void;
    readonly?: boolean;
}) {
    const [_editing, _setEditing] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(initialOptions);

    // Filtered options removing already selected ones
    const filteredOptions = options.filter((option) => !selectedOptions.map((o) => o.value).includes(option.value));

    const onChange = (selectedOptions: EuiComboBoxOptionOption<string>[]) => {
        setSelectedOptions(selectedOptions);
    };

    const save = () => {
        _setEditing(false);
        const newFlags = selectedOptions
            .map((option) => option.value)
            .filter((value): value is string => value !== undefined);
        onSave(newFlags);
    };

    const toggleEditing = () => {
        setSelectedOptions(initialOptions);
        if (readonly) {
            _setEditing(false);
        } else {
            _setEditing(!_editing);
        }
    };

    const editor = (
        <>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiComboBox
                        style={{ minWidth: 220 }}
                        aria-label="Flags editor"
                        placeholder={`Select ${iconType}s`}
                        options={filteredOptions} // Use the filtered options
                        selectedOptions={selectedOptions}
                        onChange={onChange}
                        isClearable={true}
                        data-test-subj="demoComboBox"
                        autoFocus
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButtonIcon aria-label="save and stop" iconType="save" iconSize="l" onClick={save} />
                        <EuiSpacer size="m" />
                        <EuiButtonIcon
                            aria-label="stop without save"
                            iconType="editorUndo"
                            iconSize="l"
                            color="danger"
                            onClick={toggleEditing}
                        />
                    </EuiFlexItem>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );

    return (
        <div>
            {isLoading && <p>Loading...</p>}
            {!_editing && !isLoading && (
                <EuiFlexGroup style={{ marginTop: "-0.4em" }}>
                    {initialValue.length === 0 && (
                        <EuiButtonEmpty
                            style={{ marginTop: "0.5em" }}
                            onClick={toggleEditing}
                            size="m"
                            iconType="plus"
                            isDisabled={false}
                        >
                            Add {iconType}s 🌎
                        </EuiButtonEmpty>
                    )}
                    <EuiFlexItem grow={false}>
                        <div onClick={toggleEditing} style={{ fontSize: "1.1em" }}>
                            {initialValue.map((value, index) => (
                                <div key={index}>
                                    <b>
                                        {capitalize(iconType)} {index + 1}
                                    </b>
                                    <p style={{ fontSize: 32 }}>{value}</p>
                                </div>
                            ))}
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        {initialValue.length !== 0 && (
                            <div>
                                <EuiButtonIcon
                                    aria-label="edit"
                                    iconType="pencil"
                                    iconSize="m"
                                    onClick={toggleEditing}
                                />
                            </div>
                        )}
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
            {_editing && editor}
        </div>
    );
}
export default InlineIconEditor;
