import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { EMPTY_PRICES_CROSSED } from "../Constants";
import { formatMoney } from "../utils/Utils";
import { Badge } from "./Badge";
import { easyGoingBadges, maryJaneBadges, mississippiBadges } from "./PriceRowStyling";

export const PriceRow = (props) => {
    const { prices, cols, shopId } = props;
    const styles = (() => {
        switch (shopId) {
            case "bbc0cfa7-e230-4338-817a-55190debdac0":
                return maryJaneBadges;
            case "ddd70853-5e9d-45ff-82b3-24c9d81d4e32":
                return easyGoingBadges;
            default:
                return mississippiBadges;
        }
    })();
    console.log("COLS", cols);
    return (
        <div>
            {prices.map((price) => {
                let description = price.kind_short_description_nl;
                if (price.strains.length) {
                    description = price.strains.map((strain, index) => (
                        <span>
                            {strain.name}
                            {index !== price.strains.length - 1 ? " - " : ""}
                        </span>
                    ));
                }

                return (
                    <div className="row">
                        <div className="rowContent">
                            <div className="kindBadge">
                                {price.kind_i ? (
                                    // "indica"
                                    <Badge styles={styles} letter={"i"} />
                                ) : price.kind_c ? (
                                    <Badge styles={styles} letter={"c"} />
                                ) : price.kind_s ? (
                                    // "sativa"
                                    <Badge styles={styles} letter={"s"} />
                                ) : // "hybrid"
                                price.kind_h ? (
                                    <Badge styles={styles} letter={"h"} />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="nameDescription">
                                <div className="nameStar">
                                    <h4 className="nameprice">{price.kind_name}</h4>
                                    <div className="newBadge">
                                        {price.new && (
                                            <FontAwesomeIcon
                                                style={{
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginLeft: "0.5vw",
                                                    fontSize: "1vw",
                                                }}
                                                icon={faStar}
                                                color={"#d1cb28"}
                                            />
                                        )}
                                    </div>
                                </div>
                                <p className="description">{description}</p>
                            </div>
                        </div>
                        <div className="filler" />
                        {cols.slice(0).map((col: string | number) => (
                            <div className="priceContainer">
                                {price[col] === null ? (
                                    <p className="price">{EMPTY_PRICES_CROSSED ? "x" : ""}</p>
                                ) : (
                                    <p className="price">{formatMoney(price[col])}</p>
                                )}
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};
