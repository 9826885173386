import {
    EuiButtonIcon,
    EuiCopy,
    EuiDescriptionListDescription,
    EuiDescriptionListTitle,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiMarkdownEditor,
    EuiMarkdownFormat,
    EuiPanel,
    EuiTextArea,
} from "@elastic/eui";
import React, { useState } from "react";

function ProductInfoTextEditor({
    name,
    initialValue,
    readonly,
    onSave,
    onEdit,
    setEditing,
    editing,
}: {
    name: string;
    initialValue: string;
    readonly: boolean;
    onSave: (value: string, name?: string) => void;
    onEdit: () => void;
    setEditing: (value: boolean) => void;
    editing: boolean;
}) {
    const product_key_label: any = {
        name: "Naam",
        short_description_nl: "Korte beschrijving NL, optioneel voor Cannabis",
        description_nl: "Uitgebreide beschrijving NL",
        short_description_en: "Korte beschrijving EN, optioneel voor Cannabis",
        description_en: "Uitgebreide beschrijving EN",
        created_at: "Gecreëerd op",
        modified_at: "Aangepast op",
    };
    const [value, setValue] = useState(initialValue);
    const [messages] = useState([]);
    const markdownFields = ["description_nl", "description_en"];
    const longFields = ["short_description_nl", "short_description_en"];

    const save = () => {
        stopEditing();
        onSave(value, name);
    };

    const startEditing = (e: React.MouseEvent<HTMLDivElement>) => {
        if (readonly || editing) return;
        if (e.target instanceof HTMLButtonElement && e.target.id === "copyButton") return;
        setEditing(true);
    };

    const stopEditing = () => {
        setEditing(false);
    };

    const editor = (
        <>
            {markdownFields.includes(name) ? (
                <EuiMarkdownEditor
                    aria-label="Text editor"
                    placeholder="Your text here..."
                    value={value}
                    onChange={setValue}
                    // @ts-ignore
                    errors={messages}
                />
            ) : longFields.includes(name) ? (
                <EuiTextArea
                    aria-label="Text editor"
                    placeholder="Your text here..."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    // @ts-ignore
                    errors={messages}
                />
            ) : (
                <EuiFieldText
                    aria-label="Text editor"
                    placeholder="Your text here..."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    // @ts-ignore
                    errors={messages}
                />
            )}
            <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false} />
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="stop without save"
                                iconType="editorUndo"
                                iconSize="l"
                                color="danger"
                                onClick={() => {
                                    stopEditing();
                                    setValue(initialValue);
                                }}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon aria-label="save and stop" iconType="save" iconSize="l" onClick={save} />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );

    return (
        <div onClick={startEditing}>
            <EuiFlexGroup alignItems="center">
                <EuiFlexItem grow={true}>
                    <EuiPanel hasShadow={false} hasBorder={true} grow={true}>
                        <EuiDescriptionListTitle>
                            {product_key_label[name]}{" "}
                            <EuiCopy textToCopy={value}>
                                {(copy) => (
                                    <EuiButtonIcon
                                        onClick={copy}
                                        aria-label="copy to clipboard"
                                        style={{ marginLeft: 5, marginTop: -5 }}
                                        color="primary"
                                        iconType="copy"
                                        id={"copyButton"}
                                    />
                                )}
                            </EuiCopy>
                            {!editing && !readonly && (
                                <EuiIcon style={{ marginLeft: 10, marginTop: -5 }} color="primary" type="pencil" />
                            )}
                        </EuiDescriptionListTitle>
                        <EuiDescriptionListDescription className="prodDesc">
                            {!editing && <EuiMarkdownFormat textSize={"s"}>{initialValue}</EuiMarkdownFormat>}
                            {editing && editor}
                        </EuiDescriptionListDescription>
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
        </div>
    );
}

export default ProductInfoTextEditor;
