import { EuiButtonIcon, EuiHeader, EuiHeaderLink, EuiHeaderSectionItem } from "@elastic/eui";
import React from "react";
import { useHistory, useLocation } from "react-router";

import { UserContext } from "../App";
import cannabisIcon from "../images/cannabis_icon.svg";
import horecaIcon from "../images/horeca_icon.svg";
import { setTheme } from "../utils/Utils";
import { PriceShowSwitcher } from "./PriceShowSwitcher";
import { ShopSwitcher } from "./ShopSwitcher";

export default function Header(themeSwitcher, ...props) {
    const location = useLocation();
    const history = useHistory();

    // const { theme } = useContext(ThemeContext);

    console.log(UserContext.Consumer);
    // Ugly hook; as the context seems not working ok
    const theme = localStorage.getItem("darkMode") || false ? "dark" : "light";

    return (
        <UserContext.Consumer>
            {(user) => (
                <EuiHeader className="header">
                    <EuiHeaderSectionItem border="none" size="large">
                        <EuiHeaderLink
                            onClick={() => history.push("/products/cannabis")}
                            isActive={location.pathname.startsWith("/products")}
                        >
                            Producten
                        </EuiHeaderLink>
                        <EuiHeaderLink
                            isActive={location.pathname.startsWith("/orders")}
                            onClick={() => history.push("/orders")}
                        >
                            Orders
                        </EuiHeaderLink>
                    </EuiHeaderSectionItem>
                    <EuiHeaderSectionItem>
                        {/*Temp !user.profile.email.endsWith("@prijslijst.info") workaround until the employee permissions are fixed*/}
                        {user.isLoggedIn &&
                            !user.profile.email.endsWith("@prijslijst.info") &&
                            user.profile.email !== "theroundabout69@gmail.com" && <ShopSwitcher />}
                        {user.isLoggedIn && <PriceShowSwitcher />}
                        {user.isLoggedIn && !user.profile.email.endsWith("@prijslijst.info") && (
                            <>
                                <EuiHeaderLink onClick={() => history.push("/manage/soorten")}>
                                    <EuiButtonIcon iconSize={"xl"} iconType={cannabisIcon} />
                                </EuiHeaderLink>
                                <EuiHeaderLink onClick={() => history.push("/manage/horeca")}>
                                    <EuiButtonIcon iconSize={"xl"} iconType={horecaIcon} />
                                </EuiHeaderLink>
                                <EuiHeaderLink onClick={() => history.push("/manage/quick-editor")} tooltip={"loe"}>
                                    <EuiButtonIcon iconType="grid" />
                                </EuiHeaderLink>
                            </>
                        )}
                        <EuiHeaderLink onClick={() => setTheme(theme === "light" ? "dark" : "light")}>
                            <EuiButtonIcon iconType={theme === "light" ? "moon" : "cloudSunny"} />
                        </EuiHeaderLink>
                        {!user.isLoggedIn && (
                            <EuiHeaderLink
                                onClick={() => history.push("/login")}
                                isActive={location.pathname.startsWith("/login")}
                            >
                                <EuiButtonIcon iconType="user" />
                            </EuiHeaderLink>
                        )}
                        {user.isLoggedIn && (
                            <EuiHeaderLink
                                onClick={() => history.push("/logout")}
                                isActive={location.pathname.startsWith("/logout")}
                            >
                                <EuiButtonIcon iconType="exit" />
                            </EuiHeaderLink>
                        )}
                    </EuiHeaderSectionItem>
                </EuiHeader>
            )}
        </UserContext.Consumer>
    );
}
