import { css } from "@emotion/react";

import { COLORS } from "../stylesheets/emotion/vars";

export const mississippiBadges = {
    i: css`
        background-color: ${COLORS.badgeIKind};
        border: ${COLORS.badgeIKindBorder};
    `,
    s: css`
        background-color: ${COLORS.badgeSKind};
        border: ${COLORS.badgeSKindBorder};
    `,
    c: css`
        background-color: ${COLORS.badgeCKind};
        border: ${COLORS.badgeCKindBorder};
    `,
    h: css`
        background-color: ${COLORS.badgeHKind};
        border: ${COLORS.badgeHKindBorder};
    `,
    badge: css`
        border-radius: 15%;
    `,
    letter: css`
        width: 1.25vw;
        height: 1.25vw;
        font-size: 1vw;
        text-align: center;
        color: ${COLORS.badgeLetter};
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};

export const maryJaneBadges = {
    i: css`
        background-color: ${COLORS.maryJaneBadgeI};
    `,
    s: css`
        background-color: ${COLORS.maryJaneBadgeS};
    `,
    c: css`
        background-color: ${COLORS.maryJaneBadgeC};
    `,
    h: css`
        background-color: ${COLORS.maryJaneBadgeH};
    `,
    badge: css`
        border-radius: 20%;
        margin-left: 1vw;
    `,
    letter: css`
        min-width: 1.2vw;
        min-height: 1.2vw;
        font-size: 0.95vw;
        text-align: center;
        color: ${COLORS.badgeLetter};
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(0.125vw);
        font-family: "agrandir-bold", "sans-serif";
    `,
};

export const easyGoingBadges = {
    i: css`
        background-color: ${COLORS.badgeIKind};
    `,
    s: css`
        background-color: ${COLORS.badgeSKind};
    `,
    c: css`
        background-color: ${COLORS.badgeCKind};
    `,
    h: css`
        background-color: ${COLORS.badgeHKind};
    `,
    badge: css`
        border-radius: 25%;
    `,
    letter: css`
        min-width: 1.3vw;
        min-height: 1.3vw;
        font-size: 0.95vw;
        text-align: center;
        color: ${COLORS.badgeLetter};
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(0.125vw);
        font-family: "agrandir-bold", "sans-serif";
    `,
};

export const roundAboutBadges = {
    i: css`
        background-color: ${COLORS.badgeIKind};
        border: ${COLORS.badgeIKindBorder};
    `,
    s: css`
        background-color: ${COLORS.badgeSKind};
        border: ${COLORS.badgeSKindBorder};
    `,
    c: css`
        background-color: ${COLORS.badgeCKind};
        border: ${COLORS.badgeCKindBorder};
    `,
    h: css`
        background-color: ${COLORS.badgeHKind};
        border: ${COLORS.badgeHKindBorder};
    `,
    badge: css`
        border-radius: 15%;
    `,
    letter: css`
        width: 1.25vw;
        height: 1.25vw;
        font-size: 1vw;
        text-align: center;
        color: ${COLORS.badgeLetter};
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};
