/*
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import "./Forms.scss";

import { EuiIcon, EuiPage, EuiPageSection, EuiPanel } from "@elastic/eui";
import CreateForm from "components/CreateForm";
import Explain from "components/Explain";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { postPutJson } from "../api/Client";

export interface CreateKindPayload {
    name: string;
    c?: boolean;
    h?: boolean;
    i?: boolean;
    s?: boolean;
    description_nl?: string;
    description_en?: string;
}

export interface KindStrainPayload {
    kind_id: string;
    strain_id: string;
}

function relateStrains(kindId: string, strainId: string) {
    const payload: KindStrainPayload = {
        kind_id: kindId,
        strain_id: strainId,
    };
    postPutJson("v1/kinds-to-strains/", payload, "post", false, true).then((result) =>
        console.log("Added strain relation", payload)
    );
}

function createKind(kind: CreateKindPayload): Promise<{ id: string }> {
    return postPutJson("v1/kinds/", kind, "post", false, true);
}

async function createNewStrains(strains: string[]) {
    try {
        const promises = strains.map((strain) => {
            return postPutJson(`v1/strains/`, { name: strain }, "post", false, true);
        });
        return await Promise.all(promises);
    } catch (error) {
        console.log(error);
    }
}

export default function CreateCannabisProduct() {
    const history = useHistory();
    const [showExplanation, setShowExplanation] = useState(false);

    async function handleSubmit(userInputs: any) {
        let newStrainNames = userInputs.create_new_strains;
        if (userInputs.new_strain_from_name) {
            newStrainNames.push(userInputs.kind_name);
        }
        let newStrains = await createNewStrains(newStrainNames);

        const kind: CreateKindPayload = {
            name: userInputs.kind_name,
            c: userInputs.kind_type === "CBD",
            h: userInputs.kind_type === "Hybrid",
            i: userInputs.kind_type === "Indica",
            s: userInputs.kind_type === "Sativa",
            description_nl: userInputs.kind_description_nl,
            description_en: userInputs.kind_description_en,
        };

        createKind(kind).then((result) => {
            console.log("Post kind succeeded");
            // Todo: make it less ugly
            for (const x of userInputs.strain_choice) {
                relateStrains(result.id, x);
            }
            if (newStrains) {
                for (const x of newStrains) {
                    relateStrains(result.id, x.id);
                }
            }
            if (userInputs.gebruiken) {
                history.push(`/products/new-product/cannabis?selectedWiet=${result.id}`);
            } else {
                history.push("/products/cannabis");
            }
        });
    }

    return (
        <EuiPage>
            <EuiPageSection>
                <EuiPanel hasBorder={true} hasShadow={true} style={{ marginTop: "20px" }}>
                    <div className="actions">
                        <div onClick={() => setShowExplanation(true)}>
                            <EuiIcon type={"questionInCircle"} size={"xxl"} />
                        </div>
                    </div>
                    <Explain
                        close={() => setShowExplanation(false)}
                        isVisible={showExplanation}
                        title="Snel een cannabis product aanmaken"
                    >
                        <p>
                            Met dit formulier kun je snel een cannabis product aanmaken en er een prijs aan koppelen
                            zodat het cannabis product zichtbaar wordt op de prijslijst in de winkel.
                        </p>
                    </Explain>
                    <CreateForm formKey="create_kind_to_strains_form" handleSubmit={handleSubmit} />
                </EuiPanel>
            </EuiPageSection>
        </EuiPage>
    );
}
