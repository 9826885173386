/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import { EuiIcon, EuiPage, EuiPageSection } from "@elastic/eui";
import DropDownActions from "components/DropDownActions";
import Explain from "components/Explain";
import ConfirmationDialog from "components/modals/ConfirmationDialog";
import {
    ProductsTable,
    initialProductsFilterAndSort,
    initialProductsTableSettings,
} from "components/tables/ProductTable";
import React from "react";
import { stop } from "utils/Utils";

import { ShopContext } from "../utils/Shop";
import { ProductForTable } from "../utils/types";
import { actionOptions } from "../validations/ProductsAndKinds";

interface IProps {}

interface IState {
    confirmationDialogOpen: boolean;
    confirmationDialogAction: (e: React.MouseEvent) => void;
    confirm: () => void;
    confirmationDialogQuestion: string;
    showExplanation: boolean;
}

class ProductsPage extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            confirmationDialogOpen: false,
            confirmationDialogAction: () => this,
            confirm: () => this,
            confirmationDialogQuestion: "",
            showExplanation: false,
        };
    }

    cancelConfirmation = () => this.setState({ confirmationDialogOpen: false });

    confirmation = (question: string, action: (e: React.MouseEvent) => void) =>
        this.setState({
            confirmationDialogOpen: true,
            confirmationDialogQuestion: question,
            confirmationDialogAction: (e: React.MouseEvent) => {
                this.cancelConfirmation();
                action(e);
            },
        });

    handleViewProduct = (product: ProductForTable) => (e: React.MouseEvent) => {
        window.location.replace(`/view/product/${product.id}`);
        stop(e);
    };

    handleEditProduct = (product: ProductForTable) => (e: React.MouseEvent) => {
        console.log("Redirect to edit?");
        stop(e);
    };

    handleDeleteProduct = (product: ProductForTable) => () => {
        this.confirmation(
            "Weet je zeker dat je de product wil wissen?",
            () => console.log("Deleting")
            // client.patch(`/v1/products/${product.id}`, payload).then(() => {
            //     console.log("Product forcefully completed");
            //     window.location.href = "/products";
            // })
        );
    };

    renderActions = (product: ProductForTable) => {
        let options = actionOptions(product, this.handleViewProduct(product), this.handleDeleteProduct(product));
        return <DropDownActions options={options} i18nPrefix="ProductsPage.actions" />;
    };

    renderExplain() {
        return (
            <div style={{ marginTop: 9 }} onClick={() => this.setState({ showExplanation: true })}>
                <EuiIcon type={"questionInCircle"} size={"xl"} />
            </div>
        );
    }

    render() {
        const shopId = this.context.shopId.substr(0, 8);
        const settings = initialProductsTableSettings(
            "table.products-page.horeca-producten",
            initialProductsFilterAndSort(shopId, "pending"),
            ["created_at", "description_nl", "description_en"],
            { showSettings: false, pageSize: 10, refresh: false }
        );

        return (
            <EuiPage>
                <EuiPageSection>
                    <Explain
                        close={() => this.setState({ showExplanation: false })}
                        isVisible={this.state.showExplanation}
                        title="ProductsPage Help"
                    >
                        <h1>Producten</h1>
                        <p>Gebruik deze pagina om snel product info aan te passen.</p>
                        <h2>Uitleg</h2>
                        <p>
                            De tabellen slaan hun instellingen op in je browser. Wil je dat resetten, gebruik dan het
                            potlood icoontje en druk op "Reset".
                        </p>
                    </Explain>
                    <ConfirmationDialog
                        isOpen={this.state.confirmationDialogOpen}
                        cancel={this.cancelConfirmation}
                        confirm={this.state.confirmationDialogAction}
                        question={this.state.confirmationDialogQuestion}
                    />
                    <div className="actions">{this.renderExplain()}</div>
                    <ProductsTable
                        key={"products"}
                        initialTableSettings={settings}
                        renderActions={this.renderActions}
                    />
                </EuiPageSection>
            </EuiPage>
        );
    }
}

ProductsPage.contextType = ShopContext;

export default ProductsPage;
