import React from "react";

import { S3_BUCKET } from "../Constants";

export const getImageSrc = (imageKey, width, bucket, height) => {
    const options = {
        bucket: bucket,
        key: imageKey,
        edits: {
            resize: {
                width: width,
                height: height ? height : width,
                fit: "contain",
                background: {
                    r: 255,
                    g: 255,
                    b: 255,
                    alpha: 1,
                },
            },
        },
    };
    const strRequest = JSON.stringify(options);
    const encRequest = btoa(strRequest);

    return `https://d3sticxdmgvhkp.cloudfront.net/${encRequest}`;
};

const ProductImage = (props) => {
    return <img alt="" src={getImageSrc(props.imageKey, props.width, S3_BUCKET, props.height)} />;
};

export default ProductImage;
