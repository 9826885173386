import { css } from "@emotion/react";

export const zIndex = '("sync": 900,"flash": 1020,"explain": 1010)';
export const MEDIUM_SCREEN_WITH = "1680px";
/*
!
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
*/

export const euiButton = css`
    background-color: #e39846 !important;
`;

export const euiButtonFill = css`
    color: #fff !important;
`;

export const euiTab = css`
    color: red;
`;

export const COLORS = {
    primary: "#0077cc",
    darkerPrimary: "#0077cc",
    lighterPrimary: "#a8d9e6",
    lightPrimary: "#cbe7fb",
    lightestPrimary: "#fcfdff",
    accent: "#274b72",
    danger: "#bd271e",
    lightDanger: "#ffcdc6",
    lightestDanger: "#ffddd6",
    success: "#00bfb3",
    lightSuccess: "#d0ffd9",
    lightestSuccess: "#e5ffed",
    warning: "#fec514",
    lightWarning: "#ffe4ab",
    background: "#f9f9f9",
    mediumGrey: "#a6b6be",
    darkGrey: "#565656",
    lightGrey: "#eff2f3",
    lighterGrey: "#d9d9d8",
    lightestGrey: "#fbfbfb",
    hover: "#f9fcfd",
    gold: "#d4af37",
    lightGold: "#fdf6d4",
    lightestGold: "#fdfde3",
    tableOddEven: "#fbfbfb",
    border: "rgba(0, 0, 0, 0.1)",
    borderLight: "rgba(255, 255, 255, 0.2)",
    pricelistBackgroundColor: "#1d1d1b",
    pricelistBackground: "#231f20",
    pricelistTableContainer: "#00000099",
    badgeIKind: "#ffc100",
    badgeIKindBorder: "1px solid #ffdc79",
    badgeCKind: "#ff000e",
    badgeCKindBorder: "1px solid #fd7178",
    badgeSKind: "#b600ff",
    badgeSKindBorder: "1px solid #d878ff",
    badgeHKind: "#5aa419",
    badgeHKindBorder: "1px solid #b9fd7d",
    badgeLetter: "#ffffff",
    legendTextColor: "#ffffff",
    descriptionColor: "#f5d186",
    br: "3px",
    maryJaneColor: "#00481b",
    maryJaneDescriptionColor: "#005421",
    maryJaneBadgeI: "#f0cc01",
    maryJaneBadgeIBorder: "1px solid #ffdc79",
    maryJaneBadgeC: "#ff5d2c",
    maryJaneBadgeCBorder: "1px solid #fd7178",
    maryJaneBadgeS: "#d7b6d4",
    maryJaneBadgeSBorder: "1px solid #d878ff",
    maryJaneBadgeH: "#dae5bf",
    maryJaneBadgeHBorder: "1px solid #b9fd7d",
    maryJaneRowColor: "#fffcf9",
    maryJaneRowColor2: "#fbf9f1",
    easyGoingColor: "#3c1712",
    easyGoingSecondaryColor: "#875b3b",
    easyGoingGreen: "#a8d35d",
    easyGoingHorecaCategory: "#F7DE42",
    deSteegColor: "#0ba14b",
    deSteegSecondaryColor: "#fff200",
    deSteegGlow: "#875b3b",
    deSteegGlow2: "#a8d35d",
    roundaboutColor: "#4472C4",
    roundaboutSecondaryColor: "#E5E6BE",
    roundaboutGlow: "#E8EBF5",
    roundaboutGlow2: "#CDD4EA",
};

export const SIZES = {
    medium: "1680px",
};

export const Z_INDEX = {
    sync: 900,
    flash: 1020,
    explain: 1010,
};
