/* Default font colors and table colors */
export const DARK_FONT_COLOR = "#ffffff";
export const LIGHT_FONT_COLOR = "#111111";
export const DARK_ROW_BORDER_COLOR = "#555555";
export const LIGHT_ROW_BORDER_COLOR = "#cccccc";
export const DARK_BACKGROUND_COLOR = "#1D1E24";
export const LIGHT_BACKGROUND_COLOR = "#f6f6f6";
export const DARK_SELECTED_FONT_COLOR = "#0799fc";
export const LIGHT_SELECTED_FONT_COLOR = "#0077CCFF";

/* Copy of EUI colors */
export const PRIMARY_COLOR = "#0077cc";
export const ACCENT = "#274b72";
export const SUCCESS = "#00bfb3";
export const WARNING = "#fec514";
export const DANGER = "#bd271e";

/* Todo: investigate if we need them all: we should use shadeColor() when we need different shades  */
export const MEDIUM_GREY_COLOR = "#a6b6be";
export const LIGHTER_GREY_COLOR = "#d9d9d8";
export const LIGHT_PRIMARY_COLOR = "#cbe7fb";
export const DARKEST_PRIMARY_COLOR = "#08314bff";

export const LIGHT_DANGER_COLOR = "#ffe9e9";
export const DARK_DANGER_COLOR = "#2a0000";
export const LIGHT_SUCCESS_COLOR = "#e7ffeb";
export const DARK_SUCCESS_COLOR = "#002321";
export const LIGHT_GOLD_COLOR = "#fffbec";
export const DARK_GOLD_COlOR = "#02001f";
export const LIGHT_GREY_COLOR = "#eff2f3";
export const DARK_GREY_COLOR = "#565656";
