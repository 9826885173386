import React from "react";

import { APP_VERSION } from "../../utils/Version";

export const AppVersionLabel = () => {
    return (
        <div
            style={{
                height: "1.5vh",
                position: "fixed",
                bottom: "0%",
                left: 0,
                opacity: 0.9,
                color: "#666",
                zIndex: 1000,
            }}
        >
            v{APP_VERSION}
        </div>
    );
};
