import { EuiButtonEmpty, EuiContextMenu, EuiIcon, EuiPopover } from "@elastic/eui";
import React from "react";
import { useContext, useState } from "react";
import { useQuery } from "react-query";

import { get_shops } from "../api/Client";
import { Shop } from "../types";
import { ShopContext } from "../utils/Shop";
import { removeTableSettingsFromLocalStorage } from "../utils/Utils";

export const ShopSwitcher = () => {
    const [isPopoverOpen, setPopover] = useState(false);
    const { isLoading, error, data } = useQuery("ShopSwitcher", () => get_shops().then());
    const shopContext = useContext(ShopContext);

    if (error) {
        return <div>Fetching shops resulted in error</div>;
    }

    let ShopSwitcherList: {
        icon: JSX.Element;
        name: string;
        href: string;
        onClick: () => void;
    }[] = [];
    if (!isLoading) {
        data?.map((item: Shop) =>
            ShopSwitcherList?.push({
                icon: <EuiIcon type="eye" size="m" />,
                name: item.name,
                onClick: () => {
                    shopContext.setShopName(item.name);
                    shopContext.setShopId(item.id);
                    removeTableSettingsFromLocalStorage();
                    window.location.href = "/";
                },
                href: "#",
            })
        );
    }

    const onButtonClick = () => {
        setPopover(!isPopoverOpen);
    };

    const closePopover = () => {
        setPopover(false);
    };

    const button = (
        <EuiButtonEmpty iconType="arrowDown" iconSide="right" onClick={onButtonClick}>
            {shopContext.shopName}
        </EuiButtonEmpty>
    );

    const panels = [
        {
            id: 0,
            title: shopContext.shopName,
            items: ShopSwitcherList,
        },
    ];

    return (
        <EuiPopover
            id="contextMenuExample"
            button={button}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="none"
            anchorPosition="downLeft"
        >
            <EuiContextMenu initialPanelId={0} panels={panels} />
        </EuiPopover>
    );
};
