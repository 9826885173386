// eslint-disable-next-line no-prototype-builtins
import PropTypes from "prop-types";
import React from "react";
import Currency from "react-currency-formatter";

export default function PriceTable(props) {
    const { price } = props;

    return (
        <table>
            <thead>
                <tr>
                    {Object.keys(price).map((key) => (
                        <>{price[key] && <th>{key}</th>}</>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {Object.keys(price).map((key) => (
                        <>
                            {price[key] && (
                                <td style={{ paddingRight: "9px" }}>
                                    <Currency quantity={price[key]} currency="EUR" locale="nl_NL" />
                                </td>
                            )}
                        </>
                    ))}
                </tr>
            </tbody>
        </table>
    );
}

PriceTable.propTypes = {
    price: PropTypes.object.isRequired,
};
