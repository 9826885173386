/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import React from "react";
import { Cell } from "react-table";

import { Tag } from "../../utils/types";

export function renderTimestampCell({ cell }: { cell: Cell }) {
    if (!cell.value) {
        return null;
    }
    const timestamp: number = cell.value;

    const datetime = new Date(timestamp * 1000);
    const today = new Date();
    if (
        datetime.getFullYear() === today.getFullYear() &&
        datetime.getMonth() === today.getMonth() &&
        datetime.getDay() === today.getDay()
    ) {
        return datetime.toLocaleTimeString("nl-NL").substring(0, 5) + " CET";
    } else {
        return datetime.toLocaleDateString("nl-NL");
    }
}

export function renderIdCell({ cell }: { cell: Cell }) {
    if (!cell.value) {
        return <></>;
    }
    const id: string = cell.value;
    return (
        <span key={id} onClick={(e: { stopPropagation: () => any }) => e.stopPropagation()}>
            {id.slice(0, 8)}
        </span>
    );
}

export function renderCannabisIdCell({ cell }: { cell: Cell }) {
    const id: string = cell.value;
    return <span key={id}>{id.slice(0, 8)}</span>;
}

export function renderHorecaIdCell({ cell }: { cell: Cell }) {
    const id: string = cell.value;
    return <span key={id}>{id.slice(0, 8)}</span>;
}

export function renderInsyncCell({ cell }: { cell: Cell }) {
    const insync: boolean = cell.value;
    return <i className={`${insync ? "fa fa-check-square" : "far fa-square"}`} />;
}

export function renderRiffTagCell({ cell }: { cell: Cell }) {
    const tags: Tag[] = cell.value;
    return tags.map((tag) => tag.name).join(",");
}
