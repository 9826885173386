import { EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import React, { useState } from "react";

function InlineTextEditor({
    name,
    initialValue,
    readonly,
    onSave,
    onEdit,
}: {
    name: string;
    initialValue: string;
    readonly: boolean;
    onSave: (value: string, name?: string) => void;
    onEdit: (value: string) => void;
}) {
    const [value, setValue] = useState(initialValue);
    const [_editing, _setEditing] = useState(false);
    const [messages] = useState([]);

    const save = () => {
        _setEditing(false);
        onSave(value, name);
    };

    const toggleEditing = () => {
        if (readonly) return;
        _setEditing(!_editing);
        // Todo: add name here
        onEdit(name);
    };

    const editor = (
        <>
            <EuiFieldText
                aria-label="Text editor"
                placeholder="Your text here..."
                value={value}
                onChange={(e) => setValue(e.target.value)}
                errors={messages}
            />
            <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false} />
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="stop without save"
                                iconType="editorUndo"
                                iconSize="l"
                                color="danger"
                                onClick={() => {
                                    toggleEditing();
                                    setValue(initialValue);
                                }}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon aria-label="save and stop" iconType="save" iconSize="l" onClick={save} />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );

    return (
        <div>
            {!_editing && (
                <div onClick={toggleEditing} style={{ fontSize: "1.1em", marginTop: "-0.3em" }}>
                    {initialValue}
                </div>
            )}
            {_editing && editor}
        </div>
    );
}

export default InlineTextEditor;
