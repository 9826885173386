import {
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHealth,
    EuiInMemoryTable,
    EuiLink,
    EuiSwitch,
} from "@elastic/eui";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import client from "../api/Client";
import { UserContext } from "../App";
import { helperNumber } from "../utils/Utils";
import ConfirmationDialog from "./modals/ConfirmationDialog";
import PriceTable from "./PriceTable";

/*
Example user object:

{
  id: '1',
  firstName: 'john',
  lastName: 'doe',
  github: 'johndoe',
  dateOfBirth: Date.now(),
  nationality: 'NL',
  online: true
}

Example country object:

{
  code: 'NL',
  name: 'Netherlands',
  flag: '🇳🇱'
}
*/

export const ProductResults = ({ products, searchIsOpen, mode, refresh, ...props }) => {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingRows, setLoadingRows] = useState([]);
    const [items, setItems] = useState(products);

    const [error, setError] = useState();
    const tableRef = useRef();

    const history = useHistory();
    const user = useContext(UserContext);

    const handleAvailabilityToggle = (id) => {
        let newItems = items;
        setLoading(true);

        // update data in state
        newItems.map((item) => {
            if (item.id === id) {
                const offset = DateTime.local().offset;
                item.active = !item.active;
                item.modified_at = DateTime.local().plus({ hours: -(offset / 60) });
            }
            return item;
        });
        setItems(newItems);

        const payload = { active: newItems.find((item) => item.id === id).active };
        client
            .put(`/v1/shops-to-prices/availability/${id}`, payload)
            .then((result) => {
                setLoading(false);
            })
            .catch((result) => {
                const data = result.response;
                // debugger;
                if (data.status === 401) {
                    setError(`update resulted in error: ${data.statusText}`);
                    setLoading(false);
                } else {
                    setError(`update resulted in error ${data.status}: ${data.statusText}`);
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        setLoadingRows([]);
        setItems(getMaximumNumbers(products));
    }, [products]);

    useEffect(() => {
        console.log("Mode", mode);
    }, [mode]);

    const handleDelete = (id) => {
        client
            .delete(`/v1/shops-to-prices/${id}`)
            .then((result) => {
                setLoading(false);
                setItems(items.filter((item) => item.id !== id));
                refresh(mode);
            })
            .catch((result) => {
                const data = result.response;
                // debugger;
                if (data.status === 401) {
                    setError(`update resulted in error: ${data.statusText}`);
                    setLoading(false);
                } else {
                    setError(`update resulted in error ${data.status}: ${data.statusText}`);
                    setLoading(false);
                }
            });
    };

    const getMaximumNumbers = (items) => {
        let previousItemOrderNumber = -1;
        let newArray = [];

        for (let i = 0; i < items.length; i++) {
            let newItem = items[i];
            newItem["isLast"] = false;
            if (items[i].order_number < previousItemOrderNumber) {
                newArray[i - 1]["isLast"] = true;
            }
            previousItemOrderNumber = items[i].order_number;
            newArray.push(newItem);
        }
        if (newArray.length >= 1) {
            newArray[items.length - 1]["isLast"] = true;
        }
        return newArray;
    };

    const handleSwap = (id, moveUp, order_number) => {
        let new_order_number = moveUp ? order_number - 1 : order_number + 1;
        setLoadingRows([order_number, new_order_number]);
        client
            .patch(`/v1/shops-to-prices/swap/${id}?move_up=${moveUp}`)
            .then((result) => {
                setLoading(false);
                refresh(mode);
            })
            .catch((result) => {
                const data = result.response;
                // debugger;
                if (data.status === 401) {
                    setError(`update resulted in error: ${data.statusText}`);
                    setLoading(false);
                } else {
                    setError(`update resulted in error ${data.status}: ${data.statusText}`);
                    setLoading(false);
                }
            });
    };

    function handleRowClick(item) {
        if (item.kind_id !== null) {
            history.push(`/view/cannabis/${item.kind_id}`);
        } else if (item.product_id !== null) {
            history.push(`/view/product/${item.product_id}`);
        }
    }
    // TODO: Make not hardcoded
    const showAdminLinks = !user.profile.email.endsWith("@prijslijst.info");
    const showEditLink = user.profile.email !== "theroundabout69@gmail.com";
    const field = mode === "horeca" ? "product_name" : "kind_name";

    // Todo: move columns outside function? And order the use State stuff better
    let columns = [
        {
            field: "category_name",
            name: "Category",
            sortable: true,
            width: 95,
        },
        {
            field: field,
            name: "Product",
            sortable: true,
            width: 140,
            render: showAdminLinks
                ? (field, object) =>
                      showEditLink ? (
                          <EuiLink onClick={() => handleRowClick(object)} target="_blank">
                              {field}
                          </EuiLink>
                      ) : (
                          <span>{field}</span>
                      )
                : undefined,
        },
        {
            field: "new",
            name: "Nieuw",
            sortable: true,
            width: 60,
            render: (item) => <span>{item ? "ja" : "nee"}</span>,
        },
        {
            field: "description",
            name: "Beschrijving",
            sortable: true,
        },
        {
            field: "prices",
            name: "Prijzen",
            sortable: (prices) => {
                const jointPrice = helperNumber + prices.joint;
                const halfPrice = helperNumber + prices.half;
                const onePrice = helperNumber + prices.one;
                const twoFivePrice = helperNumber + prices.two_five;
                const fivePrice = helperNumber + prices.five;
                const piecePrice = helperNumber + prices.piece;
                return [halfPrice, onePrice, twoFivePrice, fivePrice, jointPrice, piecePrice];
            },
            render: (prices) => <PriceTable price={prices} />,
            width: 240,
        },
        {
            field: "internal_product_id",
            name: "Code",
            sortable: true,
            width: 75,
            render: (id) => <label title={id}>{id.includes(":") && id.length > 3 ? id.substring(0, 3) : id}</label>,
        },
        {
            field: "modified_at",
            name: "Aangepast",
            sortable: ({ modified_at }) => DateTime.fromISO(modified_at),
            render: (modified_at) => {
                const offset = DateTime.local().offset;
                const renderDate = DateTime.fromISO(modified_at).plus({
                    hours: offset / 60,
                });
                return (
                    <div>
                        {renderDate.toLocaleString()}
                        <br />
                        {renderDate.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
                    </div>
                );
            },
            align: "right",
        },
        {
            field: "active",
            name: "Voorraad?",
            render: (active) => {
                const color = active ? "success" : "danger";
                const label = active ? "ja" : "nee";
                return (
                    <EuiHealth color={color} size="l">
                        {label}
                    </EuiHealth>
                );
            },
            align: "right",
            sortable: true,
            width: 80,
        },
        {
            field: "active",
            name: "",
            render: (active, record) => {
                return (
                    <EuiSwitch
                        aria-label="toggle-availability"
                        label=""
                        checked={active}
                        onChange={() => handleAvailabilityToggle(record.id)}
                    />
                );
            },
            align: "right",
            width: 60,
        },
        {
            field: "prices",
            name: "",
            render: (prices, record) => {
                return (
                    <div>
                        <EuiFlexGroup>
                            {mode === "horeca" && !loadingRows.includes(record.order_number) && (
                                <EuiFlexItem>
                                    <EuiFlexGroup direction="column">
                                        <EuiFlexItem style={{ margin: 1 }}>
                                            <EuiButtonIcon
                                                disabled={record.order_number === 0}
                                                aria-label="arrowUp"
                                                iconType="arrowUp"
                                                onClick={() => handleSwap(record.id, true, record.order_number)}
                                            />
                                        </EuiFlexItem>
                                        <EuiFlexItem style={{ margin: 1 }}>
                                            <EuiButtonIcon
                                                disabled={
                                                    record && record.hasOwnProperty("isLast") ? record.isLast : true
                                                }
                                                aria-label="arrowDown"
                                                iconType="arrowDown"
                                                onClick={() => handleSwap(record.id, false, record.order_number)}
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            )}
                            {mode === "horeca" && loadingRows.includes(record.order_number) && (
                                <EuiFlexItem>
                                    <EuiFlexGroup direction="column">
                                        <EuiFlexItem style={{ margin: 1, marginTop: 10 }}>
                                            <EuiButtonIcon aria-label="arrowUp" iconType="arrowUp" isLoading={true} />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            )}
                            <EuiFlexItem style={{ marginLeft: 30, marginRight: 20 }}>
                                <EuiLink href={`/products/edit-product/${mode}/${record.id}`}>
                                    <EuiButtonIcon aria-label="edit" iconType="pencil" />
                                </EuiLink>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiButtonIcon
                                    aria-label="trash"
                                    iconType="trash"
                                    onClick={() => {
                                        setDeleteId(record.id);
                                        setConfirmDialogOpen(true);
                                    }}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </div>
                );
            },
            align: "right",
            width: 170,
        },
    ];

    if (mode === "horeca") {
        columns.splice(1, 0, {
            field: "order_number",
            name: "Order Number",
            sortable: true,
            width: 110,
        });
    }

    const sorting = {
        sort: {
            field: "category",
            direction: "desc",
        },
    };

    const search = {
        box: {
            incremental: false,
            schema: true,
        },
    };

    return (
        <>
            <ConfirmationDialog
                isOpen={confirmDialogOpen}
                cancel={() => setConfirmDialogOpen(false)}
                confirm={() => {
                    setLoading(true);
                    handleDelete(deleteId);
                    setConfirmDialogOpen(false);
                }}
                question={"Weet je zeker dat je deze wilt wissen?"}
            />
            <EuiInMemoryTable
                ref={tableRef}
                loading={loading}
                layout="auto"
                items={items}
                rowHeader="description"
                columns={columns}
                error={error}
                sorting={sorting}
                search={searchIsOpen ? search : null}
                isSelectable={true}
            />
        </>
    );
};
