import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiRadio, EuiText } from "@elastic/eui";
import React, { useEffect, useState } from "react";

import { FormOption, MutationType } from "../../utils/types";

function RadioButtonEditor({
    keyName,
    initialValue,
    readonly,
    onSave,
    onEdit,
    index,
    selectedItem,
    selectedColumn,
    isNew,
    options,
    showDelete = true, //used when there are more than two columns in a table ex. Categories
}: {
    keyName: string;
    options: FormOption[];
    initialValue: string;
    readonly: boolean;
    onSave: (value: string | null, mutationType: MutationType, keyName?: string) => void;
    onEdit: (row: number, column: number) => void;
    index: number;
    selectedItem: { row: number; column: number };
    selectedColumn: number;
    isNew: boolean;
    showDelete?: boolean;
}) {
    const [value, setValue] = useState(initialValue);
    const [_editing, _setEditing] = useState(false);

    useEffect(() => {
        setValue(initialValue);
        if (index === selectedItem.row && selectedColumn === selectedItem.column) {
            _setEditing(true);
        } else {
            _setEditing(false);
        }
    }, [selectedItem, index, initialValue, _editing, selectedColumn]);

    const handleSave = () => {
        toggleEditing(-1, -1);
        if (value !== initialValue) {
            onSave(value, MutationType.Edited, keyName);
        }
    };

    const handleDelete = () => {
        toggleEditing(-1, -1);
        onSave(null, MutationType.Deleted);
    };

    const toggleEditing = (row: number, column: number) => {
        onEdit(row, column);
    };

    const onChange = (value: string) => {
        setValue(value);
    };

    const editor = (
        <>
            <EuiFlexGroup>
                {options.map((option) => (
                    <EuiFlexItem grow={false}>
                        <EuiRadio
                            id={option.value}
                            label={option.label}
                            value={option.value}
                            checked={option.value === value}
                            onChange={() => onChange(option.value)}
                        />
                    </EuiFlexItem>
                ))}
            </EuiFlexGroup>
            <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false} style={{ paddingInline: 5, paddingBlock: 2 }}>
                    {showDelete && (
                        <EuiButtonIcon
                            aria-label="delete"
                            display="base"
                            iconType="trash"
                            iconSize="m"
                            color="danger"
                            onClick={handleDelete}
                        />
                    )}
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="stop without save"
                                iconType="editorUndo"
                                iconSize="l"
                                color="danger"
                                onClick={() => {
                                    toggleEditing(-1, -1);
                                    setValue(initialValue);
                                }}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="save and stop"
                                iconType="save"
                                iconSize="l"
                                onClick={handleSave}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );

    return (
        <EuiFlexGroup>
            {!_editing && !readonly && (
                <EuiFlexItem>
                    <EuiFlexGroup
                        onClick={() => toggleEditing(index, selectedColumn)}
                        style={{ fontSize: "1.1em", marginTop: "-0.3em" }}
                    >
                        <EuiFlexItem grow={false}>
                            <EuiIcon style={{ marginLeft: 5, marginRight: -5 }} color="primary" type="pencil" />
                        </EuiFlexItem>
                        {initialValue !== "null" ? (
                            <EuiFlexItem grow={false}>{initialValue}</EuiFlexItem>
                        ) : (
                            <EuiFlexItem grow={false}>
                                <EuiText size={"s"} style={{ fontStyle: "italic", color: "grey" }}>
                                    None
                                </EuiText>
                            </EuiFlexItem>
                        )}
                        {isNew && (
                            <EuiFlexItem>
                                <EuiText style={{ fontStyle: "italic", color: "grey" }}>New</EuiText>
                            </EuiFlexItem>
                        )}
                    </EuiFlexGroup>
                </EuiFlexItem>
            )}
            {_editing && (
                <EuiFlexItem grow={false} style={{ minWidth: "10vw" }}>
                    {editor}
                </EuiFlexItem>
            )}
        </EuiFlexGroup>
    );
}

export default RadioButtonEditor;
