import "./Forms.scss";

import {
    EuiButton,
    EuiCallOut,
    EuiFieldNumber,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiSwitch,
    EuiText,
} from "@elastic/eui";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { JsonPrice } from "utils/types";

import client from "../api/Client";
import API_URL from "../Constants";
import { ShopContext } from "../utils/Shop";

function CannabisFormNew({
    product,
    preselectedWeed,
}: {
    product: object | undefined;
    preselectedWeed: string | undefined;
}) {
    const shopContext = useContext(ShopContext);

    const [redirectToListEnabled, setRedirectToListEnabled] = useState(true);
    const [prices, setPrices] = useState([]);
    const [priceOptions, setPriceOptions] = useState([]);
    const [pricesLoaded, setPricesLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoriesLoaded, setCategoriesLoaded] = useState(false);
    const [kinds, setKinds] = useState([]);
    const [kindsLoaded, setKindsLoaded] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState(product ? product.price_id : "-1");
    const [selectedCategory, setSelectedCategory] = useState(product ? product.category_id : "-1");
    const [selectedKind, setSelectedKind] = useState(product ? product.kind_id : "-1");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(undefined);
    const [messageHelp, setMessageHelp] = useState(undefined);
    const [errors, setErrors] = useState(false);

    const [checkedHalf] = useState(false);
    const [checkedOne] = useState(false);
    const [checkedTwoHalf] = useState(false);
    const [checkedFive] = useState(false);
    const [checkedJoint] = useState(false);
    const [checkedPiece] = useState(false);
    const [checkedNew, setCheckedNew] = useState(false);

    const [useExistingPrice, setUseExistingPrice] = useState(product ?? false);
    const [existingPrice, setExistingPrice] = useState(undefined);
    const [edibleJsonPrices, setEdibleJsonPrices] = useState<JsonPrice[]>([]);
    const [preRolledJsonPrices, setPreRolledJsonPrices] = useState<JsonPrice[]>([]);
    const [cannabisJsonPrices, setCannabisJsonPrices] = useState<JsonPrice[]>([]);

    const [internalIdPrefix, setInternalIdPrefix] = useState<string>("000");

    useEffect(() => {
        setCheckedNew(
            !localStorage.getItem("markPriceAsNew")
                ? true
                : localStorage.getItem("markPriceAsNew") === "true"
                ? true
                : false
        );

        setRedirectToListEnabled(
            !localStorage.getItem("redirectToPriceList")
                ? false
                : localStorage.getItem("redirectToPriceList") === "true"
                ? true
                : false
        );

        client.get(`${API_URL}/v1/prices?skip=0&limit=1000&sort=internal_product_id`).then((result) => {
            // prepare stuff for EuiSuperSelect
            const priceOptions = result.data
                .filter((price) => price.shop_group_id === "03ca4eaa-ed3e-461e-9c4c-048d043da406")
                .map((price) => ({
                    text: price.internal_product_id,
                    value: price.id,
                }));
            priceOptions.unshift({ text: "", value: "-1" });
            setPrices(result.data.filter((price) => price.shop_group_id === "03ca4eaa-ed3e-461e-9c4c-048d043da406"));
            setPriceOptions(priceOptions);
            setPricesLoaded(true);
            setSelectedPrice("-1");

            if (product) {
                setSelectedPrice(product.price_id);
                client.get(`${API_URL}/v1/prices/${product.price_id}`).then((res) => {
                    setCannabisJsonPrices(res.data.cannabis);
                    setPreRolledJsonPrices(res.data.pre_rolled_joints);
                    setEdibleJsonPrices(res.data.edible);
                    setInternalIdPrefix(res.data.internal_product_id.substring(0, 3));
                });
            }
        });

        client.get(`${API_URL}/v1/categories?skip=0&limit=1000&sort=name`).then((result) => {
            const filterCategories = result.data.filter(
                (value) => value.shop_id === shopContext.shopId && value.cannabis
            );
            let categories = filterCategories.map((category) => {
                category.text = category.name;
                category.value = category.id;
                return category;
            });
            // Add blanco first option
            categories.unshift({ text: "", value: "-1" });

            setCategories(categories);
            setCategoriesLoaded(true);
            setSelectedCategory(product ? product.category_id : "-1");
        });
        client.get(`${API_URL}/v1/kinds/all`).then((result) => {
            const sortedData = result.data
                .filter(
                    (kind) =>
                        kind.shop_group_id === "03ca4eaa-ed3e-461e-9c4c-048d043da406" || kind.shop_group_id === null
                )
                .sort((a, b) => a.name.localeCompare(b.name));
            const kinds = sortedData.map((kind) => {
                kind.text = kind.shop_group_id ? `${kind.name}` : `${kind.name}  (Global)`;
                kind.value = kind.id;
                return kind;
            });
            // Add blanco first option
            kinds.unshift({ text: "", value: "-1" });
            let selectedKind = product ? product["kind_id" as keyof object] : "-1";
            if (preselectedWeed) {
                // console.log("Preselecting a wiet", preselectedWeed);
                selectedKind = preselectedWeed;
            }
            setKinds(kinds);
            setKindsLoaded(true);
            setSelectedKind(selectedKind);
        });
    }, [preselectedWeed, product, shopContext.shopId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedKind === "-1" || selectedCategory === "-1") {
            setMessage("Error");
            setMessageHelp(
                "Het form is niet compleet ingevuld. Prijs, categorie en product-soort moeten ingevuld zijn."
            );
            setErrors(true);
            setTimeout(() => {
                setMessage(undefined);
                setMessageHelp(undefined);
                setErrors(false);
            }, 3000);
            return;
        }

        let payload = {
            shop_id: shopContext.shopId,
            new: checkedNew,
            price_id: selectedPrice,
            product_id: null,
            category_id: selectedCategory,
            kind_id: selectedKind,
            use_half: checkedHalf,
            use_one: checkedOne,
            use_two_five: checkedTwoHalf,
            use_five: checkedFive,
            use_joint: checkedJoint,
            use_piece: checkedPiece,
        };
        if (!product) {
            payload["active"] = true;
        } else {
            payload["active"] = product["active" as keyof object];
        }

        setLoading(true);
        const kindName = kinds.find((kind) => kind.id === selectedKind)!.name;

        let internal_product_id = `${internalIdPrefix}: `;

        cannabisJsonPrices.forEach((price) => {
            internal_product_id = internal_product_id + `${price.label} voor ${price.price} - `;
        });
        preRolledJsonPrices.forEach((price) => {
            internal_product_id = internal_product_id + `${price.label} voor ${price.price} - `;
        });
        edibleJsonPrices.forEach((price) => {
            internal_product_id = internal_product_id + `${price.label} voor ${price.price} - `;
        });

        if (product) {
            if (
                !useExistingPrice ||
                (useExistingPrice &&
                    existingPrice &&
                    existingPrice.internal_product_id !==
                        internal_product_id.substring(0, internal_product_id.length - 3))
            ) {
                const pricePayload = {
                    edible: edibleJsonPrices,
                    pre_rolled_joints: preRolledJsonPrices,
                    cannabis: cannabisJsonPrices,
                    internal_product_id: internal_product_id.substring(0, internal_product_id.length - 3),
                    shop_group_id: "03ca4eaa-ed3e-461e-9c4c-048d043da406",
                };

                await client.post(`/v1/prices`, pricePayload).then((priceResult) => {
                    payload.price_id = priceResult.data.id;
                });
            }
            client
                .put(`/v1/shops-to-prices/${product.id}`, payload) // Todo: probably jsonify needed
                .then(() => {
                    setMessage(`Product ${kindName} is opgeslagen. Redirecting...`);
                    setMessageHelp(undefined);
                    setErrors(false);
                    if (!redirectToListEnabled) {
                        setTimeout(() => {
                            window.location.replace("/products/cannabis");
                        }, 3000);
                    } else {
                        resetFormState();
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    }
                })
                .catch((result) => {
                    if (result.message === "Request failed with status code 409") {
                        setMessage("Error");
                        setMessageHelp("U heeft dit product al toegevoegd aan deze shop!");
                        setErrors(true);
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    } else {
                        setMessage("Error");
                        setMessageHelp("Een form veld klopt niet.");
                        setErrors(true);
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    }
                });
        } else {
            if (
                !useExistingPrice ||
                (useExistingPrice &&
                    existingPrice &&
                    existingPrice.internal_product_id !==
                        internal_product_id.substring(0, internal_product_id.length - 3))
            ) {
                const pricePayload = {
                    edible: edibleJsonPrices,
                    pre_rolled_joints: preRolledJsonPrices,
                    cannabis: cannabisJsonPrices,
                    internal_product_id: internal_product_id.substring(0, internal_product_id.length - 3),
                    shop_group_id: "03ca4eaa-ed3e-461e-9c4c-048d043da406",
                };

                await client.post(`/v1/prices`, pricePayload).then((priceResult) => {
                    payload.price_id = priceResult.data.id;
                });
            }

            client
                .post(`/v1/shops-to-prices`, payload)
                .then(() => {
                    setMessage(`Product ${kindName} is toegevoegd.`);
                    setMessageHelp(undefined);
                    setErrors(false);
                    if (!redirectToListEnabled) {
                        setTimeout(() => {
                            window.location.replace("/products/cannabis");
                        }, 3000);
                    } else {
                        resetFormState();
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    }
                })
                .catch((result) => {
                    if (result.message === "Request failed with status code 409") {
                        setMessage("Error");
                        setMessageHelp("U heeft dit product al toegevoegd aan deze shop!");
                        setErrors(true);
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    } else {
                        setMessage("Error");
                        setMessageHelp("Een form veld klopt niet.");
                        setErrors(true);
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    }
                });
        }
    };

    const resetFormState = () => {
        setSelectedKind("-1");
        setSelectedPrice("-1");
        setSelectedCategory("-1");
        setEdibleJsonPrices([]);
        setPreRolledJsonPrices([]);
        setCannabisJsonPrices([]);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            console.log("Enter key pressed; submitting form");
            handleSubmit(event);
        }
    };

    const setJsonPrice = (
        index: number,
        jsonPrices: JsonPrice[],
        setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>,
        field: string,
        value: string | number | boolean
    ) => {
        const prices: JsonPrice[] = [...jsonPrices];
        prices[index][field as keyof object] = value;
        setJsonPrices(prices);
    };

    const addJsonPrice = (jsonPrices: JsonPrice[], setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>) => {
        const prices: JsonPrice[] = [...jsonPrices];
        prices.push({
            label: "",
            quantity: 0.0,
            price: 0.0,
            active: true,
        });
        setJsonPrices(prices);
    };

    const removeJsonPrice = (jsonPrices: JsonPrice[], setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>) => {
        if (jsonPrices.length > 0) {
            const prices: JsonPrice[] = [...jsonPrices];
            prices.pop();
            setJsonPrices(prices);
        }
    };

    const handleInternalIdPrefix = (input: string) => {
        input = input.replace(/\D/g, "");
        input = input.slice(0, 3);
        input = input.padStart(3, "0");
        setInternalIdPrefix(input);
    };

    function setSelectedBasePrice(priceId) {
        setSelectedPrice(priceId);
        if (priceId === "-1") {
            return;
        }
        const price = prices.find((p) => p.id === priceId)!;
        setExistingPrice(price);
        setInternalIdPrefix(price.internal_product_id.substring(0, 3));
        setCannabisJsonPrices(price.cannabis);
        setPreRolledJsonPrices(price.pre_rolled_joints);
        setEdibleJsonPrices(price.edible);
    }

    return (
        <EuiForm>
            {message && (
                <>
                    <EuiCallOut
                        title={message}
                        color={errors ? "danger" : "primary"}
                        iconType={errors ? "alert" : "notebookApp"}
                    >
                        {messageHelp && <p>{messageHelp}</p>}
                    </EuiCallOut>
                    <EuiSpacer />
                </>
            )}

            {!product && (
                <>
                    <EuiSwitch
                        label="Blijf op deze pagina na toevoegen"
                        checked={redirectToListEnabled}
                        onChange={(e) => {
                            setRedirectToListEnabled(e.target.checked);
                            localStorage.setItem("redirectToPriceList", e.target.checked.toString());
                        }}
                    />
                    <EuiSpacer />
                </>
            )}

            <EuiSwitch
                label="Publiceer product met label 'NEW'"
                checked={checkedNew}
                onChange={(e) => {
                    setCheckedNew(e.target.checked);
                    localStorage.setItem("markPriceAsNew", e.target.checked.toString());
                }}
            />
            <EuiSpacer />

            <EuiFormRow
                label="Categorie"
                labelAppend={<EuiText size="m">Selecteer een categorie</EuiText>}
                id="select-category"
                fullWidth
            >
                <EuiSelect
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    options={categories}
                    isLoading={!categoriesLoaded}
                    autoFocus={true}
                    onKeyDown={handleKeyDown}
                    fullWidth
                />
            </EuiFormRow>

            <EuiSpacer />

            <EuiFormRow
                label="Soort"
                labelAppend={
                    <EuiText size="m">Selecteer een cannabis soort. (Global zijn beschikbaar voor alle shops.)</EuiText>
                }
                id="select-kind"
                fullWidth
            >
                <EuiSelect
                    value={selectedKind}
                    onChange={(e) => setSelectedKind(e.target.value)}
                    options={kinds}
                    isLoading={!kindsLoaded}
                    onKeyDown={handleKeyDown}
                    fullWidth
                />
            </EuiFormRow>

            {!product && (
                <EuiSwitch
                    label="Bestaande kassa code als basis gebruiken?"
                    checked={!!useExistingPrice}
                    onChange={(e) => setUseExistingPrice(e.target.checked)}
                />
            )}

            <EuiSpacer />

            {useExistingPrice && (
                <EuiFormRow
                    label="Prijs"
                    labelAppend={<EuiText size="m">Selecteer een kassa code</EuiText>}
                    id="select-price"
                    fullWidth
                >
                    <EuiSelect
                        value={selectedPrice}
                        onChange={(e) => setSelectedBasePrice(e.target.value)}
                        options={priceOptions}
                        isLoading={!pricesLoaded}
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                        fullWidth
                    />
                </EuiFormRow>
            )}
            <>
                <EuiFormRow
                    label="Kassa Code Prefix"
                    labelAppend={<EuiText size="m">Vul een drie-cijferige kassa code prefix in.</EuiText>}
                    id="select-price"
                    fullWidth
                    isDisabled={useExistingPrice}
                >
                    <EuiFieldNumber
                        value={internalIdPrefix}
                        onChange={(e) => setInternalIdPrefix(e.target.value)}
                        onBlur={(e) => handleInternalIdPrefix(e.target.value)}
                        fullWidth
                    />
                </EuiFormRow>
                <EuiFormRow
                    label="Cannabis Prijzen"
                    labelAppend={<EuiText size="m">Vul de prijzen voor cannabis in.</EuiText>}
                    id="select-price"
                    fullWidth
                    isDisabled={useExistingPrice}
                >
                    <>
                        {cannabisJsonPrices.map((price, index) => (
                            <EuiFlexGroup
                                gutterSize="l"
                                direction="column"
                                style={{ borderBottom: "lightBlue 1px solid", paddingTop: "5px" }}
                                key={index}
                            >
                                <EuiFlexItem>
                                    <EuiText>Hoeveelheid (in grammen)</EuiText>
                                    <EuiFieldNumber
                                        disabled={useExistingPrice}
                                        step={0.01}
                                        value={price.quantity}
                                        fullWidth
                                        onChange={(e) => {
                                            setJsonPrice(
                                                index,
                                                cannabisJsonPrices,
                                                setCannabisJsonPrices,
                                                "quantity",
                                                e.target.value
                                            );
                                            setJsonPrice(
                                                index,
                                                cannabisJsonPrices,
                                                setCannabisJsonPrices,
                                                "label",
                                                `${e.target.value}g`
                                            );
                                        }}
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiText>Prijs (in Euro's)</EuiText>
                                    <EuiFieldNumber
                                        disabled={useExistingPrice}
                                        step={0.01}
                                        value={price.price}
                                        fullWidth
                                        onChange={(e) =>
                                            setJsonPrice(
                                                index,
                                                cannabisJsonPrices,
                                                setCannabisJsonPrices,
                                                "price",
                                                e.target.value
                                            )
                                        }
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiText>Label</EuiText>
                                    <EuiFieldText
                                        disabled={true}
                                        value={price.label}
                                        fullWidth
                                        onChange={(e) =>
                                            setJsonPrice(
                                                index,
                                                cannabisJsonPrices,
                                                setCannabisJsonPrices,
                                                "label",
                                                e.target.value
                                            )
                                        }
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiSwitch
                                        disabled={useExistingPrice}
                                        label="Actief?"
                                        checked={price.active}
                                        onChange={(e) =>
                                            setJsonPrice(
                                                index,
                                                cannabisJsonPrices,
                                                setCannabisJsonPrices,
                                                "active",
                                                e.target.checked
                                            )
                                        }
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        ))}
                        <EuiSpacer />
                        <EuiButton
                            disabled={useExistingPrice}
                            iconType={"plus"}
                            style={{ marginRight: "5px" }}
                            onClick={() => addJsonPrice(cannabisJsonPrices, setCannabisJsonPrices)}
                        >
                            Add
                        </EuiButton>
                        <EuiButton
                            iconType={"minus"}
                            disabled={cannabisJsonPrices.length < 1 || useExistingPrice}
                            onClick={() => removeJsonPrice(cannabisJsonPrices, setCannabisJsonPrices)}
                        >
                            Remove
                        </EuiButton>
                    </>
                </EuiFormRow>
                <EuiFormRow
                    label="Pre-Rolled Joints Prijzen"
                    labelAppend={<EuiText size="m">Vul de prijzen voor pre-rolled joints in.</EuiText>}
                    id="select-price"
                    fullWidth
                >
                    <>
                        {preRolledJsonPrices.map((price, index) => (
                            <EuiFlexGroup
                                gutterSize="l"
                                direction="column"
                                style={{ borderBottom: "lightBlue 1px solid", paddingTop: "5px" }}
                                key={index}
                            >
                                <EuiFlexItem>
                                    <EuiText>Hoeveelheid (in aantal joints)</EuiText>
                                    <EuiFieldNumber
                                        disabled={useExistingPrice}
                                        step={0.01}
                                        value={price.quantity}
                                        fullWidth
                                        onChange={(e) => {
                                            setJsonPrice(
                                                index,
                                                preRolledJsonPrices,
                                                setPreRolledJsonPrices,
                                                "quantity",
                                                e.target.value
                                            );
                                            setJsonPrice(
                                                index,
                                                preRolledJsonPrices,
                                                setPreRolledJsonPrices,
                                                "label",
                                                `${e.target.value}j`
                                            );
                                        }}
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiText>Prijs (in Euro's)</EuiText>
                                    <EuiFieldNumber
                                        disabled={useExistingPrice}
                                        step={0.01}
                                        value={price.price}
                                        fullWidth
                                        onChange={(e) =>
                                            setJsonPrice(
                                                index,
                                                preRolledJsonPrices,
                                                setPreRolledJsonPrices,
                                                "price",
                                                e.target.value
                                            )
                                        }
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiText>Label</EuiText>
                                    <EuiFieldText
                                        disabled={true}
                                        label="Edible Prijzen"
                                        value={price.label}
                                        fullWidth
                                        onChange={(e) =>
                                            setJsonPrice(
                                                index,
                                                preRolledJsonPrices,
                                                setPreRolledJsonPrices,
                                                "label",
                                                e.target.value
                                            )
                                        }
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiSwitch
                                        disabled={useExistingPrice}
                                        label="Actief?"
                                        checked={price.active}
                                        onChange={(e) =>
                                            setJsonPrice(
                                                index,
                                                preRolledJsonPrices,
                                                setPreRolledJsonPrices,
                                                "active",
                                                e.target.checked
                                            )
                                        }
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        ))}
                        <EuiSpacer />
                        <EuiButton
                            disabled={useExistingPrice}
                            iconType={"plus"}
                            style={{ marginRight: "5px" }}
                            onClick={() => addJsonPrice(preRolledJsonPrices, setPreRolledJsonPrices)}
                        >
                            Add
                        </EuiButton>
                        <EuiButton
                            iconType={"minus"}
                            disabled={preRolledJsonPrices.length < 1 || useExistingPrice}
                            onClick={() => removeJsonPrice(preRolledJsonPrices, setPreRolledJsonPrices)}
                        >
                            Remove
                        </EuiButton>
                    </>
                </EuiFormRow>
                <EuiFormRow
                    label="Edible Prijzen"
                    labelAppend={<EuiText size="m">Vul de prijzen voor edibles in.</EuiText>}
                    id="select-price"
                    fullWidth
                >
                    <>
                        {edibleJsonPrices.map((price, index) => (
                            <EuiFlexGroup
                                gutterSize="l"
                                direction="column"
                                style={{ borderBottom: "lightBlue 1px solid", paddingTop: "5px" }}
                                key={index}
                            >
                                <EuiFlexItem>
                                    <EuiText>Hoeveelheid (in aantal edibles)</EuiText>
                                    <EuiFieldNumber
                                        disabled={useExistingPrice}
                                        step={0.01}
                                        value={price.quantity}
                                        fullWidth
                                        onChange={(e) => {
                                            setJsonPrice(
                                                index,
                                                edibleJsonPrices,
                                                setEdibleJsonPrices,
                                                "quantity",
                                                e.target.value
                                            );
                                            setJsonPrice(
                                                index,
                                                edibleJsonPrices,
                                                setEdibleJsonPrices,
                                                "label",
                                                `${e.target.value}st`
                                            );
                                        }}
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiText>Prijs (in Euro's)</EuiText>
                                    <EuiFieldNumber
                                        disabled={useExistingPrice}
                                        step={0.01}
                                        value={price.price}
                                        fullWidth
                                        onChange={(e) =>
                                            setJsonPrice(
                                                index,
                                                edibleJsonPrices,
                                                setEdibleJsonPrices,
                                                "price",
                                                e.target.value
                                            )
                                        }
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiText>Label</EuiText>
                                    <EuiFieldText
                                        disabled={true}
                                        value={price.label}
                                        fullWidth
                                        onChange={(e) =>
                                            setJsonPrice(
                                                index,
                                                edibleJsonPrices,
                                                setEdibleJsonPrices,
                                                "label",
                                                e.target.value
                                            )
                                        }
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiSwitch
                                        disabled={useExistingPrice}
                                        label="Actief?"
                                        checked={price.active}
                                        onChange={(e) =>
                                            setJsonPrice(
                                                index,
                                                edibleJsonPrices,
                                                setEdibleJsonPrices,
                                                "active",
                                                e.target.checked
                                            )
                                        }
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        ))}
                        <EuiSpacer />
                        <EuiButton
                            disabled={useExistingPrice}
                            iconType={"plus"}
                            style={{ marginRight: "5px" }}
                            onClick={() => addJsonPrice(edibleJsonPrices, setEdibleJsonPrices)}
                        >
                            Add
                        </EuiButton>
                        <EuiButton
                            iconType={"minus"}
                            disabled={edibleJsonPrices.length < 1 || useExistingPrice}
                            onClick={() => removeJsonPrice(edibleJsonPrices, setEdibleJsonPrices)}
                        >
                            Remove
                        </EuiButton>
                    </>
                </EuiFormRow>
            </>

            <EuiSpacer />

            <EuiFormRow>
                <EuiFlexGroup>
                    {!product && (
                        <EuiFlexItem>
                            {" "}
                            <EuiButton fill color="danger" onClick={resetFormState} disabled={false}>
                                Reset
                            </EuiButton>
                        </EuiFlexItem>
                    )}

                    <EuiFlexItem>
                        {useExistingPrice ? (
                            <EuiButton
                                fill
                                onClick={(e) => handleSubmit(e)}
                                disabled={selectedKind === "-1" || selectedCategory === "-1" || selectedPrice === "-1"}
                                type="submit"
                                isLoading={loading}
                            >
                                {product ? "Opslaan" : "Toevoegen"}
                            </EuiButton>
                        ) : (
                            <EuiButton
                                fill
                                onClick={(e) => handleSubmit(e)}
                                disabled={
                                    selectedKind === "-1" ||
                                    selectedCategory === "-1" ||
                                    (edibleJsonPrices.length < 1 &&
                                        preRolledJsonPrices.length < 1 &&
                                        cannabisJsonPrices.length < 1)
                                }
                                type="submit"
                                isLoading={loading}
                            >
                                {product ? "Opslaan" : "Toevoegen"}
                            </EuiButton>
                        )}
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
        </EuiForm>
    );
}

export default CannabisFormNew;
