import React from "react";

export const BannerAll = ({ shopId }: { shopId: string }) => {
    switch (shopId) {
        case "470f3f5a-e7b9-43a5-bbfd-2ffce8c161e4":
            return (
                <div className="bannerAll">
                    <img alt="Banner logo" className="bannerLogo" src="/images/pricelist/deSteegLogoColor.png" />
                </div>
            );
        case "bbc0cfa7-e230-4338-817a-55190debdac0":
            return (
                <div className="bannerAll">
                    <img alt="Banner logo" className="bannerLogo" src="/images/pricelist/maryJaneLogo.png" />
                </div>
            );
        case "f155b35a-14a1-4866-867f-93fe21e9769b":
            return (
                <div className="bannerAll">
                    <img alt="Banner logo" className="bannerLogo" src="/images/pricelist/roundabout69_logo_black.png" />
                </div>
            );
        case "a08a13e2-a31b-4b6d-b2b4-0491cb3d2227":
            return (
                <div className="bannerAll">
                    <img alt="Banner front" className="bannerFront" src="/images/pricelist/bannerFront.png" />
                    <img alt="Banner logo" className="bannerLogo" src="/images/pricelist/logo.png" />
                    <img alt="Banner back" className="bannerBack" src="/images/pricelist/bannerMissouri.png" />
                </div>
            );
        case "ddd70853-5e9d-45ff-82b3-24c9d81d4e32":
            return (
                <div className="bannerAll">
                    <img alt="Banner front" className="bannerFront" src="/images/pricelist/bannerEasyGoingLeft.png" />
                    <img alt="Banner logo" className="bannerLogo" src="/images/pricelist/bannerEasyGoingCenter.png" />
                    <img alt="Banner back" className="bannerBack" src="/images/pricelist/bannerEasyGoingRight.png" />
                </div>
            );
        default:
            return (
                <div className="bannerAll">
                    <img alt="Banner front" className="bannerFront" src="/images/pricelist/bannerFront.png" />
                    <img alt="Banner logo" className="bannerLogo" src="/images/pricelist/logo.png" />
                    <img alt="Banner back" className="bannerBack" src="/images/pricelist/bannerBack.png" />
                </div>
            );
    }
};
