import { EuiFormRow, EuiMarkdownEditor, EuiText } from "@elastic/eui";
import { FieldProps } from "lib/uniforms-surfnet/src/types";
/*
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import React from "react";
import { connectField, filterDOMProps } from "uniforms";

export type LongTextFieldProps = FieldProps<string, {}, HTMLTextAreaElement>;

function MarkdownText({
    id,
    label,
    description,
    onChange,
    placeholder,
    readOnly,
    value,
    error,
    errorMessage,
    ...props
}: LongTextFieldProps) {
    return (
        <section {...filterDOMProps(props)}>
            <EuiFormRow
                label={label}
                labelAppend={<EuiText size="m">{description}</EuiText>}
                error={errorMessage}
                isInvalid={error}
                id={id}
                fullWidth
            >
                <EuiMarkdownEditor
                    onChange={onChange}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    value={value ?? ""}
                    aria-labelledby={id} //
                />
            </EuiFormRow>
        </section>
    );
}

export default connectField(MarkdownText, { kind: "leaf" });
