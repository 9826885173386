import axios from "axios";
import { SortingRule } from "react-table";

import API_URL from "../Constants";
import { setFlash } from "../utils/Flash";
import { FilterArgument } from "../utils/types";
import { resetAppState } from "../utils/Utils";

const axiosInstance = axios.create({
    baseURL: API_URL,
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        if (error.response.status === 403) {
            console.log("Session expired resetting local storage ");
            resetAppState();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export function setTableUser(token: String) {
    if (token) {
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
}

export const cancel = axios.CancelToken.source();

function getHeaders(eTag?: string | null) {
    const ifNoneMatchHeader = eTag ? { "If-None-Match": eTag } : {};
    return { ...ifNoneMatchHeader };
}

export function filterableEndpoint<T>(
    path: string,
    startRow: number | null,
    endRow: number | null,
    sortBy: SortingRule<string>[] | null,
    filterBy: FilterArgument[],
    eTag?: string | null
) {
    const requestHeaders = getHeaders(eTag);

    let params = new URLSearchParams();

    if (startRow !== null && endRow !== null) {
        params.append("skip", String(startRow));
        params.append("limit", String(endRow - startRow));
    }
    if (sortBy !== null) {
        for (const s of sortBy) {
            params.append("sort", `${s.id}:${s.desc ? "DESC" : "ASC"}`);
        }
    }
    if (filterBy !== null) {
        for (const f of filterBy) {
            params.append("filter", `${f.id}:${f.values}`);
        }
    }
    const extractResponseHeaders = (headers: any) => {
        let etag: string | undefined = headers["etag"];

        if (etag?.startsWith('W/"')) {
            etag = etag.slice(3, -1);
        }

        const contentRange: string | undefined = headers["content-range"];
        const total = contentRange ? parseInt(contentRange.split("/")[1], 10) : 99;
        return [total, etag];
    };
    return axiosInstance
        .get(path, {
            headers: requestHeaders,
            baseURL: API_URL + "/v1/",
            params,
            validateStatus: (status: number) => (status >= 200 && status < 300) || status === 304,
            cancelToken: cancel.token,
        })
        .then(
            (response) => {
                switch (response.status) {
                    case 200:
                        return [response.data, ...extractResponseHeaders(response.headers)] as [
                            T[],
                            number,
                            string | null
                        ];
                    case 304:
                        return [null, ...extractResponseHeaders(response.headers)] as [null, number, string | null];
                    default:
                        return Promise.reject(response);
                }
            },
            (error) => {
                if (axios.isCancel(error)) {
                    console.log(`Request canceled: ${error.message}`);
                    // don't set a message to flash, we are canceled.
                } else if (error.response) {
                    setFlash(
                        `${error.config.baseURL}${path} returned with HTTP status ${error.response.status}: ${error.response.statusText}`,
                        "error"
                    );
                } else if (error.request) {
                    setFlash(`${error.config.baseURL}${path} failed with status ${error.request.status}`, "error");
                } else {
                    setFlash(error.message, "error");
                }

                return Promise.reject(error);
            }
        );
}
