/*
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import "./Forms.scss";

import { EuiPage, EuiPageSection } from "@elastic/eui";
import React from "react";

import CRUDResourceItem from "../components/CRUDResourceItem";
import { ResourceType } from "../utils/types";

export default function ManageCategories() {
    return (
        <EuiPage>
            <EuiPageSection>
                <CRUDResourceItem type={ResourceType.Category} extraQueries={["main-categories", "tags"]} />
            </EuiPageSection>
        </EuiPage>
    );
}
