import { EuiFlexItem } from "@elastic/eui";
import React from "react";

export const Badge = ({ letter, styles, ...props }: { letter: string; styles: any }) => {
    return (
        <EuiFlexItem css={[styles[letter], styles.badge]}>
            <EuiFlexItem css={styles.letter}>{letter.toUpperCase()}</EuiFlexItem>
        </EuiFlexItem>
    );
};
