import { css } from "@emotion/react";
import React, { useEffect, useMemo, useRef } from "react";

import { Badge } from "../../../components/Badge";
import { COLORS } from "../../../stylesheets/emotion/vars";
import { getTotalHeightInVh } from "../../../utils/DynamicPricelistUtils";

export type DeSteegPriceModel = {
    active: boolean;
    label: string;
    price: number;
    product_link: string;
    quantity: number;
};

//Logos urls
const BANNER_LOGO = "/images/pricelist/deSteegLogoColor.png";
const BACKGROUND_VIDEO = "/images/pricelist/smokeBackground.mp4";

const MAX_ROWS_PER_COLUMN = 20; // Maximum rows per each column, this value affects the number of columns in the table

// Table Logo Header settings: VH = Viewport Height (1vh = 1% of the screen height)
const LOGO_HEADER_HEIGHT_VH = 0;
const LOGO_HEADER_PADDING_VH = 0;
const ITEM_NAME_FONT_SIZE_VH = 1.8;

// Padding for each table column
const TABLE_COLUMN_PADDING_VH = 0.6;
const COLOR_GLOW = COLORS.deSteegGlow;
const COLOR_GLOW2 = COLORS.deSteegGlow2;

// Calculations
const totalHeightInVh = getTotalHeightInVh(LOGO_HEADER_HEIGHT_VH, LOGO_HEADER_PADDING_VH, TABLE_COLUMN_PADDING_VH);
const rowHeightInVh = totalHeightInVh / MAX_ROWS_PER_COLUMN;

// Excluded categories
const excludedCategories = ["PREVIEW"];

// Styling
const STYLES = css`
    .pricelistBody {
        background-color: ${COLORS.lightestPrimary};
    }

    .logoBackground {
        background-image: url(${BANNER_LOGO});
        background-size: contain;
        background-position: center;
        z-index: 0;
        position: fixed;
        background-repeat: no-repeat;
        width: 40%;
        height: 40%;

        /* Center the logo both vertically and horizontally */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .pricelistLogoHeader {
        background: ${COLORS.deSteegColor};
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${LOGO_HEADER_HEIGHT_VH};
        padding-block: ${LOGO_HEADER_PADDING_VH}vh;
        z-index: 3;
    }

    .bannerBack {
        height: ${LOGO_HEADER_HEIGHT_VH - 1.5}vh;
    }

    .bannerFront {
        height: ${LOGO_HEADER_HEIGHT_VH - 1.5}vh;
    }

    .bannerLogo {
        height: ${LOGO_HEADER_HEIGHT_VH}vh; // The logo height should be the same as the header height
    }

    // Pricelist table styles

    .tableColumn {
        background: ${COLORS.pricelistBackground};
        color: white;
        width: 100%;
        padding: ${TABLE_COLUMN_PADDING_VH}vh;
    }

    .rowDivider {
        border: 0.01vh solid;
        border-color: ${COLORS.deSteegColor};
        z-index: 3;
    }

    .tableContainer {
        position: relative;
        display: flex;
    }

    .tableRow {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 2;
    }

    // .tableRowContainer {
    //    max-height: ${rowHeightInVh / 1.05}vh;
    // }

    .titleRow {
        color: ${COLORS.easyGoingHorecaCategory};
        font-size: 1.5vw; // If the category title font size is too big, the text will be cut off
        font-weight: bold;
        text-shadow: 0 0 0.04vw #000000, 0 0 0.05vw ${COLOR_GLOW}, 0 0 0.06vw ${COLOR_GLOW}, 0 0 0.07vw ${COLOR_GLOW2},
            0 0 0.08vw ${COLOR_GLOW}, 0 0 0.09vw ${COLOR_GLOW2};
    }

    @keyframes pulsate {
        100% {
            /* A slightly smaller blur radius */
            text-shadow: 0 0 0.04vw #000000, 0 0 0.05vw ${COLOR_GLOW}, 0 0 0.06vw ${COLOR_GLOW},
                0 0 0.07vw ${COLOR_GLOW2}, 0 0 0.08vw ${COLOR_GLOW}, 0 0 0.09vw ${COLOR_GLOW2};
        }
        0% {
            /* Larger blur radius */
            text-shadow: 0 0 0.04vw #000000, 0 0 0.18vw ${COLOR_GLOW}, 0 0 0.23vw ${COLOR_GLOW},
                0 0 0.3vw ${COLOR_GLOW2}, 0 0 0.44vw ${COLOR_GLOW}, 0 0 0.6vw ${COLOR_GLOW2};
        }
    }

    .namePriceRow {
        padding-inline: 0.1vh;
        font-size: ${ITEM_NAME_FONT_SIZE_VH}vh;
    }

    .name {
        font-weight: bold;
    }

    .videoContainer {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.85;
        object-fit: fill;
    }

    .pricePart {
        display: flex;
        padding-block: 0.2vh;
    }

    .priceQuantityContainer {
        margin-right: ${TABLE_COLUMN_PADDING_VH}vw;
        display: flex;
        gap: 0.7vh;
    }

    .priceQuantityItem {
        //flex: 0;
        flex-grow: 1;
        text-align: right;
        width: 1.75vw;
    }

    .quantity {
        text-align: left;
        font-size: 1.4vh;
        margin-bottom: 0.3vh;
    }

    .price {
        text-align: left;
        font-size: 1.45vh;
        font-weight: bold;
        text-shadow: 0.1vw 0.1vw 0.15vw black, 0 0 0.75vw rgba(245, 243, 151, 0.25), 0 0 0.5vw #203a20;
    }

    .kindIcon {
        font-size: 2.2vh;
        text-shadow: 0.1vw 0.1vw 0.15vw black, 0 0 0.75vw rgba(255, 255, 255, 0.25), 0 0 0.5vw #203a20;
    }

    .starIcon {
        font-size: 1.9vh;
        text-shadow: 0.1vw 0.1vw 0.15vw black, 0 0 0.75vw rgba(255, 255, 255, 0.25), 0 0 0.5vw #203a20;
    }

    .legendContainer {
        width: 100%;
        display: flex;
        gap: 1vh;
    }

    .chisGroup {
        flex: 2;
        padding: 1vh 1vh 1.45vh;
        justify-content: center;
        align-items: center;
        background: ${COLORS.deSteegSecondaryColor}20;
        display: flex;
        font-size: 2vh;
        color: white;
        z-index: 3;
        gap: 3vh;
        // border: 0.1vh solid ${COLORS.deSteegColor};
        border-radius: 0.75vh;
    }

    .iconGroup {
        flex: 1;
        padding: 1vh 1vh 1.45vh;
        justify-content: center;
        align-items: center;
        background: ${COLORS.deSteegColor}20;
        // border: 0.1vh solid ${COLORS.deSteegSecondaryColor};
        border-radius: 0.75vh;
        display: flex;
        font-size: 2vh;
        color: white;
        z-index: 3;
        gap: 1.2vh;
    }

    .legendItem {
        align-items: center;
        font-size: 1.5vh;
        display: flex;
        gap: 0.6vh;
    }

    .kindBadge {
        padding-inline: 0.5vh;
    }

    .badgeWrapper {
        maxwidth: 1.5vw;
    }

    .iconWrapper {
        font-size: 2.5vh;
    }

    .legendColumn {
        display: flex;
        flex-direction: column;
        gap: 1vh;
    }
`;

export const deSteegBadges = {
    i: css`
        background-color: ${COLORS.badgeIKind};
        border: ${COLORS.badgeIKindBorder};
    `,
    s: css`
        background-color: ${COLORS.badgeSKind};
        border: ${COLORS.badgeSKindBorder};
    `,
    c: css`
        background-color: ${COLORS.badgeCKind};
        border: ${COLORS.badgeCKindBorder};
    `,
    h: css`
        background-color: ${COLORS.badgeHKind};
        border: ${COLORS.badgeHKindBorder};
    `,
    badge: css`
        border-radius: 15%;
    `,
    letter: css`
        width: 1.25vw;
        height: 1.25vw;
        font-size: 1vw;
        text-align: center;
        color: ${COLORS.badgeLetter};
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};

const RenderBackground = () => {
    const videoSrc = useMemo(() => BACKGROUND_VIDEO, []);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        return () => {
            if (videoElement) {
                videoElement.pause();
                videoElement.currentTime = 0;
                videoElement.load();
            }
        };
    }, []);

    return (
        <div>
            <video ref={videoRef} className="videoContainer" autoPlay loop muted src={videoSrc} />
            <div className="logoBackground" />
        </div>
    );
};
const PricelistBackground = React.memo(RenderBackground);

const PricelistLegend = () => {
    return (
        <div className="legendContainer">
            <div className="iconGroup">
                <div className="legendColumn">
                    <div className="legendItem">
                        <div className="iconWrapper">
                            <p>⭐</p>
                        </div>
                        <p>New</p>
                    </div>
                    <div className="legendItem">
                        <div className="iconWrapper">
                            <p>🔥</p>
                        </div>
                        <p>Strong</p>
                    </div>
                </div>
            </div>
            <div className="chisGroup">
                <div className="legendColumn">
                    <div className="legendItem">
                        <div className="badgeWrapper">
                            <Badge letter={"i"} styles={deSteegBadges} />
                        </div>
                        <p>Indica</p>
                    </div>
                    <div className="legendItem">
                        <div className="badgeWrapper">
                            <Badge letter={"s"} styles={deSteegBadges} />
                        </div>
                        <p className={"verticalText"}>Sativa</p>
                    </div>
                </div>
                <div className="legendColumn">
                    <div className="legendItem">
                        <div className="badgeWrapper">
                            <Badge letter={"c"} styles={deSteegBadges} />
                        </div>
                        <p>CBD</p>
                    </div>
                    <div className="legendItem">
                        <div className="badgeWrapper">
                            <Badge letter={"h"} styles={deSteegBadges} />
                        </div>
                        <p>Hybrid</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const deSteegCannabisSettings = {
    styles: STYLES,
    bannersURLs: {
        bannerLogo: BANNER_LOGO,
        bannerFront: undefined,
        bannerBack: undefined,
    },
    maxRowsPerColumn: MAX_ROWS_PER_COLUMN,
    rowHeight: rowHeightInVh,
    badgesStyles: deSteegBadges,
    excludedCategories: excludedCategories,
    renderBackground: <PricelistBackground />,
    renderLegend: <PricelistLegend />,
};
