import { css } from "@emotion/react";

import { COLORS } from "../../stylesheets/emotion/vars";
import fontBold from "../font/agrandir-narrow-bold.ttf";
import font from "../font/Agrandir-Regular.otf";
import fontBlack from "../font/Anton-Regular.ttf";
import background from "../images/mary-jane/mary-jane-background-mirror-3.png";

export const maryJaneStyle = css`
    @font-face {
        font-family: "agrandir";
        src: url("../${font}");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "agrandir-bold";
        src: url("${fontBold}");
        font-weight: bold 1;
        font-style: normal;
    }
    @font-face {
        font-family: "anton-black";
        src: url("${fontBlack}");
        font-weight: bold 2;
        font-style: normal;
    }

    body {
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .bannerAll {
        display: grid;
        grid-template-columns: auto;
    }

    .bannerLogo {
        width: 8vw;
        position: relative;
        margin: 1vw auto 1.5vw auto;
        z-index: 1000;
    }

    .menukaart {
        width: 100vw;
        height: 100vw;
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .menulinks {
        width: 45vw;
        height: 45vw;
        margin-left: 2.5vw;
        margin-top: 2vw;
        position: relative;
    }

    .menurechts {
        width: 45vw;
        height: 45vw;
        margin-left: 3.5vw;
        margin-top: 2vw;
        position: relative;
    }

    .info {
        display: flex;
        flex-direction: row-reverse;
    }

    .row {
        display: flex;
        padding-top: 0.1vw;
        position: relative;
    }

    .row::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${COLORS.maryJaneRowColor};
        opacity: 0.72;
        z-index: -1; /* behind the text */
    }

    .row:nth-child(even) {
        display: flex;
        padding-top: 0.1vw;
        position: relative;
    }

    .row:nth-child(even)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${COLORS.maryJaneRowColor2};
        opacity: 0.72;
        z-index: -1; /* behind the text */
    }

    .row:nth-last-child(1) {
        border-bottom-left-radius: 1vw;
        border-bottom-right-radius: 1vw;
    }

    .row:first-child {
        border-top-left-radius: 1vw;
        border-top-right-radius: 1vw;
    }

    .rowContent {
        padding: 0.19vw 0;
        display: flex;
    }

    .productkind {
        color: white;
        font-size: 0.9vw;
        margin-top: 0.3vw;
        width: 3vw;
        margin-left: 2vw;
    }

    .banner {
        width: 100%;
        margin-left: 8vw;
        position: relative;
    }

    @keyframes moveBackground {
        0% {
            background-position: 0 0;
        }
        12.5% {
            background-position: 40% 20%;
        }
        25% {
            background-position: 20% 60%;
        }
        37.5% {
            background-position: 60% 80%;
        }
        50% {
            background-position: 100% 60%;
        }
        62.5% {
            background-position: 60% 40%;
        }
        75% {
            background-position: 20% 60%;
        }
        87.5% {
            background-position: 40% 20%;
        }
        100% {
            background-position: 0 0;
        }
    }

    .pagebackground {
        width: 100%;
        height: 100vh;
        background-image: url("${background}");
        background-size: 200% 200%; /* Adjust the zoom level as needed */
        position: relative;
        overflow: hidden;
        animation: moveBackground 50s linear infinite;
        z-index: -10000;
    }

    .redbg {
        position: absolute;
    }

    .logo {
        position: absolute;
        margin-left: 20%;
        margin-top: -6.5%;
        width: 60%;
        opacity: 0;
        animation: rotation 25s 20s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
    }

    .marginTopTitle {
        margin-top: -4vw;
    }

    .title {
        font-family: "anton-black", "sans-serif";
        margin-left: 1.5vw;
        font-size: 1.3vw;
        margin-top: 1.25vw;
        position: relative;
        width: 20.3vw;
        text-transform: uppercase;
        color: ${COLORS.maryJaneColor};
    }

    .titleNotAtTop {
        font-family: "anton-black", "sans-serif";
        margin-left: 1.5vw;
        font-size: 1.3vw;
        margin-top: 1vw;
        position: relative;
        width: 20.3vw;
        text-transform: uppercase;
        color: ${COLORS.maryJaneColor};
    }

    .particles {
        position: absolute;
        margin-left: 50vw;
        width: 300px;
    }

    .btn {
        position: absolute;
    }

    .kindBadge {
        margin: auto;
        padding: 0 0.75vw;
    }

    .nameStar {
        display: flex;
    }

    .newBadge {
    }

    .nameprice {
        font-size: 1vw;
        font-family: "agrandir-bold", "sans-serif";
        text-transform: uppercase;
        margin-top: 0.1vw;
        margin-bottom: -0.1vw;
        color: ${COLORS.maryJaneColor};
    }

    .description {
        font-size: 0.6vw;
        font-family: "agrandir", "sans-serif";
        color: ${COLORS.maryJaneDescriptionColor};
        margin: 0 auto;
        display: none;
    }

    .kind {
        font-size: 0.5vw;
        position: relative;
        margin-left: -2vw;
        margin-top: 0.5vw;
        width: 5vw;
        color: ${COLORS.maryJaneColor};
    }

    .priceContainer {
        position: relative;
        left: 1.4vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .price {
        font-size: 1.05vw;
        font-family: "agrandir", "sans-serif";
        width: 4.45vw;
        color: ${COLORS.maryJaneDescriptionColor};
        font-weight: bold;
    }

    .priceHeader {
        position: relative;
        left: 1.35vw;
        margin-left: -0.55vw;
        font-size: 1vw;
        margin-top: -1.5vw;
        width: 4.8vw;
        color: ${COLORS.maryJaneColor};
    }

    .filler {
        flex-grow: 1;
    }

    .svg-inline--fa {
        margin-left: 20px;
        margin-top: 8px;
        font-size: 30px;
    }
`;
