import { EuiFlexGroup, EuiFlexItem, EuiPanel } from "@elastic/eui";
import React from "react";

import { NameWithDescription } from "../../utils/types";
import ProductInfoTextEditor from "../editors/ProductInfoTextEditor";
import { readonly_rows } from "./utils";

interface EditProductInfoProps {
    prInfo: NameWithDescription[];
    save: (name: string, key?: string) => void;
    editingProductInfo: boolean[];
    changeEditStates: (value: boolean, index: number, type: string) => void;
}

export const EditProductInfo = ({ prInfo, save, editingProductInfo, changeEditStates }: EditProductInfoProps) => {
    return (
        <>
            {prInfo.map((item, index) => {
                const readonly = readonly_rows.includes(item.title);

                return (
                    <EuiPanel
                        key={item.title}
                        color="transparent"
                        paddingSize="xs"
                        className={readonly ? "panelInfoReadOnly" : "panelInfo"}
                    >
                        <EuiFlexGroup alignItems="center">
                            <EuiFlexItem grow={6}>
                                <ProductInfoTextEditor
                                    name={item.title}
                                    initialValue={item.description}
                                    readonly={readonly}
                                    onSave={save}
                                    onEdit={() => console.log("edit")}
                                    setEditing={(value: boolean) => changeEditStates(value, index, "info")}
                                    editing={editingProductInfo[index]}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiPanel>
                );
            })}
        </>
    );
};
