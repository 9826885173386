import { EuiComboBoxOptionOption } from "@elastic/eui/src/components/combo_box/types";
import { css } from "@emotion/react";
import { useMutation } from "react-query";

import client from "../../api/Client";
import { KindIcon } from "../../utils/KindIcons";
import { KindForTable, KindRelation, ProductForTable } from "../../utils/types";

export const getObjectTypeFromRelation = (relation: KindRelation) => {
    if (relation.hasOwnProperty("flavor_id")) {
        return "flavor";
    } else if (relation.hasOwnProperty("strain_id")) {
        return "strain";
    } else if (relation.hasOwnProperty("tag_id")) {
        return "tag";
    }
};

export const mapFlagsDataToSelectedOptions = (
    allFlags: KindIcon[],
    flags: string[]
): EuiComboBoxOptionOption<string>[] => {
    // Create a map for quick lookup of flags by emoji
    const flagMap = new Map(allFlags.map((flag) => [flag.emoji, flag]));

    // Iterate over the ordered flags array and map to options
    return flags
        .map((emoji) => {
            const flag = flagMap.get(emoji);
            if (flag) {
                return {
                    label: `${flag.emoji} ${flag.name}`,
                    value: flag.emoji,
                    css: css({
                        ".euiComboBoxOption__content": { fontSize: 18 },
                    }),
                };
            }
            return {
                label: emoji,
                value: emoji,
                css: css({
                    ".euiComboBoxOption__content": { fontSize: 18 },
                }),
            };
        })
        .filter((option) => option !== null); // Remove any null values from the result
};

export const mapKindIconsToOptions = (kindIcons: KindIcon[]): EuiComboBoxOptionOption<string>[] => {
    return kindIcons.map((kindIcon) => ({
        label: kindIcon.emoji + "  " + kindIcon.name,
        value: kindIcon.emoji,
        css: css({ ".euiComboBoxOption__content": { fontSize: 18 } }),
    }));
};

export const readonly_rows = ["created_at", "modified_at"];

export const useProductDetailMutations = (urlType: "kinds" | "products") => {
    const update_kinds_relation_mutation = useMutation((relation: KindRelation) => {
        const objectType = getObjectTypeFromRelation(relation);
        const data = {
            kind_id: relation.kind_id,
            [`${objectType}_id`]: relation[`${objectType}_id` as keyof KindRelation],
        };
        if (objectType === "tag") {
            data.amount = relation.amount;
        }
        return client.put(`/v1/kinds-to-${objectType}s/${relation?.id}`, data);
    });
    const create_kinds_relation_mutation = useMutation((relation: KindRelation) => {
        const objectType = getObjectTypeFromRelation(relation);
        const data = {
            kind_id: relation.kind_id,
            [`${objectType}_id`]: relation[`${objectType}_id` as keyof KindRelation],
        };
        if (objectType === "tag") {
            data.amount = relation.amount;
        }
        return client.post(`/v1/kinds-to-${objectType}s`, data);
    });

    const delete_kinds_relation_mutation = useMutation((relation: KindRelation) => {
        const objectType = getObjectTypeFromRelation(relation);
        return client.delete(`/v1/kinds-to-${objectType}s/${relation?.id}`);
    });

    const update_product_mutation = useMutation((product: ProductForTable | KindForTable) =>
        client.put(`/v1/${urlType}/${product?.id}`, product)
    );

    return {
        update_kinds_relation_mutation,
        create_kinds_relation_mutation,
        delete_kinds_relation_mutation,
        update_product_mutation,
    };
};
