/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import { Action, Order } from "utils/types";

export function orderActionOptions(
    order: Order,
    completeAction: (e: React.MouseEvent<HTMLButtonElement>) => void,
    cancelAction: (e: React.MouseEvent<HTMLButtonElement>) => void,
    deleteAction: (e: React.MouseEvent<HTMLButtonElement>) => void
): Action[] {
    //TODO scope on the context of logged in-user
    const complete = {
        euiIcon: "check",
        label: "Order afgehandeld",
        action: completeAction,
    };
    const cancel = {
        euiIcon: "stop",
        label: "Order annuleren",
        action: cancelAction,
    };
    const _delete = {
        euiIcon: "trash",
        label: "Order wissen",
        action: deleteAction,
        danger: true,
    };
    let options = [];

    switch (order.status) {
        case "pending":
            options = [complete, cancel];
            // if (process.is_task) {
            //     options.push(_delete);
            // }
            break;
        case "complete":
            options = [complete, cancel, _delete];
            break;
        case "cancelled":
            options = [complete, cancel, _delete];
            break;
        default:
            throw new Error(`Unknown status: ${order.status}`);
    }

    return options;
}
