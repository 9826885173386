/*
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiGlobalToastList,
    EuiIcon,
    EuiPage,
    EuiPageSection,
    EuiPanel,
} from "@elastic/eui";
import Explain from "components/Explain";
import { lowerCase } from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";

import client, { get_product_by_id_generic } from "../api/Client";
import EditPictures from "../components/ProductDetail/EditPictures";
import ProductInfo from "../components/ProductDetail/ProductInfo";
import ShowPictures from "../components/ProductDetail/ShowPictures";
import { getObjectTypeFromRelation, useProductDetailMutations } from "../components/ProductDetail/utils";
import GlobalToastContext from "../contextProviders/GlobalToastProvider";
import { KindForTable, KindRelation, MutationType, ProductForTable, ResourceType } from "../utils/types";

export default function ProductDetailPage() {
    const { type, id } = useParams();
    const urlType = type === "cannabis" ? "kinds" : "products";
    const { data, isLoading } = useQuery([urlType, id], () => get_product_by_id_generic(urlType, id).then(), {
        refetchOnWindowFocus: false,
        onError: (err) => {
            addErrorToast(err);
        },
    });

    const [showExplanation, setShowExplanation] = useState(false);
    const [pictureUploadVisible, setPictureUploadVisible] = useState(false);
    const history = useHistory();
    const queryClient = useQueryClient();
    const { toasts, addErrorToast, addToastHandler, removeToast } = useContext(GlobalToastContext);
    const {
        update_kinds_relation_mutation,
        create_kinds_relation_mutation,
        delete_kinds_relation_mutation,
        update_product_mutation,
    } = useProductDetailMutations(urlType);

    const updateProduct = useCallback(
        (product: ProductForTable | KindForTable, updateType: MutationType, objectType?: string) => {
            update_product_mutation.mutate(product, {
                onSuccess: () => {
                    queryClient.setQueryData([urlType, product.id], product);
                    setPictureUploadVisible(false);
                    addToastHandler(updateType, objectType);
                },
                onError: (error: any) => {
                    queryClient.refetchQueries([urlType, product.id]);
                    addErrorToast(error);
                },
            });
        },
        [addErrorToast, addToastHandler, queryClient, update_product_mutation, urlType]
    );

    const updateRelation = useCallback(
        (relation: KindRelation, updateType: MutationType) => {
            let objectType = getObjectTypeFromRelation(relation);

            if (updateType === MutationType.Edited) {
                update_kinds_relation_mutation.mutate(relation, {
                    onSuccess: (successData) => {
                        let newData = { ...data };
                        let object = newData[`${objectType}s`][relation.index];
                        object.name = relation.name;
                        objectType === "flavor" && (object.color = relation.color);
                        objectType === "tag" && (object.amount = relation.amount);
                        newData[`${objectType}s`][relation.index] = object;
                        addToastHandler(updateType, objectType);
                        queryClient.setQueryData(["kinds", data.id], newData);
                    },
                    onError: (error: any) => {
                        queryClient.refetchQueries(["kinds", data.id]);
                        addErrorToast(error);
                    },
                });
            } else if (updateType === MutationType.Added) {
                create_kinds_relation_mutation.mutate(relation, {
                    onSuccess: (successData) => {
                        client
                            .get(
                                `/v1/kinds-to-${objectType}s/get_relation_id?${objectType}_id=${
                                    relation[`${objectType}_id` as keyof KindRelation]
                                }&kind_id=${relation.kind_id}`
                            )
                            .then((response) => {
                                let newData = { ...data };
                                let object = {
                                    name: relation.name,
                                    id: response.data,
                                };
                                objectType === "flavor" &&
                                    (object = {
                                        ...object,
                                        ...{
                                            color: relation.color,
                                            icon: lowerCase(relation.name),
                                        },
                                    });
                                objectType === "tag" && (object = { ...object, ...{ amount: relation.amount } });
                                newData[`${objectType}s`].push(object);
                                addToastHandler(updateType, objectType);
                                queryClient.setQueryData(["kinds", data.id], newData);
                            })
                            .catch((error) => {
                                queryClient.refetchQueries(["kinds", data.id]);
                                addErrorToast(error);
                            });
                    },
                    onError: (error: any) => {
                        queryClient.refetchQueries(["kinds", data.id]);
                        addErrorToast(error);
                    },
                });
            } else if (updateType === MutationType.Deleted) {
                delete_kinds_relation_mutation.mutate(relation, {
                    onSuccess: (successData) => {
                        let newData = { ...data };
                        newData[`${objectType}s`].splice(relation.index, 1);
                        addToastHandler(updateType, objectType);
                        queryClient.setQueryData(["kinds", data.id], newData);
                    },
                    onError: (error: any) => {
                        queryClient.refetchQueries(["kinds", data.id]);
                        addErrorToast(error);
                    },
                });
            }
        },
        [
            data,
            addErrorToast,
            addToastHandler,
            create_kinds_relation_mutation,
            delete_kinds_relation_mutation,
            update_kinds_relation_mutation,
            queryClient,
        ]
    );

    const handleBackButton = () => {
        history.goBack();
    };

    return (
        <EuiPage>
            <EuiPageSection>
                {data && (
                    <EuiPanel hasBorder={true} hasShadow={true} style={{ marginTop: "20px" }}>
                        <div className="actions">
                            <div onClick={() => setShowExplanation(true)}>
                                <EuiIcon type={"questionInCircle"} size={"xxl"} />
                            </div>
                        </div>
                        <Explain
                            close={() => setShowExplanation(false)}
                            isVisible={showExplanation}
                            title="Product details"
                        >
                            <p>
                                Op deze pagina kun je snel plaatjes van producten bewerken. Tevens kun je vanaf hier
                                snel navigeren naar plekken waar je alle product attributen kan aanpassen.
                            </p>
                        </Explain>
                        <ProductInfo
                            type={type}
                            updateProduct={updateProduct}
                            updateRelation={updateRelation}
                            urlType={urlType}
                            id={id}
                        />
                        {!isLoading && (
                            <ShowPictures data={data} showDelete={pictureUploadVisible} updateProduct={updateProduct} />
                        )}
                        {!pictureUploadVisible && (
                            <EuiFlexGroup>
                                <EuiFlexItem style={{ maxWidth: 100 }}>
                                    <EuiButton onClick={() => handleBackButton()}>
                                        <EuiIcon type="arrowLeft" /> Back
                                    </EuiButton>
                                </EuiFlexItem>

                                <EuiFlexItem style={{ maxWidth: 150 }}>
                                    <EuiButton fill onClick={() => setPictureUploadVisible(true)}>
                                        Bewerk foto's
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        )}
                        {!isLoading && pictureUploadVisible && (
                            <EditPictures
                                data={data}
                                id={id}
                                type={ResourceType.Kind}
                                setPictureUploadVisible={setPictureUploadVisible}
                                updateProduct={updateProduct}
                            />
                        )}
                    </EuiPanel>
                )}
                <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} side="left" />
            </EuiPageSection>
        </EuiPage>
    );
}
