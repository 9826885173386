import "./LoginForm.css";

import {
    EuiButton,
    EuiCallOut,
    EuiFieldPassword,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiPanel,
    EuiSpacer,
} from "@elastic/eui";
import PropTypes from "prop-types";
import React, { useState } from "react";

import client from "../api/Client";
import { isEmpty } from "../utils/Utils";

function LoginForm({ handleLogin }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [token] = useState("");
    const [loading, setLoading] = useState(false);

    const formValid = username.length >= 8;

    const handleLoginInternal = (e) => {
        if (!formValid) {
            setErrorMessage("Form not valid: all fields are required");
            return;
        }
        setLoading(true);

        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        formData.append("grant_type", "password");

        client
            .post("/v1/login/access-token", formData)
            .then((res) => {
                setLoading(false);
                const data = res.data;
                handleLogin(data.access_token);
            })
            .catch((res) => {
                setLoading(false);
                const data = res.response;

                if (data.status === 400) {
                    const errorResponse = data.data.detail;
                    setErrorMessage(errorResponse);
                } else {
                    setErrorMessage("Unknown error occurred");
                    setTimeout(() => {
                        setErrorMessage("");
                    }, 3000);
                }
            });
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            console.log("Enter key pressed; submitting form");
            handleLoginInternal(event);
        }
    };

    return (
        <div className="login-form">
            <EuiPanel>
                <EuiForm style={{ marginTop: "20px" }} component="form" size="large" onSubmit={handleLoginInternal}>
                    {!isEmpty(token) && <div>loading</div>}
                    <div>
                        <EuiFormRow label="Username/Email">
                            <EuiFieldText
                                name="username"
                                autoComplete="username"
                                required
                                icon="email"
                                onChange={(event) => {
                                    setUsername(event.target.value);
                                    setErrorMessage("");
                                }}
                                autoFocus={true}
                                onKeyDown={handleKeyDown}
                            />
                        </EuiFormRow>

                        <EuiFormRow label="Password">
                            <EuiFieldPassword
                                name="password"
                                autoComplete="password"
                                required
                                icon="password"
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    setErrorMessage("");
                                }}
                                onKeyDown={handleKeyDown}
                            />
                        </EuiFormRow>
                        <EuiSpacer />
                        {errorMessage && (
                            <>
                                <EuiCallOut title={errorMessage} color="danger" iconType="alert"></EuiCallOut>
                            </>
                        )}
                        <EuiSpacer />
                        <EuiButton disabled={!formValid} fluid isLoading={loading} onClick={handleLoginInternal}>
                            Login
                        </EuiButton>
                    </div>
                </EuiForm>
            </EuiPanel>
        </div>
    );
}

LoginForm.propTypes = {
    handleLogin: PropTypes.func.isRequired,
};

export default LoginForm;
