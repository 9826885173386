/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import "./Preferences.scss";

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import React, { Dispatch } from "react";
import { ColumnInstance, TableSettings, TableState } from "react-table";

import { capitalizeFirstLetter } from "../../utils/Utils";
import { ActionType, TableSettingsAction } from "./tableSettingsUtils";

interface IProps<T extends object> {
    dispatch: Dispatch<TableSettingsAction<T>>;
    allColumns: ColumnInstance<T>[];
    initialTableSettings: TableSettings<T>;
    state: TableState<T>;
    excludeInFilter: string[];
}

function Preferences<T extends object>({
    allColumns,
    state,
    dispatch,
    initialTableSettings,
    excludeInFilter,
}: IProps<T>) {
    const { name, minimized, refresh, delay, loading, showSettings, showPaginator } = state;

    const title = capitalizeFirstLetter(name.split(".").slice(-1)[0]);

    return (
        <span key={`preferences_${name}`}>
            <span className={`table-preferences-icon-bar${minimized ? " minimized" : ""}`}>
                <span className="table-name">
                    {title}
                    {minimized && "is_minimized"}
                </span>
                {"   "}
                <span
                    title={refresh ? "refresh" + delay : "norefresh"}
                    onClick={() => dispatch({ type: ActionType.REFRESH_TOGGLE })}
                    className={refresh ? (loading ? "pulse" : "rest") : "dead"}
                >
                    {refresh ? (
                        loading ? (
                            <i className={"fa fa-bullseye"} />
                        ) : (
                            <i className={"fa fa-circle"} />
                        )
                    ) : (
                        <i className={"far fa-circle"} />
                    )}
                </span>
                {"   "}
                <span onClick={() => dispatch({ type: ActionType.SHOW_SETTINGS_TOGGLE })}>
                    <EuiIcon type="pencil"></EuiIcon>
                </span>
                {"   "}

                {minimized ? (
                    <span title="table.preferences.maximize" onClick={() => dispatch({ type: ActionType.MAXIMIZE })}>
                        <i className={"fa fa-caret-up"} />
                    </span>
                ) : (
                    <span title="minimize" onClick={() => dispatch({ type: ActionType.MINIMIZE })}>
                        <i className={"fa fa-caret-down"} />
                    </span>
                )}
            </span>
            {showSettings && (
                <div className={"preferences"}>
                    <EuiFlexGroup className="buttons">
                        <EuiFlexItem>
                            <EuiButton
                                onClick={() =>
                                    dispatch({
                                        type: ActionType.OVERRIDE,
                                        settings: initialTableSettings,
                                    })
                                }
                                color="warning"
                                iconType="refresh"
                                fill
                            >
                                reset
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton
                                onClick={() => dispatch({ type: ActionType.SHOW_PAGINATOR_TOGGLE })}
                                color="ghost"
                                fill
                            >
                                {showPaginator ? "hide_paginator" : "show_paginator"}
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <h1>Auto refresh</h1>
                    {/*<NumericInput*/}
                    {/*    onChange={valueAsNumber => {*/}
                    {/*        valueAsNumber && dispatch({ type: ActionType.REFRESH_DELAY, delay: valueAsNumber });*/}
                    {/*    }}*/}
                    {/*    min={500}*/}
                    {/*    max={10000}*/}
                    {/*    step={500}*/}
                    {/*    value={state.delay}*/}
                    {/*    strict={true}*/}
                    {/*/>*/}
                    <h2>Visible columns</h2>
                    {allColumns
                        .filter((column) => !excludeInFilter.includes(column.id))
                        .map((column) => {
                            return (
                                <label key={column.id}>
                                    <input type="checkbox" {...column.getToggleHiddenProps()} /> {column.id}
                                </label>
                            );
                        })}
                </div>
            )}
        </span>
    );
}

export default Preferences;
