import I18n from "i18n-js";

I18n.translations.en = {
    code: "EN",
    name: "English",
    select_locale: "Select English",
    EntityId: "",

    header: {
        title: "Orchestrator",
        links: {
            logout: "Logout",
            exit: "Exit",
        },
        role: "Role",
    },
    process: {
        stop: "Stop",
        cancel: "Cancel",
        next: "Volgende",
        submit: "Verder",
        previous: "Terug",
    },
    products: {
        create: {
            new_product: "Nieuwe wiet",
        },
        table: {
            name: "Naam",
            product_type: "Type",
            description_nl: "Beschrijving NL",
            description_en: "Beschrijving EN",
            modified_at: "Bewerkt",
        },
        filters: {
            state: "Filter producten",
        },
    },
    // Refactor the translations
    forms: {
        input_fields_have_validation_errors: "Validatie fouten. Graag corrigeren.",
        widgets: {
            select: {
                placeholder: "Maak een selectie...",
            },
        },
        fields: {
            category_name: "Categorie naam",
            color: "Kleur",
            description: "Beschrijving",
            icon: "Icoon",
            is_cannabis: "Categorie voor cannabis?",
            kind_name: "Wiet naam",
            kind_name_info: "De wiet naam zoals deze wordt getoond in de prijslijst",
            kind_type: "Wiet type",
            strain_choice: "Kies een kruising",
            strain_choice_info:
                "Kies tussen de 1 en de 3 wiet kruisingen. Staat de kruising er niet bij? Gebruik dan [+] hieronder om direct een nieuwe kruising aan te maken.",
            gebruiken: "Dit product meteen gebruiken?",
            gebruiken_info:
                "Wanneer je deze aanvinkt kom je hierna op een formulier waar je een prijs aan dit product kan hangen. Daarna is het direct op het scherm te zien.",
            strain_name: "Naam van de kruising",
            strain_name_info: "Vul de naam van de kruising in",
            tag_name: "Naam van de tag",
            tag_name_info: "Vul de naam van de tag in",
            short_description_en: "Korte beschrijving EN",
            description_en: "Beschrijving EN",
            short_description_nl: "Korte beschrijving NL",
            description_nl: "Beschrijving NL",
            summary: "Wat je gaat aanmaken",
            summary_info: "Hier zie je een samenvatting van wat je net ingevuld hebt",
            warning: "Let op!",
            warning_info:
                "Je kan (nog) geen producten bewerken. Zie je een nu een 'typo' ga dan terug naar het vorige formulier en pas het aan.",
            name_en: "Categorie naam - EN",
            new_strain_from_name: "Gebruik wiet naam als nieuwe kruising",
            new_strain_from_name_info: "Vink deze aan om de nieuwe wiet naam meteen als een kruising toe te voegen.",
            create_new_strains: "Maak nieuwe kruisingen",
            kind_description_nl: "Wiet beschrijving - NL",
            kind_description_nl_info: "Als u de beschrijving overslaat, moet u minimaal 1 kruising opgeven",
            kind_description_en: "Wiet beschrijving - EN",
            kind_description_en_info: "Als u de beschrijving overslaat, moet u minimaal 1 kruising opgeven",
            main_category_id: "Main Categorie",
            product_name: "Horeca product naam",
        },
    },
};

export default I18n.translations.en;
