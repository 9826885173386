/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import "../EditPictures.scss";

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiImage, EuiPanel, EuiSpacer, EuiTitle } from "@elastic/eui";
import AWS from "aws-sdk";
import noImage from "images/no_image.jpg";
import React, { useState } from "react";

import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, S3_BUCKET } from "../../Constants";
import { KindForTable, MutationType, ProductForTable } from "../../utils/types";
import { imageCounter } from "../../utils/Utils";
import ConfirmationDialog from "../modals/ConfirmationDialog";
import { getImageSrc } from "../ProductImage";

AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
});

interface IProps {
    data: ProductForTable | KindForTable;
    showDelete: boolean;
    updateProduct: (product: ProductForTable | KindForTable, updateType: MutationType, objectType: string) => void;
}

function ShowPictures({ data, showDelete, updateProduct }: IProps) {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(-1);

    const renderImages = () => {
        let filePickers = [];
        for (let i = 0; i < imageCounter(data); i++) {
            filePickers.push(
                <EuiFlexItem key={i} grow={1} style={{ width: 150, maxWidth: "20vw" }}>
                    <EuiImage
                        hasShadow
                        // @ts-ignore
                        allowFullScreen={data[`image_${i + 1}`] !== null}
                        size="xl"
                        caption={`Foto ${i + 1}`}
                        alt="alt"
                        // @ts-ignore
                        src={getImageSrc(data[`image_${i + 1}`], 2000, S3_BUCKET)}
                        wrapperProps={{ className: "eui-textLeft" }}
                        onError={(e) => {
                            e.currentTarget.src = noImage;
                        }}
                    />
                    <EuiButtonIcon
                        onClick={() => {
                            setDeleteId(i);
                            setConfirmDialogOpen(true);
                        }}
                        style={{ display: showDelete ? "block" : "none" }}
                        display="base"
                        iconType="trash"
                        aria-label="Delete"
                        color="danger"
                    />
                </EuiFlexItem>
            );
        }

        return filePickers;
    };

    const deleteImage = (index: number) => {
        let newProduct = JSON.parse(JSON.stringify(data));
        newProduct[`image_${index + 1}`] = null;
        updateProduct(newProduct, MutationType.Deleted, "Picture");
    };

    return (
        <EuiPanel color="transparent" hasBorder={false}>
            <ConfirmationDialog
                isOpen={confirmDialogOpen}
                cancel={() => setConfirmDialogOpen(false)}
                confirm={() => {
                    deleteImage(deleteId);
                    setConfirmDialogOpen(false);
                    setDeleteId(-1);
                }}
                question={"Weet je zeker dat je deze wilt wissen?"}
            />
            <EuiTitle>
                <h1>Foto's</h1>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGroup>{renderImages()}</EuiFlexGroup>
        </EuiPanel>
    );
}

export default ShowPictures;
