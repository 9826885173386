import {
    EuiButton,
    EuiFieldNumber,
    EuiFieldText,
    EuiFlexGrid,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiSwitch,
    EuiText,
    EuiTitle,
} from "@elastic/eui";
import client from "api/Client";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ShopContext } from "utils/Shop";
import { JsonPrice, PriceForForm } from "utils/types";

export function PriceForm({ price, useOldModel }: { price: PriceForForm | undefined; useOldModel: boolean }) {
    const [internalIdPrefix, setInternalIdPrefix] = useState("");
    const [shopGroupId, setShopGroupId] = useState("");
    const [shopGroups, setShopGroups] = useState([]);
    const [groupsLoading, setGroupsLoading] = useState(true);

    const [edibleJsonPrices, setEdibleJsonPrices] = useState<JsonPrice[]>([]);
    const [preRolledJsonPrices, setPreRolledJsonPrices] = useState<JsonPrice[]>([]);
    const [cannabisJsonPrices, setCannabisJsonPrices] = useState<JsonPrice[]>([]);

    const [half, setHalf] = useState<number>(0);
    const [one, setOne] = useState<number>(0);
    const [twoFive, setTwoFive] = useState<number>(0);
    const [five, setFive] = useState<number>(0);
    const [joint, setJoint] = useState<number>(0);
    const [piece, setPiece] = useState<number>(0);

    const { shopId } = useContext(ShopContext);

    useEffect(() => {
        if (price) {
            setInternalIdPrefix(price.internal_product_id.substring(0, 3));
            if (useOldModel) {
                setHalf(price.half);
                setOne(price.one);
                setTwoFive(price.two_five);
                setFive(price.five);
                setJoint(price.joint);
                setPiece(price.piece);
            } else {
                setCannabisJsonPrices(price.cannabis);
                setPreRolledJsonPrices(price.pre_rolled_joints);
                setEdibleJsonPrices(price.edible);
            }
        }

        client.get(`/v1/shop-groups?skip=0&limit=1000`).then((result) => {
            const shopGroups = result.data.filter((group) => group.shop_ids.includes(shopId));
            const options = shopGroups.map((group) => ({
                text: group.name,
                value: group.id,
            }));
            options.unshift({ text: "", value: "-1" });
            setShopGroups(options);
            setGroupsLoading(false);

            if (price) {
                setShopGroupId(price.shop_group_id);
            }
        });
    }, [shopId, price, useOldModel]);

    const handleSubmit = () => {
        let payload;

        if (useOldModel) {
            payload = {
                internal_product_id: internalIdPrefix,
                shop_group_id: shopGroupId,
                half: half > 0 ? half : null,
                one: one > 0 ? one : null,
                two_five: twoFive > 0 ? twoFive : null,
                five: five > 0 ? five : null,
                joint: joint > 0 ? joint : null,
                piece: piece > 0 ? piece : null,
                cannabis: null,
                pre_rolled_joints: null,
                edible: null,
            };
        } else {
            let internal_product_id = `${internalIdPrefix}: `;

            cannabisJsonPrices.forEach((price) => {
                internal_product_id = internal_product_id + `${price.label} voor ${price.price} - `;
            });
            preRolledJsonPrices.forEach((price) => {
                internal_product_id = internal_product_id + `${price.label} voor ${price.price} - `;
            });
            edibleJsonPrices.forEach((price) => {
                internal_product_id = internal_product_id + `${price.label} voor ${price.price} - `;
            });

            payload = {
                internal_product_id: internal_product_id.substring(0, internal_product_id.length - 3),
                shop_group_id: shopGroupId,
                half: null,
                one: null,
                two_five: null,
                five: null,
                joint: null,
                piece: null,
                cannabis: cannabisJsonPrices,
                pre_rolled_joints: preRolledJsonPrices,
                edible: edibleJsonPrices,
            };
        }

        if (price) {
            client
                .put(`/v1/prices/${price.id}`, payload)
                .then(() => {
                    window.location.replace("/prices");
                })
                .catch((error) =>
                    alert("Prijs bestaat al. Wanneer je toch op wil slaan, kies dan een andere kassa code prefix.")
                );
        } else {
            client.post(`/v1/prices`, payload).then(() => {
                window.location.replace("/prices");
            });
        }
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            console.log("Enter key pressed; submitting form");
            handleSubmit(event);
        }
    };

    const setJsonPrice = (
        index: number,
        jsonPrices: JsonPrice[],
        setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>,
        field: string,
        value: string | number | boolean
    ) => {
        const prices: JsonPrice[] = [...jsonPrices];
        prices[index][field as keyof object] = value;
        setJsonPrices(prices);
    };

    const addJsonPrice = (jsonPrices: JsonPrice[], setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>) => {
        const prices: JsonPrice[] = [...jsonPrices];
        prices.push({
            label: "",
            quantity: 0.0,
            price: 0.0,
            active: true,
        });
        setJsonPrices(prices);
    };

    const removeJsonPrice = (jsonPrices: JsonPrice[], setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>) => {
        if (jsonPrices.length > 0) {
            const prices: JsonPrice[] = [...jsonPrices];
            prices.pop();
            setJsonPrices(prices);
        }
    };

    const handleInternalIdPrefix = (input: string) => {
        input = input.replace(/\D/g, "");
        input = input.slice(0, 3);
        input = input.padStart(3, "0");
        setInternalIdPrefix(input);
    };

    return (
        <EuiForm>
            <EuiTitle>
                <EuiText>{price === undefined ? "Prijs Toevoegen" : "Prijs Bewerken"}</EuiText>
            </EuiTitle>
            <EuiSpacer />
            <EuiFormRow
                label="Shop Groep"
                labelAppend={<EuiText size="m">Selecteer een shop groep.</EuiText>}
                id="select-group"
                fullWidth
            >
                <EuiSelect
                    value={shopGroupId}
                    onChange={(e) => setShopGroupId(e.target.value)}
                    options={shopGroups}
                    isLoading={groupsLoading}
                    onKeyDown={handleKeyDown}
                    fullWidth
                />
            </EuiFormRow>
            <EuiFormRow
                label="Kassa Code Prefix"
                labelAppend={<EuiText size="m">Vul een drie-cijferige kassa code prefix in.</EuiText>}
                id="select-price"
                fullWidth
            >
                <EuiFieldNumber
                    value={internalIdPrefix}
                    onChange={(e) => setInternalIdPrefix(e.target.value)}
                    onBlur={(e) => handleInternalIdPrefix(e.target.value)}
                    fullWidth
                />
            </EuiFormRow>
            {useOldModel === false ? (
                <>
                    <EuiFormRow
                        label="Cannabis Prijzen"
                        labelAppend={<EuiText size="m">Vul de prijzen voor cannabis in.</EuiText>}
                        id="select-price"
                        fullWidth
                    >
                        <>
                            {cannabisJsonPrices.map((price, index) => (
                                <EuiFlexGroup
                                    gutterSize="l"
                                    direction="column"
                                    style={{ borderBottom: "lightBlue 1px solid", paddingTop: "5px" }}
                                    key={index}
                                >
                                    <EuiFlexItem>
                                        <EuiText>Hoeveelheid (in grammen)</EuiText>
                                        <EuiFieldNumber
                                            step={0.01}
                                            value={price.quantity}
                                            fullWidth
                                            onChange={(e) => {
                                                setJsonPrice(
                                                    index,
                                                    cannabisJsonPrices,
                                                    setCannabisJsonPrices,
                                                    "quantity",
                                                    e.target.value
                                                );
                                                setJsonPrice(
                                                    index,
                                                    cannabisJsonPrices,
                                                    setCannabisJsonPrices,
                                                    "label",
                                                    `${e.target.value}g`
                                                );
                                            }}
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiText>Prijs (in Euro's)</EuiText>
                                        <EuiFieldNumber
                                            step={0.01}
                                            value={price.price}
                                            fullWidth
                                            onChange={(e) =>
                                                setJsonPrice(
                                                    index,
                                                    cannabisJsonPrices,
                                                    setCannabisJsonPrices,
                                                    "price",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiText>Label</EuiText>
                                        <EuiFieldText
                                            disabled={true}
                                            value={price.label}
                                            fullWidth
                                            onChange={(e) =>
                                                setJsonPrice(
                                                    index,
                                                    cannabisJsonPrices,
                                                    setCannabisJsonPrices,
                                                    "label",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiSwitch
                                            label="Actief?"
                                            checked={price.active}
                                            onChange={(e) =>
                                                setJsonPrice(
                                                    index,
                                                    cannabisJsonPrices,
                                                    setCannabisJsonPrices,
                                                    "active",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            ))}
                            <EuiSpacer />
                            <EuiButton
                                iconType={"plus"}
                                style={{ marginRight: "5px" }}
                                onClick={() => addJsonPrice(cannabisJsonPrices, setCannabisJsonPrices)}
                            >
                                Add
                            </EuiButton>
                            <EuiButton
                                iconType={"minus"}
                                disabled={cannabisJsonPrices.length < 1}
                                onClick={() => removeJsonPrice(cannabisJsonPrices, setCannabisJsonPrices)}
                            >
                                Remove
                            </EuiButton>
                        </>
                    </EuiFormRow>
                    <EuiFormRow
                        label="Pre-Rolled Joints Prijzen"
                        labelAppend={<EuiText size="m">Vul de prijzen voor pre-rolled joints in.</EuiText>}
                        id="select-price"
                        fullWidth
                    >
                        <>
                            {preRolledJsonPrices.map((price, index) => (
                                <EuiFlexGroup
                                    gutterSize="l"
                                    direction="column"
                                    style={{ borderBottom: "lightBlue 1px solid", paddingTop: "5px" }}
                                    key={index}
                                >
                                    <EuiFlexItem>
                                        <EuiText>Hoeveelheid (in aantal joints)</EuiText>
                                        <EuiFieldNumber
                                            step={0.01}
                                            value={price.quantity}
                                            fullWidth
                                            onChange={(e) => {
                                                setJsonPrice(
                                                    index,
                                                    preRolledJsonPrices,
                                                    setPreRolledJsonPrices,
                                                    "quantity",
                                                    e.target.value
                                                );
                                                setJsonPrice(
                                                    index,
                                                    preRolledJsonPrices,
                                                    setPreRolledJsonPrices,
                                                    "label",
                                                    `${e.target.value}j`
                                                );
                                            }}
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiText>Prijs (in Euro's)</EuiText>
                                        <EuiFieldNumber
                                            step={0.01}
                                            value={price.price}
                                            fullWidth
                                            onChange={(e) =>
                                                setJsonPrice(
                                                    index,
                                                    preRolledJsonPrices,
                                                    setPreRolledJsonPrices,
                                                    "price",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiText>Label</EuiText>
                                        <EuiFieldText
                                            disabled={true}
                                            value={price.label}
                                            fullWidth
                                            onChange={(e) =>
                                                setJsonPrice(
                                                    index,
                                                    preRolledJsonPrices,
                                                    setPreRolledJsonPrices,
                                                    "label",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiSwitch
                                            label="Actief?"
                                            checked={price.active}
                                            onChange={(e) =>
                                                setJsonPrice(
                                                    index,
                                                    preRolledJsonPrices,
                                                    setPreRolledJsonPrices,
                                                    "active",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            ))}
                            <EuiSpacer />
                            <EuiButton
                                iconType={"plus"}
                                style={{ marginRight: "5px" }}
                                onClick={() => addJsonPrice(preRolledJsonPrices, setPreRolledJsonPrices)}
                            >
                                Add
                            </EuiButton>
                            <EuiButton
                                iconType={"minus"}
                                disabled={preRolledJsonPrices.length < 1}
                                onClick={() => removeJsonPrice(preRolledJsonPrices, setPreRolledJsonPrices)}
                            >
                                Remove
                            </EuiButton>
                        </>
                    </EuiFormRow>
                    <EuiFormRow
                        label="Edible Prijzen"
                        labelAppend={<EuiText size="m">Vul de prijzen voor edibles in.</EuiText>}
                        id="select-price"
                        fullWidth
                    >
                        <>
                            {edibleJsonPrices.map((price, index) => (
                                <EuiFlexGroup
                                    gutterSize="l"
                                    direction="column"
                                    style={{ borderBottom: "lightBlue 1px solid", paddingTop: "5px" }}
                                    key={index}
                                >
                                    <EuiFlexItem>
                                        <EuiText>Hoeveelheid (in aantal edibles)</EuiText>
                                        <EuiFieldNumber
                                            step={0.01}
                                            value={price.quantity}
                                            fullWidth
                                            onChange={(e) => {
                                                setJsonPrice(
                                                    index,
                                                    edibleJsonPrices,
                                                    setEdibleJsonPrices,
                                                    "quantity",
                                                    e.target.value
                                                );
                                                setJsonPrice(
                                                    index,
                                                    edibleJsonPrices,
                                                    setEdibleJsonPrices,
                                                    "label",
                                                    `${e.target.value}st`
                                                );
                                            }}
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiText>Prijs (in Euro's)</EuiText>
                                        <EuiFieldNumber
                                            step={0.01}
                                            value={price.price}
                                            fullWidth
                                            onChange={(e) =>
                                                setJsonPrice(
                                                    index,
                                                    edibleJsonPrices,
                                                    setEdibleJsonPrices,
                                                    "price",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiText>Label</EuiText>
                                        <EuiFieldText
                                            disabled={true}
                                            value={price.label}
                                            fullWidth
                                            onChange={(e) =>
                                                setJsonPrice(
                                                    index,
                                                    edibleJsonPrices,
                                                    setEdibleJsonPrices,
                                                    "label",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiSwitch
                                            label="Actief?"
                                            checked={price.active}
                                            onChange={(e) =>
                                                setJsonPrice(
                                                    index,
                                                    edibleJsonPrices,
                                                    setEdibleJsonPrices,
                                                    "active",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            ))}
                            <EuiSpacer />
                            <EuiButton
                                iconType={"plus"}
                                style={{ marginRight: "5px" }}
                                onClick={() => addJsonPrice(edibleJsonPrices, setEdibleJsonPrices)}
                            >
                                Add
                            </EuiButton>
                            <EuiButton
                                iconType={"minus"}
                                disabled={edibleJsonPrices.length < 1}
                                onClick={() => removeJsonPrice(edibleJsonPrices, setEdibleJsonPrices)}
                            >
                                Remove
                            </EuiButton>
                        </>
                    </EuiFormRow>
                </>
            ) : (
                <EuiFlexGrid columns={3}>
                    <EuiFlexItem>
                        <EuiFormRow
                            label="0,5 gram Prijs"
                            labelAppend={<EuiText size="m">Vul de prijs voor 0,5g in.</EuiText>}
                            id="half-price"
                            fullWidth
                        >
                            <EuiFieldNumber
                                step={0.01}
                                value={half}
                                fullWidth
                                onChange={(e) => setHalf(e.target.value)}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow
                            label="1 gram Prijs"
                            labelAppend={<EuiText size="m">Vul de prijs voor 1g in.</EuiText>}
                            id="one-price"
                            fullWidth
                        >
                            <EuiFieldNumber
                                step={0.01}
                                value={one}
                                fullWidth
                                onChange={(e) => setOne(e.target.value)}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow
                            label="2,5 gram Prijs"
                            labelAppend={<EuiText size="m">Vul de prijs voor 2,5g in.</EuiText>}
                            id="two-half-price"
                            fullWidth
                        >
                            <EuiFieldNumber
                                step={0.01}
                                value={twoFive}
                                fullWidth
                                onChange={(e) => setTwoFive(e.target.value)}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow
                            label="5 gram Prijs"
                            labelAppend={<EuiText size="m">Vul de prijs voor 5g in.</EuiText>}
                            id="five-price"
                            fullWidth
                        >
                            <EuiFieldNumber
                                step={0.01}
                                value={five}
                                fullWidth
                                onChange={(e) => setFive(e.target.value)}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow
                            label="Joint Prijs"
                            labelAppend={<EuiText size="m">Vul de prijs voor een joint in.</EuiText>}
                            id="joint-price"
                            fullWidth
                        >
                            <EuiFieldNumber
                                step={0.01}
                                value={joint}
                                fullWidth
                                onChange={(e) => setJoint(e.target.value)}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow
                            label="Per Stuk Prijs"
                            labelAppend={<EuiText size="m">Vul de prijs per stuk in.</EuiText>}
                            id="piece-price"
                            fullWidth
                        >
                            <EuiFieldNumber
                                step={0.01}
                                value={piece}
                                fullWidth
                                onChange={(e) => setPiece(e.target.value)}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                </EuiFlexGrid>
            )}
            <EuiFlexItem>
                {useOldModel === false ? (
                    <EuiButton
                        fill
                        onClick={() => handleSubmit()}
                        disabled={
                            shopGroupId === "-1" &&
                            internalIdPrefix.length < 1 &&
                            edibleJsonPrices.length < 1 &&
                            preRolledJsonPrices.length < 1 &&
                            cannabisJsonPrices.length < 1
                        }
                        type="submit"
                        isLoading={groupsLoading}
                    >
                        {price ? "Opslaan" : "Toevoegen"}
                    </EuiButton>
                ) : (
                    <EuiButton
                        fill
                        onClick={() => handleSubmit()}
                        disabled={
                            shopGroupId === "-1" &&
                            internalIdPrefix.length < 1 &&
                            half < 0.01 &&
                            one < 0.01 &&
                            twoFive < 0.01 &&
                            five < 0.01 &&
                            joint < 0.01 &&
                            piece < 0.01
                        }
                        type="submit"
                        isLoading={groupsLoading}
                    >
                        {price ? "Opslaan" : "Toevoegen"}
                    </EuiButton>
                )}
            </EuiFlexItem>
        </EuiForm>
    );
}
