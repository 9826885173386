import React from "react";

// import { getPriceQuantityDescription } from "../utils/Utils";

export const PriceTableHeaderNew = (props) => {
    const { title, cols, topHeader } = props;

    return (
        <div>
            <div>
                <h2 className={topHeader ? "title" : "titleNotAtTop"}>{title}</h2>
            </div>
            <div className="info">
                {cols
                    .slice(0)
                    .reverse()
                    .map((col, index) => (
                        <p key={index} className="priceHeader">
                            {col}
                        </p>
                    ))}
            </div>
        </div>
    );
};
