import React from "react";

import { PricelistTableRow } from "../../utils/types";
import { Badge } from "../Badge";

const KindBadge = <T,>({ row, badgesStyles }: { row: PricelistTableRow<T>; badgesStyles: any }) => {
    const renderBadge = () => {
        switch (true) {
            case row.price?.kind_c:
                return <Badge letter={"c"} styles={badgesStyles} />;
            case row.price?.kind_h:
                return <Badge letter={"h"} styles={badgesStyles} />;
            case row.price?.kind_s:
                return <Badge letter={"s"} styles={badgesStyles} />;
            case row.price?.kind_i:
                return <Badge letter={"i"} styles={badgesStyles} />;
            default:
                return <Badge letter={""} styles={badgesStyles} />;
        }
    };

    return <>{renderBadge()}</>;
};

export default KindBadge;
