import { EuiPage } from "@elastic/eui";
import PropTypes from "prop-types";
import { Component } from "react";
import React from "react";

import client from "../api/Client";
import LoginForm from "../components/LoginForm";
import { ShopContext } from "../utils/Shop";

class Login extends Component {
    static contextType = ShopContext;

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            loading: false,
            email: "",
        };
    }

    componentDidMount = () => {
        console.log("LoginPage mounted");
    };

    handleShowResetPassword = (event) => {
        event.preventDefault();
        console.log("Switching to register form");
        this.setState({ isLogin: false });
    };

    handleLogin = (token) => {
        // Todo: now loginform will return email: better to work with user_id and fetch current userinfo here
        console.log(`Handle Login called on page level with token: ${token}`);
        client
            .get(`/v1/users/me`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                const data = res.data;
                const userData = {
                    id: data.id,
                    email: data.email,
                    username: data.username,
                    is_superuser: data.is_superuser,
                    // Todo: roles??
                    token: token,
                    shop_groups: data.shop_groups,
                };
                const appState = {
                    isLoggedIn: true,
                    profile: userData,
                };

                localStorage["appState"] = JSON.stringify(appState);
                this.setState(appState);

                if (data.email.startsWith("maryjane@")) {
                    this.context.setShopName("Mary Jane");
                    this.context.setShopId("bbc0cfa7-e230-4338-817a-55190debdac0");
                }
                if (data.email.startsWith("easygoing@")) {
                    this.context.setShopName("Easy Going");
                    this.context.setShopId("ddd70853-5e9d-45ff-82b3-24c9d81d4e32");
                }
                if (data.email.startsWith("missouri@")) {
                    this.context.setShopName("Missouri");
                    this.context.setShopId("a08a13e2-a31b-4b6d-b2b4-0491cb3d2227");
                }
                if (data.email === "theroundabout69@gmail.com") {
                    this.context.setShopName("Roundabout 69");
                    this.context.setShopId("f155b35a-14a1-4866-867f-93fe21e9769b");
                }
                window.location.href = "/";
            })
            .catch((res) => {
                const data = res.response;
                if (data.status === 401) {
                    this.setState({
                        errorMessage: `Token based user profile fetch resulted in error: ${data.statusText}`,
                    });
                } else {
                    this.setState({
                        errorMessage: `Token based user profile fetch resulted in error ${data.status}: ${data.statusText}`,
                    });
                }
            });
    };

    handleReset = () => {
        console.log(`Handle Reset called on page level with email: ${this.state.email}`);
        this.setState({ loading: true });
        // OK valid form: Let's call api endpoint
        const payload = {
            email: this.state.email,
        };

        client
            .post("/reset", payload)
            .then((res) => {
                this.props.alert.show(`Password reset handled OK`, { type: "success" });
                this.setState({
                    loading: false,
                    isReset: false,
                    isLogin: true,
                    email: "",
                });
            })
            .catch((res) => {
                this.setState({ loading: false, email: "" });
                const message = res.response.data.response.errors.email[0];
                if (message === "Specified user does not exist") {
                    this.props.alert.show(`Pass reset handled OK, check your inbox.`, {
                        type: "success",
                    });
                    this.setState({
                        loading: false,
                        isReset: false,
                        isLogin: true,
                        email: "",
                    });
                } else {
                    this.props.alert.show(message, { type: "error" });
                }
            });
    };

    render() {
        return (
            <EuiPage>
                <LoginForm handleLogin={this.handleLogin} />
            </EuiPage>
        );
    }
}

export default Login;

Login.propTypes = {
    isLogin: PropTypes.bool,
};

Login.defaultProps = {
    isLogin: true,
};
