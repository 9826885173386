import crypto from "crypto";

import React, { createContext, useState } from "react";

import { MutationType } from "../../utils/types";
import { capitalizeFirstLetter } from "../../utils/Utils";

export interface GlobalToastInterface {
    toasts: [];
    addErrorToast: (error: any) => void;
    addToastHandler: (mutationType: MutationType, objectType?: string) => void;
    removeToast: (removedToast: { id: any }) => void;
}

export const GlobalToastContext = createContext<GlobalToastInterface>({
    toasts: [],
    addErrorToast: (error: any) => {},
    addToastHandler: (mutationType: MutationType, objectType?: string) => {},
    removeToast: (removedToast: { id: any }) => {},
});

export const GlobalToastContextProvider = GlobalToastContext.Provider;
export default GlobalToastContext;

export function GlobalToastContextWrapper({ children }: any) {
    const [toasts, setToasts] = useState<[]>([]);

    const addErrorToast = (error: any) => {
        if (error.request) {
            // @ts-ignore
            setToasts((toasts) => [
                ...toasts,
                {
                    id: crypto.randomBytes(8).toString("hex"),
                    title: "Error, changes not saved. Trying refetch ...",
                    color: "danger",
                    iconType: "cross",
                    text: (
                        <p>
                            {error.request.statusText}: {`${error.request.responseText}`}
                        </p>
                    ),
                    toastLifeTimeMs: 8000,
                },
            ]);
        } else {
            // @ts-ignore
            setToasts((toasts) => [
                ...toasts,
                {
                    id: crypto.randomBytes(8).toString("hex"),
                    title: "Error, changes not saved.",
                    color: "danger",
                    iconType: "cross",
                    text: <p>Could not connect to server or unknown error</p>,
                    toastLifeTimeMs: 8000,
                },
            ]);
        }
    };

    const addToastHandler = (mutationType: MutationType, objectType?: string) => {
        let toast = {};
        objectType = capitalizeFirstLetter(objectType);

        switch (mutationType) {
            case MutationType.PictureUpload:
                toast = {
                    id: crypto.randomBytes(8).toString("hex"),
                    title: "Pictures uploaded",
                    color: "success",
                    iconType: "check",
                    text: <p>Your pictures have been uploaded successfully!</p>,
                    toastLifeTimeMs: 3000,
                };
                break;
            case MutationType.Deleted:
                toast = {
                    id: crypto.randomBytes(8).toString("hex"),
                    title: `${objectType} deleted`,
                    color: "success",
                    iconType: "check",
                    text: <p>{objectType} deleted successfully!</p>,
                    toastLifeTimeMs: 3000,
                };
                break;
            case MutationType.Edited:
                toast = {
                    id: crypto.randomBytes(8).toString("hex"),
                    title: "Changes submitted",
                    color: "success",
                    iconType: "check",
                    text: <p>{objectType} has been edited successfully!</p>,
                    toastLifeTimeMs: 3000,
                };
                break;
            case MutationType.Added:
                toast = {
                    id: crypto.randomBytes(8).toString("hex"),
                    title: `${objectType} added`,
                    color: "success",
                    iconType: "check",
                    text: <p>{objectType} added successfully!</p>,
                    toastLifeTimeMs: 3000,
                };
                break;
            default:
                toast = {
                    id: crypto.randomBytes(8).toString("hex"),
                    title: "Changes submitted!",
                    color: "success",
                    iconType: "check",
                    text: <p>Update successful</p>,
                    toastLifeTimeMs: 3000,
                };
        }
        // @ts-ignore
        setToasts((toasts) => [...toasts, toast]);
    };

    const removeToast = (removedToast: { id: any }) => {
        // @ts-ignore
        setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
    };

    return (
        <GlobalToastContextProvider value={{ toasts, addErrorToast, addToastHandler, removeToast }}>
            {children}
        </GlobalToastContextProvider>
    );
}
