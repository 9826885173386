import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiLoadingSpinner, EuiText } from "@elastic/eui";
import React, { ReactNode } from "react";

import { PricelistTableRow, RowType } from "../../utils/types";
import { AppVersionLabel } from "./AppVersionLabel";

interface DynamicRowPricelistProps<T> {
    tableRowsColumns: PricelistTableRow<T>[][];
    isLoading: boolean;
    maxHeight: number;
    renderRow: (row: PricelistTableRow<T>) => ReactNode;
    renderBackground?: JSX.Element | (() => JSX.Element);
    renderLegend?: () => JSX.Element;
}

const LoadingComponent = ({ isLoading }: { isLoading: boolean }) => {
    return (
        <EuiFlexGroup
            justifyContent={"center"}
            alignItems={"center"}
            style={{ padding: "1vw", display: isLoading ? "flex" : "none" }}
        >
            <EuiText style={{ paddingRight: 5 }}>
                <h1>Loading...</h1>
            </EuiText>
            <EuiLoadingSpinner size="xxl" style={{ display: "block" }} />
        </EuiFlexGroup>
    );
};

const RowDivider = ({ row }: { row: PricelistTableRow<unknown> }) => {
    return row.type === RowType.DataRow ? <EuiHorizontalRule className="rowDivider" margin={"none"} /> : <></>;
};

export const DynamicPricelist = <T,>({
    tableRowsColumns,
    isLoading,
    maxHeight,
    renderRow,
    renderBackground,
    renderLegend,
}: DynamicRowPricelistProps<T>) => {
    return (
        <div className="tableContainer">
            {renderBackground ? renderBackground : <></>}
            {tableRowsColumns.map((column, index) => (
                <div key={index} className="tableColumn">
                    <LoadingComponent isLoading={isLoading} />
                    <EuiFlexGroup gutterSize="none" direction="column" css={{ height: `${maxHeight}vh` }}>
                        {column.map((row, index) => (
                            <EuiFlexItem className="tableRowContainer" key={index}>
                                <RowDivider row={row} />
                                <div className="tableRow">{renderRow(row)}</div>
                            </EuiFlexItem>
                        ))}
                    </EuiFlexGroup>
                </div>
            ))}
            {/*{renderLegend ? renderLegend() : <></>}*/}
            <AppVersionLabel />
        </div>
    );
};
